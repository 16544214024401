import React, { useState, useEffect, useCallback, useRef } from "react";
import { Card, Row, Col, Button, Form, Modal, Spinner, Badge, Tab, Tabs, ListGroup, InputGroup } from "react-bootstrap";
import { FaEdit, FaUsers, FaTrash, FaTimes, FaUserPlus, FaSearch, FaInfo, FaPlus, FaCheck, FaUserClock, FaEye, FaEyeSlash, FaEnvelope, FaUpload, FaPaste, FaFileAlt, FaCopy } from "react-icons/fa";
import debounce from "lodash.debounce";
import moment from "moment-timezone";

import apiService from "../../services/apiService";
import geminiService from "../../services/geminiService";
import logger from "../../utils/logger";
import toastUtils from "../../utils/toast";
import * as SERVICES from "../../services/localService";

const Groups = () => {
    const [myTeams, setMyTeams] = useState([]);
    const [otherTeams, setOtherTeams] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showMembersModal, setShowMembersModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [inviteEmails, setInviteEmails] = useState("");
    const [extractedEmails, setExtractedEmails] = useState([]);
    const [inviteLoading, setInviteLoading] = useState(false);
    const [extractLoading, setExtractLoading] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [groupMembers, setGroupMembers] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentUserRole, setCurrentUserRole] = useState('');
    const [currentUserId, setCurrentUserId] = useState(null);
    const [showDeclinedInvitations, setShowDeclinedInvitations] = useState(false);
    const [activeTab, setActiveTab] = useState('my-teams');
    const [emailInputMode, setEmailInputMode] = useState('manual'); // 'manual', 'paste', 'file'
    const [validationErrors, setValidationErrors] = useState([]);
    const [copySuccess, setCopySuccess] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        fetchGroups();
        const currentUser = SERVICES.getUser();
        setCurrentUserRole(currentUser?.role || '');
        setCurrentUserId(currentUser?.id || null);
    }, []);

    const isAdministrator = () => {
        return currentUserRole === 'ROLE_ADMINISTRATOR' ||
            currentUserRole === 'ROLE_SUBSCRIBER_OWNER';
    };

    const isIndividualSubscriber = () => {
        return currentUserRole === 'ROLE_SUBSCRIBER_INDIVIDUAL';
    };

    const fetchGroups = async () => {
        setIsLoading(true);
        try {
            const response = await apiService.getCompanyGroups();
            if (response.status === 200 && response.data) {
                setMyTeams(response.data.myGroups || []);
                setOtherTeams(response.data.companyGroups || []);
            }
        } catch (error) {
            logger.error("Error fetching groups:", error);
            toastUtils.error("Failed to load teams", {
                description: "Please try again later"
            });
        } finally {
            setIsLoading(false);
        }
    };

    const fetchGroupMembers = async (groupId) => {
        try {
            const response = await apiService.getGroupMembers(groupId);
            if (response.status === 200) {
                // Use content array from the new response format
                const members = response.data?.content || [];
                setGroupMembers(prev => ({
                    ...prev,
                    [groupId]: members
                }));
                return members;
            }
            return [];
        } catch (error) {
            logger.error(`Error fetching members for group ${groupId}:`, error);
            toastUtils.error("Failed to fetch team members", {
                description: "Please try again later"
            });
            return [];
        }
    };

    const handleCreateGroup = async () => {
        if (!groupName.trim()) return;

        setIsLoading(true);
        try {
            logger.log("Creating team:", groupName);
            const response = await apiService.createGroup(groupName, []);
            logger.log("Team creation response:", response);

            // Close modal regardless of specific status code as long as it's successful
            if (response.status >= 200 && response.status < 300) {
                let newGroup;
                if (response.data?.data && response.data.data[0]) {
                    newGroup = response.data.data[0];
                    setMyTeams(prev => [...prev, newGroup]);
                }

                toastUtils.success(`Team Created`, {
                    description: `"${groupName}" has been created successfully`
                });

                // Force clearing input and closing modal
                setTimeout(() => {
                    setGroupName('');
                    setShowCreateModal(false);
                }, 100);

                // Fetch updated list of teams to ensure UI is in sync
                fetchGroups();
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            logger.error("Error creating team:", error);
            toastUtils.error("Failed to create team", {
                description: error.message || "An unexpected error occurred"
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteGroup = async () => {
        if (!selectedGroup) return;

        setIsLoading(true);
        try {
            await apiService.deleteGroup(selectedGroup.id);
            setMyTeams(prev => prev.filter(group => group.id !== selectedGroup.id));
            setOtherTeams(prev => prev.filter(group => group.id !== selectedGroup.id));
            toastUtils.success(`Team Deleted`, {
                description: `"${selectedGroup.name}" has been deleted successfully`
            });
            setShowDeleteModal(false);
            setSelectedGroup(null);
        } catch (error) {
            logger.error("Error deleting team:", error);
            toastUtils.error("Failed to delete team", {
                description: error.message || "An unexpected error occurred"
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateGroupName = async () => {
        if (!selectedGroup || !groupName.trim()) return;

        setIsLoading(true);
        try {
            await apiService.updateGroupName(selectedGroup.id, groupName);
            const updateTeams = teams => teams.map(group =>
                group.id === selectedGroup.id ? { ...group, name: groupName } : group
            );

            setMyTeams(updateTeams);
            setOtherTeams(updateTeams);

            toastUtils.success(`Team Updated`, {
                description: `Team renamed to "${groupName}" successfully`
            });
            setShowCreateModal(false);
        } catch (error) {
            logger.error("Error updating team name:", error);
            toastUtils.error("Failed to update team name", {
                description: error.message || "Please try again later"
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleInviteUsers = async () => {
        if (!selectedGroup || !inviteEmails.trim()) return;

        // Validate emails
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emails = inviteEmails.split(/[,\n]/)
            .map(email => email.trim())
            .filter(email => email.length > 0);

        // Check for invalid emails
        const invalid = emails.filter(email => !emailRegex.test(email));
        if (invalid.length > 0) {
            setValidationErrors(invalid);
            toastUtils.error("Invalid email addresses", {
                description: `${invalid.length} email addresses are invalid. Please correct them.`
            });
            return;
        }

        setValidationErrors([]);
        setInviteLoading(true);
        try {
            await apiService.inviteUsersToGroup({
                inviterId: currentUserId,
                emailsOfInvitees: emails,
                groupName: selectedGroup.name
            });

            toastUtils.success(`Invitations Sent`, {
                description: `Invitations to "${selectedGroup.name}" have been sent successfully`
            });

            setShowInviteModal(false);
            setInviteEmails("");
            setExtractedEmails([]);
            setEmailInputMode('manual');

            // Refresh group members
            fetchGroupMembers(selectedGroup.id);
        } catch (error) {
            logger.error("Error inviting users to team:", error);
            toastUtils.error("Failed to send invitations", {
                description: error.message || "An unexpected error occurred"
            });
        } finally {
            setInviteLoading(false);
        }
    };

    const handleEditGroup = (group) => {
        setSelectedGroup(group);
        setGroupName(group.name);
        setShowCreateModal(true);
    };

    const handleManageMembers = async (group) => {
        setSelectedGroup(group);
        setShowMembersModal(true);
        setShowDeclinedInvitations(false);

        const members = await fetchGroupMembers(group.id);
        setSelectedMembers(members);
    };

    const handleInvite = (group) => {
        setSelectedGroup(group);
        setInviteEmails("");
        setExtractedEmails([]);
        setEmailInputMode('manual');
        setValidationErrors([]);
        setShowInviteModal(true);
    };

    const searchUsers = useCallback(
        debounce(async (term) => {
            if (!term || term.length < 3) {
                setSearchResults([]);
                setIsSearching(false);
                return;
            }

            setIsSearching(true);
            try {
                const response = await apiService.searchCompanyUsers(term);
                if (response.status === 200 && response.data) {
                    setSearchResults(response.data || []);
                }
            } catch (error) {
                logger.error("Error searching users:", error);
                toastUtils.error("Search failed", {
                    description: "Failed to search for users"
                });
            } finally {
                setIsSearching(false);
            }
        }, 500),
        []
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        searchUsers(e.target.value);
    };

    const addMember = (user) => {
        const userId = user.id || user.userId;
        if (!selectedMembers.some(member => (member.id || member.userId) === userId)) {
            setSelectedMembers([...selectedMembers, user]);
            toastUtils.success(`Member Added`, {
                description: `${user.firstName} ${user.lastName} has been added to the selection`
            });
        } else {
            toastUtils.info(`Already Selected`, {
                description: `${user.firstName} ${user.lastName} is already in your selection`
            });
        }
    };

    const removeMember = (user) => {
        const userId = user.id || user.userId;
        setSelectedMembers(selectedMembers.filter(member =>
            (member.id || member.userId) !== userId
        ));
        toastUtils.info(`Member Removed`, {
            description: `${user.firstName} ${user.lastName} has been removed from the selection`
        });
    };

    const handleUpdateMembers = async () => {
        if (!selectedGroup) return;

        setIsLoading(true);
        try {
            // Get current members from cache
            const currentMembers = groupMembers[selectedGroup.id] || [];

            // Find members to add and remove
            const currentMemberIds = currentMembers.map(member => member.id || member.userId);
            const selectedMemberIds = selectedMembers.map(member => member.id || member.userId);

            const membersToAdd = selectedMembers
                .filter(member => !currentMemberIds.includes(member.id || member.userId))
                .map(member => member.id || member.userId);

            const membersToRemove = currentMembers
                .filter(member => !selectedMemberIds.includes(member.id || member.userId))
                .map(member => member.id || member.userId);

            // Process additions
            if (membersToAdd.length > 0) {
                await apiService.addUsersToGroup(selectedGroup.id, membersToAdd);
            }

            // Process removals
            if (membersToRemove.length > 0) {
                await apiService.removeUsersFromGroup(selectedGroup.id, membersToRemove);
            }

            // Update local cache
            setGroupMembers(prev => ({
                ...prev,
                [selectedGroup.id]: selectedMembers
            }));

            toastUtils.success(`Members Updated`, {
                description: `Team members have been updated successfully`
            });
            setShowMembersModal(false);

            // Refresh the members with latest data from server
            fetchGroupMembers(selectedGroup.id);

        } catch (error) {
            logger.error("Error updating team members:", error);
            toastUtils.error("Failed to update team members", {
                description: error.message || "Please try again later"
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Function to get invitation status badge
    const getStatusBadge = (status) => {
        // Only show invitation status badges to subscribers with individual role
        if (!status || (!isIndividualSubscriber() && !isAdministrator())) return null;

        // Only show badge for PENDING or DECLINED (if admin + show declined option enabled)
        switch (status) {
            case 'ACCEPTED':
                // Don't show badge for accepted - this is the normal state
                return null;
            case 'PENDING':
                return <Badge bg="warning" pill className="ms-2 px-2 py-1">
                    <FaUserClock className="me-1" size="10" />Pending Invitation
                </Badge>;
            case 'DECLINED':
                return <Badge bg="danger" pill className="ms-2 px-2 py-1">
                    <FaTimes className="me-1" size="10" />Declined Invitation
                </Badge>;
            default:
                return <Badge bg="secondary" pill className="ms-2">{status}</Badge>;
        }
    };

    // Function to get role badge
    const getRoleBadge = (role) => {
        if (!role) return null;

        switch (role) {
            case 'OWNER':
                return <Badge bg="primary" pill className="me-2 px-2">Owner</Badge>;
            case 'ADMIN':
                return <Badge bg="info" pill className="me-2 px-2">Admin</Badge>;
            case 'MEMBER':
                return <Badge bg="secondary" pill className="me-2 px-2">Member</Badge>;
            default:
                return <Badge bg="secondary" pill className="me-2 px-2">{role}</Badge>;
        }
    };

    // Filter members based on invitation status and admin role
    const filterMembers = (members) => {
        if (!members) return [];

        return members.filter(member => {
            // If not an admin, hide declined members
            if (!isAdministrator() && member.invitationStatus === 'DECLINED') {
                return false;
            }

            // If admin but declined view is toggled off, hide declined members
            if (isAdministrator() && !showDeclinedInvitations && member.invitationStatus === 'DECLINED') {
                return false;
            }

            return true;
        });
    };

    // Additional method to handle file upload for email extraction
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setExtractLoading(true);
        try {
            const emails = await geminiService.extractEmailsFromFile(file);

            if (emails.length === 0) {
                toastUtils.warning("No emails found", {
                    description: "No valid email addresses were found in the uploaded file."
                });
                return;
            }

            // Format emails and add to textarea
            const formattedEmails = emails.join(', ');
            setExtractedEmails(emails);
            setInviteEmails(formattedEmails);

            toastUtils.success("Emails extracted", {
                description: `${emails.length} email addresses extracted from file.`
            });
        } catch (error) {
            logger.error("Error extracting emails from file:", error);
            toastUtils.error("Email extraction failed", {
                description: error.message || "Failed to extract emails from the file."
            });
        } finally {
            setExtractLoading(false);
        }
    };

    // Method to handle pasted text for email extraction
    const handlePastedText = async (text) => {
        if (!text.trim()) return;

        setExtractLoading(true);
        try {
            const emails = await geminiService.extractEmailsFromText(text);

            if (emails.length === 0) {
                toastUtils.warning("No emails found", {
                    description: "No valid email addresses were found in the pasted text."
                });
                return;
            }

            // Format emails and add to textarea
            const formattedEmails = emails.join(', ');
            setExtractedEmails(emails);
            setInviteEmails(formattedEmails);

            toastUtils.success("Emails extracted", {
                description: `${emails.length} email addresses extracted from text.`
            });
        } catch (error) {
            logger.error("Error extracting emails from text:", error);
            toastUtils.error("Email extraction failed", {
                description: error.message || "Failed to extract emails from the text."
            });
        } finally {
            setExtractLoading(false);
        }
    };

    // Method to handle paste from clipboard
    const handlePaste = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            if (clipboardText) {
                handlePastedText(clipboardText);
            } else {
                toastUtils.error("Empty clipboard", {
                    description: "No text found in clipboard."
                });
            }
        } catch (error) {
            logger.error("Error reading from clipboard:", error);
            toastUtils.error("Clipboard access failed", {
                description: "Unable to access clipboard. Please paste text manually."
            });
        }
    };

    // Method to copy emails to clipboard
    const handleCopyEmails = () => {
        if (inviteEmails) {
            navigator.clipboard.writeText(inviteEmails)
                .then(() => {
                    setCopySuccess(true);
                    setTimeout(() => setCopySuccess(false), 2000);
                    toastUtils.success("Copied to clipboard", {
                        description: "Email addresses copied to clipboard"
                    });
                })
                .catch(err => {
                    logger.error("Error copying to clipboard:", err);
                    toastUtils.error("Copy failed", {
                        description: "Failed to copy to clipboard"
                    });
                });
        }
    };

    const renderTeamList = (teams) => {
        if (isLoading && !teams.length) {
            return (
                <div className="text-center py-5">
                    <Spinner animation="border" variant="primary" />
                    <p className="mt-3">Loading teams...</p>
                </div>
            );
        }

        if (!teams.length) {
            return (
                <Card className="text-center p-4 border-0 bg-light">
                    <Card.Body>
                        <FaUsers size={48} className="text-muted mb-3" />
                        <h5>No Teams Yet</h5>
                        <p>Create a team to get started</p>
                        <Button
                            variant="primary"
                            className="mt-3 px-4"
                            onClick={() => {
                                setSelectedGroup(null);
                                setGroupName('');
                                setShowCreateModal(true);
                            }}
                        >
                            <FaPlus className="me-2" /> Create Team
                        </Button>
                    </Card.Body>
                </Card>
            );
        }

        return (
            <ListGroup className="shadow-sm">
                {teams.map(group => (
                    <ListGroup.Item
                        key={group.id}
                        className="d-flex justify-content-between align-items-center p-3 border-start-0 border-end-0"
                    >
                        <div>
                            <h6 className="mb-1 fw-bold">{group.name}</h6>
                            <div className="small">
                                <FaUsers className="me-1" />
                                {group.memberCount || 0} members
                                {group.createdAt && (
                                    <span className="ms-3">
                                        Created: {moment(group.createdAt).format('MMM D, YYYY')}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div>
                            {isIndividualSubscriber() && (
                                <Button
                                    variant="outline-success"
                                    size="sm"
                                    className="me-2 px-3"
                                    onClick={() => handleInvite(group)}
                                >
                                    <FaEnvelope className="me-1" /> Invite
                                </Button>
                            )}
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="me-2 px-3"
                                onClick={() => handleManageMembers(group)}
                            >
                                <FaUserPlus className="me-1" /> Members
                            </Button>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                className="me-2 px-3"
                                onClick={() => handleEditGroup(group)}
                            >
                                <FaEdit className="me-1" /> Edit
                            </Button>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="px-3"
                                onClick={() => {
                                    setSelectedGroup(group);
                                    setShowDeleteModal(true);
                                }}
                            >
                                <FaTrash className="me-1" /> Delete
                            </Button>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    };

    return (
        <Card className="border-0 shadow-sm rounded-3 h-100">
            <Card.Body className="p-4">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h4 className="mb-0"><FaUsers className="me-2" /> Manage Teams</h4>
                    <Button
                        variant="primary"
                        className="px-4"
                        onClick={() => {
                            setSelectedGroup(null);
                            setGroupName('');
                            setShowCreateModal(true);
                        }}
                    >
                        <FaPlus className="me-2" /> Create Team
                    </Button>
                </div>

                <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    className="mb-4"
                >
                    <Tab eventKey="my-teams" title="My Teams">
                        {renderTeamList(myTeams)}
                    </Tab>
                    <Tab eventKey="other-teams" title="Other Teams">
                        {renderTeamList(otherTeams)}
                    </Tab>
                </Tabs>
            </Card.Body>

            {/* Create/Edit Team Modal */}
            <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)} centered>
                <Modal.Header className="border-0 pb-0" closeButton>
                    <Modal.Title>{selectedGroup ? 'Edit Team' : 'Create New Team'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3">
                    <Form.Group className="mb-3">
                        <Form.Label>Team Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter team name"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            className="py-2 px-3"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="border-0 pt-0">
                    <Button
                        variant="light"
                        className="px-4"
                        onClick={() => setShowCreateModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        className="px-4"
                        onClick={selectedGroup ? handleUpdateGroupName : handleCreateGroup}
                        disabled={!groupName.trim() || isLoading}
                    >
                        {isLoading ? <Spinner animation="border" size="sm" /> : (selectedGroup ? 'Update' : 'Create')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Manage Team Members Modal */}
            <Modal show={showMembersModal} onHide={() => setShowMembersModal(false)} size="lg" centered>
                <Modal.Header className="border-0 pb-0" closeButton>
                    <Modal.Title>Manage Team Members: {selectedGroup?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3">
                    <Form.Group className="mb-4">
                        <Form.Label>Search Users</Form.Label>
                        <div className="position-relative">
                            <InputGroup>
                                <InputGroup.Text className="bg-light border-end-0">
                                    <FaSearch />
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by name or email"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    className="border-start-0 ps-0"
                                />
                            </InputGroup>
                            {isSearching && (
                                <div className="position-absolute" style={{ right: '10px', top: '8px' }}>
                                    <Spinner animation="border" size="sm" />
                                </div>
                            )}
                            {searchTerm.length > 0 && searchResults.length > 0 && (
                                <div className="position-absolute w-100 shadow-sm bg-white rounded border mt-1" style={{ zIndex: 10, maxHeight: '200px', overflowY: 'auto' }}>
                                    {searchResults.map(user => (
                                        <div
                                            key={user.id || user.userId}
                                            className="p-2 border-bottom d-flex justify-content-between align-items-center hover-bg-light"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                addMember(user);
                                                setSearchTerm('');
                                                setSearchResults([]);
                                            }}
                                        >
                                            <div>
                                                <div className="fw-bold">{user.firstName} {user.lastName}</div>
                                                <div className="text-muted small">{user.username}</div>
                                            </div>
                                            <Button size="sm" variant="outline-primary" className="">
                                                <FaUserPlus /> Add
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <Form.Text className="text-muted">
                            Type at least 3 characters to search
                        </Form.Text>
                    </Form.Group>

                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h6 className="mb-0">Selected Members ({filterMembers(selectedMembers).length})</h6>

                        {isAdministrator() && (
                            <Button
                                variant={showDeclinedInvitations ? "outline-secondary" : "outline-info"}
                                size="sm"
                                className="px-3"
                                onClick={() => setShowDeclinedInvitations(!showDeclinedInvitations)}
                            >
                                {showDeclinedInvitations ? (
                                    <><FaEyeSlash className="me-1" /> Hide Declined</>
                                ) : (
                                    <><FaEye className="me-1" /> Show Declined</>
                                )}
                            </Button>
                        )}
                    </div>

                    <div className="border rounded shadow-sm p-2" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        {selectedMembers.length === 0 ? (
                            <div className="text-center p-4 text-muted">
                                <FaInfo className="mb-2" size={24} />
                                <p className="mb-0">No members selected. Search and add members above.</p>
                            </div>
                        ) : filterMembers(selectedMembers).length === 0 ? (
                            <div className="text-center p-4 text-muted">
                                <FaInfo className="mb-2" size={24} />
                                <p className="mb-0">
                                    {isAdministrator() && !showDeclinedInvitations ?
                                        "No active members found. Enable 'Show Declined' to view declined invitations." :
                                        "No members found with current filters."}
                                </p>
                            </div>
                        ) : (
                            filterMembers(selectedMembers).map(member => (
                                <div
                                    key={member.id || member.userId}
                                    className={`p-3 mb-2 d-flex justify-content-between align-items-center rounded ${member.invitationStatus === 'PENDING' ? 'bg-warning bg-opacity-10' :
                                        member.invitationStatus === 'DECLINED' ? 'bg-danger bg-opacity-10' :
                                            'bg-light'
                                        }`}
                                >
                                    <div>
                                        <div className="fw-bold">{member.firstName} {member.lastName}</div>
                                        <div className="text-muted small">{member.username}</div>
                                        <div className="mt-2 d-flex align-items-center flex-wrap">
                                            {getRoleBadge(member.role)}
                                            {getStatusBadge(member.invitationStatus)}
                                        </div>
                                    </div>
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        className="rounded-pill"
                                        onClick={() => removeMember(member)}
                                    >
                                        <FaTimes /> Remove
                                    </Button>
                                </div>
                            ))
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 pt-0">
                    <Button
                        variant="light"
                        className="rounded-pill px-4"
                        onClick={() => setShowMembersModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        className="rounded-pill px-4"
                        onClick={handleUpdateMembers}
                        disabled={isLoading}
                    >
                        {isLoading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Invite Users Modal - Updated Version */}
            <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)} centered size="lg">
                <Modal.Header className="border-0 pb-0" closeButton>
                    <Modal.Title>Invite Users to {selectedGroup?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3">
                    <div className="mb-3">
                        <div className="d-flex justify-content-start mb-3">
                            <Button
                                variant={emailInputMode === 'manual' ? 'primary' : 'outline-primary'}
                                className="me-2 rounded-pill"
                                onClick={() => setEmailInputMode('manual')}
                            >
                                <FaEnvelope className="me-1" /> Manual Entry
                            </Button>
                            <Button
                                variant={emailInputMode === 'paste' ? 'primary' : 'outline-primary'}
                                className="me-2 rounded-pill"
                                onClick={() => setEmailInputMode('paste')}
                            >
                                <FaPaste className="me-1" /> Paste Text
                            </Button>
                            <Button
                                variant={emailInputMode === 'file' ? 'primary' : 'outline-primary'}
                                className="rounded-pill"
                                onClick={() => setEmailInputMode('file')}
                            >
                                <FaUpload className="me-1" /> Upload File
                            </Button>
                        </div>

                        {emailInputMode === 'manual' && (
                            <Form.Group>
                                <Form.Label>Email Addresses</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        placeholder="Enter email addresses (comma or new line separated)"
                                        value={inviteEmails}
                                        onChange={(e) => setInviteEmails(e.target.value)}
                                        className="py-2 px-3"
                                    />
                                    {inviteEmails && (
                                        <Button
                                            variant="outline-secondary"
                                            onClick={handleCopyEmails}
                                            title="Copy to clipboard"
                                        >
                                            {copySuccess ? <FaCheck className="text-success" /> : <FaCopy />}
                                        </Button>
                                    )}
                                </InputGroup>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <Form.Text className="text-muted">
                                        Enter one email address per line or separate multiple emails with commas
                                    </Form.Text>
                                    {inviteEmails && (
                                        <small className="text-muted">
                                            {inviteEmails.split(/[,\n]/).filter(email => email.trim().length > 0).length} email(s)
                                        </small>
                                    )}
                                </div>
                            </Form.Group>
                        )}

                        {emailInputMode === 'paste' && (
                            <div>
                                <Form.Label>Extract Emails from Pasted Text</Form.Label>
                                <div className="d-flex mb-3">
                                    <Button
                                        variant="outline-primary"
                                        className="w-100"
                                        onClick={handlePaste}
                                        disabled={extractLoading}
                                    >
                                        {extractLoading ? (
                                            <><Spinner animation="border" size="sm" /> Processing...</>
                                        ) : (
                                            <><FaPaste className="me-1" /> Paste from Clipboard</>
                                        )}
                                    </Button>
                                </div>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Or manually paste text here and click 'Extract Emails'"
                                    className="mb-2"
                                    disabled={extractLoading}
                                    onChange={(e) => { }}
                                    onBlur={(e) => {
                                        if (e.target.value.trim()) {
                                            handlePastedText(e.target.value);
                                        }
                                    }}
                                />
                                <Button
                                    variant="outline-primary"
                                    className="mb-3 w-100"
                                    onClick={(e) => {
                                        const textarea = e.target.previousSibling;
                                        if (textarea && textarea.value.trim()) {
                                            handlePastedText(textarea.value);
                                        }
                                    }}
                                    disabled={extractLoading}
                                >
                                    {extractLoading ? (
                                        <><Spinner animation="border" size="sm" /> Processing...</>
                                    ) : (
                                        <><FaSearch className="me-1" /> Extract Emails</>
                                    )}
                                </Button>

                                {/* Help text explaining how it works */}
                                <div className="mb-3 px-3 py-2 bg-light border rounded">
                                    <small className="text-muted">
                                        <FaInfo className="me-1" /> This feature uses AI to extract email addresses from any text.
                                        It will analyze the content and extract only valid email addresses while filtering out
                                        duplicates and invalid formats.
                                    </small>
                                </div>

                                {extractedEmails.length > 0 && (
                                    <div className="mb-3">
                                        <div className="d-flex justify-content-between align-items-center mb-1">
                                            <Form.Label className="mb-0">Extracted Emails:</Form.Label>
                                            <Badge bg="success" pill>
                                                {extractedEmails.length} found
                                            </Badge>
                                        </div>
                                        <InputGroup>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                value={inviteEmails}
                                                onChange={(e) => setInviteEmails(e.target.value)}
                                                className="py-2 px-3"
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                onClick={handleCopyEmails}
                                                title="Copy to clipboard"
                                            >
                                                {copySuccess ? <FaCheck className="text-success" /> : <FaCopy />}
                                            </Button>
                                        </InputGroup>
                                    </div>
                                )}
                            </div>
                        )}

                        {emailInputMode === 'file' && (
                            <div>
                                <Form.Label>Extract Emails from File</Form.Label>
                                <div className="border rounded p-4 text-center mb-3">
                                    <FaFileAlt size={40} className="text-primary mb-3" />
                                    <p>Upload a file to extract email addresses</p>
                                    <p className="text-muted small">Supports text files, CSVs, PDFs, images, and more</p>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileUpload}
                                        className="d-none"
                                        accept=".txt,.csv,.pdf,.jpg,.jpeg,.png,.doc,.docx"
                                    />
                                    <Button
                                        variant="outline-primary"
                                        className="rounded-pill px-4"
                                        onClick={() => fileInputRef.current?.click()}
                                        disabled={extractLoading}
                                    >
                                        {extractLoading ? (
                                            <><Spinner animation="border" size="sm" /> Processing...</>
                                        ) : (
                                            <><FaUpload className="me-1" /> Choose File</>
                                        )}
                                    </Button>
                                </div>

                                {/* Help text explaining how it works */}
                                <div className="mb-3 px-3 py-2 bg-light border rounded">
                                    <small className="text-muted">
                                        <FaInfo className="me-1" /> This feature uses AI Vision to analyze documents and images to
                                        extract email addresses. For text files, it will extract any valid emails found in the text.
                                        For non-text files like images, it will use AI to read and detect emails.
                                    </small>
                                </div>

                                {extractedEmails.length > 0 && (
                                    <div className="mb-3">
                                        <div className="d-flex justify-content-between align-items-center mb-1">
                                            <Form.Label className="mb-0">Extracted Emails:</Form.Label>
                                            <Badge bg="success" pill>
                                                {extractedEmails.length} found
                                            </Badge>
                                        </div>
                                        <InputGroup>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                value={inviteEmails}
                                                onChange={(e) => setInviteEmails(e.target.value)}
                                                className="py-2 px-3"
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                onClick={handleCopyEmails}
                                                title="Copy to clipboard"
                                            >
                                                {copySuccess ? <FaCheck className="text-success" /> : <FaCopy />}
                                            </Button>
                                        </InputGroup>
                                    </div>
                                )}
                            </div>
                        )}

                        {validationErrors.length > 0 && (
                            <div className="mt-3 alert alert-danger">
                                <p className="mb-2"><strong>Invalid email addresses:</strong></p>
                                <ul className="mb-0 ps-3">
                                    {validationErrors.map((email, index) => (
                                        <li key={index}>{email}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {/* Show a stats summary when we have emails */}
                        {inviteEmails && (
                            <div className="mt-3 p-2 bg-light rounded border">
                                <div className="d-flex justify-content-between">
                                    <small className="text-muted">Email addresses to invite:</small>
                                    <small className="fw-bold">{inviteEmails.split(/[,\n]/).filter(email => email.trim().length > 0).length}</small>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 pt-0">
                    <Button
                        variant="light"
                        className="rounded-pill px-4"
                        onClick={() => setShowInviteModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        className="rounded-pill px-4"
                        onClick={handleInviteUsers}
                        disabled={!inviteEmails.trim() || inviteLoading || extractLoading}
                    >
                        {inviteLoading ? <Spinner animation="border" size="sm" /> : 'Send Invitations'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                centered
                contentClassName="padding-40px"
                style={{
                    overflow: "hidden",
                    fontFamily: "Montserrat",
                    borderRadius: "30px",
                }}
            >
                <Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingBottom: 0,
                        margin: "0px",
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: "100%", textAlign: "center" }}
                    >
                        {/* Title is intentionally left empty as per informationModal */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center position-relative" style={{ bottom: "15px" }}>
                    <div className="text-primary mt-0" style={{ fontSize: "1.3rem", fontWeight: 200 }}>
                        <p>Are you sure you want to delete the team "{selectedGroup?.name}"?</p>
                    </div>
                    <div className="mt-3">
                        <button
                            className="btn btn-outline-primary"
                            style={{
                                borderRadius: "20px",
                                marginLeft: "0px",
                                width: "8rem"
                            }}
                            onClick={() => setShowDeleteModal(false)}
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-outline-primary"
                            style={{
                                borderRadius: "20px",
                                marginLeft: "20px",
                                width: "8rem"
                            }}
                            onClick={handleDeleteGroup}
                            disabled={isLoading}
                        >
                            {isLoading ? <Spinner animation="border" size="sm" /> : 'Delete'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </Card>
    );
};

export default Groups; 