// @ts-nocheck

/**
 * Project: spaces
 * File: Login
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React, { FormEvent, useLayoutEffect, useState } from "react";
import { CloseCircle, EyeOutline, EyeOffOutline } from "react-ionicons";
import { Link, useLocation, useHistory } from "react-router-dom";
import { PageTitle, RightComponent, LeftComponent } from "../../layout/PageData";
import { toAbsoluteUrl } from "../../layout/helpers";
import queryString from "query-string";
import apiService from "../../services/apiService";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { getApp } from "firebase/app";
import { toast } from 'sonner';
import toastUtils from '../../utils/toast';
import SERVICES from '../../services';
import logger from "../../utils/logger";

const inputStyles = {
    WebkitBoxShadow: "0 0 0 30px white inset !important",
    WebkitTextFillColor: "#000000 !important",
    transition: "background-color 5000s ease-in-out 0s",
    background: "white !important"
};

const StyledEmailInput = ({ email, setEmail }) => {
    return (
        <div className="form-group basic">
            <div className="input-wrapper">
                <input
                    type="email"
                    className="form-control input-new-rounded floating-input"
                    id="email1"
                    autoComplete="username"
                    placeholder=" "
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    style={{
                        backgroundColor: 'white',
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                        WebkitTextFillColor: '#000'
                    }}
                />
                <label
                    className="floating-label-new-background"
                    htmlFor="email1"
                >
                    E-mail
                </label>
                {email && (
                    <i className="clear-input">
                        <CloseCircle
                            color={"#00000"}
                            title={"Clear"}
                            onClick={() => setEmail('')}
                            style={{ cursor: 'pointer' }}
                        />
                    </i>
                )}
            </div>
        </div>
    );
};

const StyledPasswordInput = ({ password, setPassword }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="form-group basic">
            <div className="input-wrapper">
                <input
                    type={showPassword ? "text" : "password"}
                    className="form-control floating-input input-new-rounded"
                    id="password1"
                    autoComplete="current-password"
                    placeholder=" "
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    style={{
                        backgroundColor: 'white',
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                        WebkitTextFillColor: '#000'
                    }}
                />
                <label
                    className="floating-label-new-background"
                    htmlFor="password1"
                >
                    Password
                </label>
                <i
                    className="clear-input"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: 'pointer' }}
                >
                    {showPassword ? (
                        <EyeOffOutline
                            color={"#00000"}
                            title={"Hide password"}
                            height="24px"
                            width="24px"
                        />
                    ) : (
                        <EyeOutline
                            color={"#00000"}
                            title={"Show password"}
                            height="24px"
                            width="24px"
                        />
                    )}
                </i>
            </div>
        </div>
    );
};

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const search = queryString.parse(location.search);
    const params = search && search.payload ? search.payload : "";

    const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');

        let credentials = {
            password,
            username: email,
            companyId: 1,
        };

        try {
            const res = await apiService.login(credentials);

            if (res.status === 200) {
                const user = res.data.data[0];

                if (!user) {
                    logger.error("No user data received");
                    toast.error("Login failed: No user data received");
                    return;
                }

                await onLoginSuccessful(res);

                const fcmToken = await getFCMToken();

                if (fcmToken) {
                    try {
                        await apiService.updateFCMToken(user.id, fcmToken);
                    } catch (updateError) {
                        logger.error("Error updating FCM token:", updateError);
                        toastUtils.warning("Failed to update notification settings", {
                            title: "Notification Settings"
                        });
                    }
                }
            }
        } catch (err) {
            logger.error("Login error:", err);
            toastUtils.error('Login failed. Please try again.', {
                title: "Authentication Error"
            });
            setError('Login failed. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    useLayoutEffect(() => {
        const sidebarPanel = document.getElementById('sidebarPanel') as HTMLAnchorElement;
        sidebarPanel.addEventListener('shown.bs.modal', function (event) {
            sidebarPanel.setAttribute('data-bs-dismiss', 'modal');
        });
    }, []);

    const googleLogin = async () => {
        try {
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: "select_account"
            });

            const result = await signInWithPopup(auth, provider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const accessToken = credential?.accessToken;

            if (!accessToken) {
                throw new Error("No access token received from Google");
            }

            const response = await apiService.googleAuth({
                accessToken,
                companyId: 1,
                provider: "GOOGLE"
            });

            await onLoginSuccessful(response);

            const user = response.data.data[0];
            const fcmToken = await getFCMToken();

            if (fcmToken) {
                try {
                    await apiService.updateFCMToken(user.id, fcmToken);
                } catch (updateError) {
                    logger.error("Error updating FCM token:", updateError);
                    toastUtils.warning("Failed to update notification settings", {
                        title: "Notification Settings"
                    });
                }
            }
        } catch (error) {
            logger.error("Google login error:", error);
            toastUtils.error('Failed to login with Google. Please try again.', {
                title: "Google Login Error"
            });
        }
    };

    const getFCMToken = async () => {
        try {
            const app = getApp();
            const messaging = getMessaging(app);
            const token = await getToken(messaging);
            logger.log("FCM Token:", token);
            return token;
        } catch (error) {
            logger.error("Error getting FCM token:", error);
            return null;
        }
    };

    function onLoginSuccessful(res: AxiosResponse<any>) {
        const response = res.data;
        const responseData = response?.data;
        const currentUser = (responseData && responseData.length > 0) ? responseData[0] : null;
        const packagePlan = currentUser?.packagePlan;

        // Save package plan status in localStorage
        if (packagePlan) {
            localStorage.setItem('hasPackagePlan', 'true');
            logger.log("User has package plan:", packagePlan);
        } else {
            localStorage.setItem('hasPackagePlan', 'false');
            logger.log("User does not have package plan");
        }

        SERVICES.saveUser(currentUser);

        const userCountry = currentUser?.country;
        const currency = userCountry.currency;

        logger.log('currency associated with company is:', currency);

        SERVICES.setCurrency(currency);

        const role = currentUser?.role;
        logger.log('current user role is:', role);

        const companyId = currentUser ? currentUser.companyId : 0;
        SERVICES.setCompanyId(companyId);

        if (role != null) {
            switch (role) {
                // Corporate & Employee Users - Go to search page
                case 'ROLE_SUBSCRIBER_INDIVIDUAL':
                case 'ROLE_SUBSCRIBER_OWNER':
                case 'ROLE_EMPLOYEE':
                case 'ROLE_APPROVER_EMPLOYEE':
                    history.push('/searchPage');
                    break;

                // Host & Operations - Go to properties
                case 'ROLE_HOST':
                case 'ROLE_HOST_OPERATIONS':
                    history.push('/properties');
                    break;

                // Administrator - Special access
                case 'ROLE_ADMINISTRATOR':
                    history.push('/properties');
                    break;

                default:
                    // Unrecognized role - Show error
                    setErrorMsg('Invalid role or permissions');
                    setErrorAlert(true);
                    // Logout after 2 seconds
                    setTimeout(() => {
                        SERVICES.clearUser();
                        history.push('/signin');
                    }, 2000);
            }
        }
    }

    const isValidJSON = (str: string) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    let payload = null;
    // @ts-ignore
    const decodedString = atob(params);
    if (isValidJSON(decodedString)) {
        payload = JSON.parse(decodedString);
        // localStorage.setItem('payload',payload)
        SERVICES.saveUser(payload);
    }

    const onLoginError = (error: any) => {
        logger.error('Google login failed:', error);
        setError('Failed to login with Google. Please try again.');
    };

    return (
        <div className="position-relative overflow-hidden" style={{ marginTop: "-56px" }}>
            <PageTitle>{false}</PageTitle>
            <RightComponent>{false}</RightComponent>
            <LeftComponent>{false}</LeftComponent>

            <div className="section position-relative p-0">
                <img
                    className="w-100"
                    decoding={"async"}
                    src={toAbsoluteUrl("/img/Rectangle 18.png")}
                    alt={"Logo"}
                />

                <div className="section mt-2 position-absolute"
                    style={{ bottom: "88px", left: "16px" }}>
                    <h2 className={"text-capitalize text-white"}>Sign in</h2>
                    <h4 className={"mt-2 text-white"}>Welcome back to SPACIA!</h4>
                </div>
            </div>
            <div className="section mb-5 p-2 position-relative"
                style={{
                    backgroundColor: "white",
                    borderTopRightRadius: "30px",
                    borderTopLeftRadius: "30px",
                    marginTop: "-21px",
                }}>
                <form onSubmit={handleLogin}>
                    <div className="card-body pb-1">
                        <StyledEmailInput email={email} setEmail={setEmail} />
                        <StyledPasswordInput password={password} setPassword={setPassword} />

                        <div className="d-flex flex-column gap-3">
                            <div className="text-end">
                                <Link to="/reset-password-mobile" style={{ color: '#1F2937', textDecoration: 'none' }}>
                                    Forgot Password?
                                </Link>
                            </div>

                            <button
                                type='submit'
                                className="btn btn-primary btn-lg w-100"
                                style={{ borderRadius: "40rem" }}
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <div className="spinner-border spinner-border-sm text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : 'Sign in'}
                            </button>

                            <div className="text-center">
                                <p className="text-dark mb-0">
                                    Don't have an account?
                                </p>
                                <div className="d-flex flex-column gap-2 mt-2">
                                    <Link to={"/signup"} className="btn btn-outline-primary" style={{ borderRadius: "40rem" }}>
                                        Sign Up As a Business
                                    </Link>
                                    <Link to={"/register-host"} className="btn btn-outline-primary" style={{ borderRadius: "40rem" }}>
                                        Sign Up As Host/Individual
                                    </Link>
                                </div>
                            </div>

                            <div className="text-center">
                                <p className="text-muted mb-2">or continue with</p>
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={googleLogin}
                                >
                                    <img
                                        src={toAbsoluteUrl("/img/google.png")}
                                        alt={"Google Sign In"}
                                    />
                                </button>
                            </div>

                            <div className="text-center mt-3">
                                <Link
                                    to="/home"
                                    className="d-block mb-3"
                                    style={{
                                        color: '#4E46E5FF',
                                        textDecoration: 'none',
                                        fontSize: '0.9rem',
                                        fontWeight: '500'
                                    }}
                                >
                                    ← Go to Home
                                </Link>
                                <p className="text-muted mb-1" style={{ fontSize: '0.7rem', opacity: 0.6 }}>
                                    Powered by theSOFTtribe
                                </p>
                                <p className="text-muted text-capitalize mb-0">
                                    &copy;2022 SPACIA All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {error && (
                <div className="alert alert-danger mt-3">
                    {error}
                </div>
            )}
        </div>
    );
};
export default Login;
