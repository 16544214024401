import React, { useState, useEffect } from "react";
// import TextField from '@material-ui/core/TextField';
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// import DatePicker from "@material-ui/lab/DatePicker";
import {
	Container,
	Table,
	Toast,
	Button,
	DropdownButton,
	Dropdown,
	InputGroup,
	FormControl, Spinner,
} from "react-bootstrap";
import { FaCaretDown, FaEllipsisH, FaSearch } from "react-icons/fa";
import PaymentTableRow from "../../components/PaymentTableRow";
import Search from "../../components/Search";
import UserTableRow from "../../components/UserTableRow";
import mastercard from "../../assets/img/Web/Spacia/mastercard.png";
import axios from "axios";
import SERVICES from '../../services';
import moment from 'moment-timezone';
import { Constants } from '../../utils/constants';
import ReactPaginate from "react-paginate";
import { BillingStyles } from "./BillingStyles";
import Pagination from "../../components/Pagination";
import { useHistory } from 'react-router-dom';
import { InvoiceModal as Modal, MobileWalletModal } from "../../components/Modals/ModalStyles";
import warning from "../../img/Web/Spacia/t-warning 3.svg";
import { BlockerModal } from "../../components/FeedbackModall";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import apiService from '../../services/apiService';
import logger from "../../utils/logger";

const UserManagement = () => {
	const [show, setShow] = useState(false);
	const [showStatementModal, setShowStatementModal] = useState(false);
	const [hasNoMobileWalletAccount, setHasNoMobileWalletAccount] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [totalPageSize, setTotalPageSize] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [allUnpaidInvoices, setAllUnpaidInvoices] = useState([]);
	const [makeRequestForUnpaidInvoices, setMakeRequestForUnpaidInvoices] = useState(false);
	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedInvoices, setSelectedInvoices] = useState(null);
	const [mobileWalletAccounts, setMobileWalletAccounts] = useState([]);
	const [currency, setCurrency] = useState('');

	const [selectedMonthAndYear, setSelectedMonthAndYear] = useState(new Date());
	const [monthStartDate, setMonthStartDate] = useState("");
	const [monthEndDate, setMonthEndDate] = useState("");

	const history = useHistory();

	useEffect(() => {
		setCurrency(SERVICES.getCurrency());
	}, []);

	const getBookingsSuccessful = (res) => {
		logger.log(res);
		setallpayments(res.data.data);
	};

	const [allpayments, setallpayments] = useState([]);
	const [token, setToken] = useState('');
	const [userId, setuserId] = useState('N/A');

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		setuserId(currentUser);
		const token = '123';
		setToken(token);

		apiService.getInvoices(currentUser.id, { statusNotIncluded: 'INCOMPLETE' })
			.then((res) => {
				if (res.status === 200) {
					setTotalPageSize(res.data.totalCount);
					setItemsPerPage(res.data.pageSize);
					getBookingsSuccessful(res);
				}
			})
			.catch((err) => logger.log(err));
	}, []);

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;

		apiService.getUserPaymentMethods(userId)
			.then(res => {
				if (res.status === 200) {
					logger.log(res.data);
					const responseData = res.data.data;
					setMobileWalletAccounts(responseData);
					logger.log('mobile money accounts:', responseData);
				}
			});
	}, []);

	const formatDateToString = (date) => {
		let dateStr = null;

		if (date) {
			logger.log("about to format date: " + date + " to 'dd/MM/yyyy'");

			try {
				dateStr = moment(date).format("DD/MM/yyyy");
			} catch (e) {
				logger.log("an error occurred while formatting date to 'dd/MM/yyyy' string");
			}
		}
		return dateStr;
	};

	const handleMonthAndYearChange = (value) => {
		logger.log('month / year value is:', value);

		const date = new Date(value);
		const year = date.getFullYear(); // get date full year
		const month = date.getMonth(); // get month

		const startOfMonth = formatDateToString(new Date(year, month, 1));
		const endOfMoth = formatDateToString(new Date(year, (month + 1), 0));

		logger.log('start of month:', startOfMonth);
		logger.log('end of month:', endOfMoth);

		setMonthStartDate(startOfMonth);
		setMonthEndDate(endOfMoth);

		setSelectedMonthAndYear(value);
	};

	const [isDownloading, setIsDownloading] = useState(false);

	const downloadStatement = async () => {
		if (isDownloading) return;

		try {
			setIsDownloading(true);
			let startAt = monthStartDate;
			let endAt = monthEndDate;

			const year = selectedMonthAndYear.getFullYear(); // get date full year
			const month = selectedMonthAndYear.getMonth(); // get month

			const startOfMonth = formatDateToString(new Date(year, month, 1));
			const endOfMoth = formatDateToString(new Date(year, (month + 1), 0));

			if (!startAt) {
				startAt = startOfMonth;
			}

			if (!endAt) {
				endAt = endOfMoth;
			}

			logger.log('downloading statement. start date:', startAt);
			logger.log('downloading statement. end date:', endAt);

			const currentUser = SERVICES.getUser();
			const userId = currentUser ? currentUser.id : 0;

			const response = await apiService.downloadStatementPdf({
				customerId: userId,
				userId: userId,
				startAt: startAt,
				endAt: endAt
			});

			// Check if we got a valid blob response
			if (!(response instanceof Blob)) {
				throw new Error('Invalid response format');
			}

			const url = window.URL.createObjectURL(response);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `statement_${startAt}_${endAt}.pdf`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			logger.error('Error downloading statement:', error);
			// Show error toast or message to user
			// toast.error('Failed to download statement. Please try again.');
		} finally {
			setIsDownloading(false);
		}
	};

	const viewPayment = async (token) => {
		try {
			const response = await apiService.downloadInvoicePdf(userId.id, token);
			const blobData = response.data || response;
			const url = window.URL.createObjectURL(blobData);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `invoice-${token}.pdf`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			logger.error('Error downloading invoice:', error);
			// Show error toast or message to user
			// toast.error('Failed to download invoice. Please try again.');
		}
	};

	const pageCount = Math.ceil(totalPageSize / itemsPerPage);

	const changePage = (selected) => {
		const currentUser = SERVICES.getUser();
		setuserId(currentUser);
		const token = '123';
		setToken(token);

		setCurrentPage(selected);

		apiService.getInvoices(currentUser.id, { page: (selected - 1) })
			.then((res) => {
				if (res.status === 200) {
					setTotalPageSize(res.data.totalCount);
					setItemsPerPage(res.data.pageSize);
					getBookingsSuccessful(res);
				}
			})
			.catch((err) => logger.log(err));
	};

	const showMakePaymentPage = () => {
		history.push({
			pathname: '/make-payment',
			state: {
				invoiceAmount: totalAmount,
				selectedInvoices
			}
		});
	};

	const displayMobileWalletAccountSetupPage = () => {
		history.push('/settings/momopayment');
	};

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser?.id;

		if (show) {
			// make ajax call for all unpaid invoices
			setMakeRequestForUnpaidInvoices(true);

			apiService.getInvoices(currentUser.id, { params: { userId, isPaged: false } })
				.then((res) => {
					if (res.status === 200) {
						setMakeRequestForUnpaidInvoices(false);

						logger.log('invoices:', res.data);
						const invoices = res.data.data;
						const allUnPaidInvoices = invoices.filter(invoice => {
							return invoice.isActive && !invoice.isCancelled && !invoice.isPaid;
						});

						setAllUnpaidInvoices(allUnPaidInvoices);

						logger.log('all unpaid invoices:', allUnPaidInvoices);
					}
				})
				.catch((res) => {
					setMakeRequestForUnpaidInvoices(false);

					setAllUnpaidInvoices([]);
					logger.log(res);
				});
		}
	}, [show]);

	const showInvoiceModal = () => {
		setShow(true);
	};

	const renderResultForUnpaidInvoices = () => {
		let whatToRender = '';

		if (makeRequestForUnpaidInvoices) {
			whatToRender = (
				<div style={{ display: 'flex', justifyContent: 'center', padding: '70px 0' }}>
					<Spinner size="sm" animation="border" role="status" style={{ width: '50px', height: '50px' }}>
						<span className="visually-hidden"></span>
					</Spinner>{" "}
				</div>
			);
		} else if (!makeRequestForUnpaidInvoices && allUnpaidInvoices.length === 0) {
			whatToRender = (
				<div style={{ display: 'flex', justifyContent: 'center', padding: '70px 0' }}>
					<p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>No Unpaid Invoices</p>
				</div>
			);
		} else if (!makeRequestForUnpaidInvoices && allUnpaidInvoices.length > 0) {
			whatToRender = (
				<div role='table'>
					<table>
						<thead>
							<tr>
								<th scope='col'>Number</th>
								<th scope='col'>Value</th>
								<th scope='col'>Date</th>
								<th scope='col'>Check All &nbsp; <input type="checkbox" onChange={e => {
									const isChecked = e.currentTarget.checked;

									logger.log('is checked?', isChecked);

									// select all checkboxes
									const allInvoiceCheckboxes = document.querySelectorAll('.invoice-check');
									allInvoiceCheckboxes.forEach(checkbox => {
										checkbox.checked = isChecked;
									});

									if (isChecked) {
										const selectedInvoicesAmount = allUnpaidInvoices.map(o => o.netAmount).reduce((val1, val2) => val1 + val2, 0);
										logger.log('selected invoices amount:', selectedInvoicesAmount);
										setTotalAmount(selectedInvoicesAmount);

										setSelectedInvoices(allUnpaidInvoices);
									} else {
										setTotalAmount(0);
										setSelectedInvoices(null);
									}

								}} /></th>
							</tr>
						</thead>
						<tbody>
							{
								allUnpaidInvoices.map((unpaidInvoice, index) => {
									const amount = unpaidInvoice.netAmount.toLocaleString();

									const invoiceValue = `${currency} ${amount}`;
									return <tr>
										<td>{index + 1}</td>
										<td>{invoiceValue}</td>
										<td>{moment(unpaidInvoice.createdOn).format('DD-MMM-YYYY')}</td>
										<td>
											<input type="checkbox" className='invoice-check' onChange={(e) => {
												// logger.log('pending hosts find:', allUnpaidInvoices.find(o=>o.referenceCode === unpaidInvoice.referenceCode).checked = e.target.checked);
												// pendingHosts.find(o=>o.companyId===pendingHost.companyId).checked = e.target.checked;
												// const selectedInvoicesAmount = allUnpaidInvoices.filter(o=>o.checked===true).map(o=> o);
												const checked = allUnpaidInvoices.find(o => o.referenceCode === unpaidInvoice.referenceCode).checked = e.target.checked;
												const selectedInvoicesAmount = allUnpaidInvoices.filter(o => o.checked === true).map(o => o.netAmount).reduce((val1, val2) => val1 + val2, 0);
												logger.log('selected invoices amount:', selectedInvoicesAmount);
												setTotalAmount(selectedInvoicesAmount);

												const checkedInvoices = allUnpaidInvoices.find(o => o.referenceCode === unpaidInvoice.referenceCode).checked = e.target.checked;
												const selectedInvoices = allUnpaidInvoices.filter(o => o.checked === true).map(o => o);

												setSelectedInvoices(selectedInvoices);

											}} />
										</td>
									</tr>;
								})
							}
						</tbody>
					</table>
				</div>
			);
		}

		return whatToRender;
	};

	return (
		<div>
			<div>
				{/* Header */}
				<div className="header">
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: 'center'
						}}
					>
						<div>
							<h3 style={{ fontSize: '1.6rem', fontWeight: 'normal' }}>
								<b>Billing</b>
							</h3>
						</div>

						<form
							className="form-inline my-2 my-xl-0"
							style={{ width: "30vw" }}
						>
							{/* <input
								className="form-control mr-sm-2"
								type="search"
								placeholder="Search"
								aria-label="Search"
							/> */}
							{/*	<InputGroup>
										<FormControl id="inlineFormInputGroupUsername" placeholder="Search" />
										<InputGroup.Append>
											<InputGroup.Text><FaSearch /></InputGroup.Text>
										</InputGroup.Append>
									</InputGroup> */}
							{/*<button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>*/}
						</form>

						<div className="dropdown" style={{ margin: "auto 0", marginLeft: 'auto', display: 'flex' }}>
							<button
								type='button'
								onClick={() => setShowStatementModal(true)}
								className="button"
								style={{
									width: "210px",
									height: "37px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									whiteSpace: "nowrap",
									padding: "20 80px",
								}}
							>
								Download Statement
							</button>
							{/* <button type='button' onClick={showInvoiceModal} className="button" style={{ width: "100%" }}>
								Make Payment
							</button> */}
							{/* <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Dropdown button
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" href="#">Action</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                        </div> */}
							{/* <Dropdown>
								<Dropdown.Toggle
									style={{
										backgroundColor: "#ECECEC",
										border: "none",
										color: "#848484",
									}}
									variant="success"
									id="dropdown-basic"
								>
									Filter
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item href="#/action-1">
										Action
									</Dropdown.Item>
									<Dropdown.Item href="#/action-2">
										Another action
									</Dropdown.Item>
									<Dropdown.Item href="#/action-3">
										Something else
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown> */}
						</div>
					</div>
				</div>
				{/* End Header */}

				<Container>
					<BillingStyles>
						<Toast
							style={{ width: "100%", padding: 20, marginBottom: 50, maxWidth: '100%' }}
						>
							<h6 style={{ fontWeight: "bold" }}>
								Transaction History
							</h6>
							<Table>
								<tr className="tableUnderline">
									<th style={{width: '15%', textAlign: 'left'}}>Date</th>
									<th style={{width: '25%', textAlign: 'left'}}>Description</th>
									<th style={{width: '20%', textAlign: 'right'}}>Amount</th>
									<th style={{width: '20%', textAlign: 'right'}}>Invoice</th>
									<th style={{width: '20%', textAlign: 'right'}}>Status</th>
								</tr>

								{
									allpayments.map((payment) => {
										let paymentStatus = undefined;

										if (payment.isPaid || (payment.order && payment.order.orderStatus === 'paid')) {
											paymentStatus = 'PAID';
										} else if (payment.isActive && !payment.isCancelled) {
											paymentStatus = 'ACTIVE';
										} else if (payment.isCancelled) {
											paymentStatus = 'CANCELLED';
										}

										return <PaymentTableRow
											date={moment(payment.createdOn).format('DD-MMM-YYYY HH:mm')}
											// bank={mastercard}
											description={payment.description}
											status={paymentStatus}
											role="Admin"
											invoice={payment}
											mobileWalletAccounts={mobileWalletAccounts}
											setHasNoWalletWalletAccount={setHasNoMobileWalletAccount}
											amount={payment.grossAmount || 0}
											currency={payment.currency || SERVICES.getCurrency()}
											onView={() => viewPayment(payment.referenceCode)}
										/>;
									})}
								{
									Array.isArray(allpayments) && allpayments.length > 0 &&
									<caption>
										<Pagination
											className="pagination-bar"
											currentPage={currentPage || 1}
											totalCount={totalPageSize}
											pageSize={10}
											onPageChange={changePage}
										/>
									</caption>
								}
							</Table>
							<div style={{ width: '300px' }}>
								{/*// <ReactPaginate*/}
								{/*// 	previousLabel={'<'}*/}
								{/*// 	nextLabel={'>'}*/}
								{/*// 	pageCount={pageCount}*/}
								{/*// 	marginPagesDisplayed={0}*/}
								{/*// 	pageRangeDisplayed={6}*/}
								{/*// 	onPageChange={changePage}*/}
								{/*// 	containerClassName={'whole-block'}*/}
								{/*// 	activeClassName={'paginate-active'}*/}
								{/*// 	disabledClassName={'paginate-disabled'}*/}
								{/*// 	previousLinkClassName={'previous-link'}*/}
								{/*// 	nextLinkClassName={'next-link'}/>*/}
							</div>
						</Toast>
					</BillingStyles>

					{/* <Toast style={{width:'100%', padding:20}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Search />
                    <div style={{display:'flex'}}>
                        <h6 className="text-muted" style={{marginRight:10}}>Sort by</h6>
                        <h6 style={{fontWeight:'bold'}}>Status-invited</h6>
                        <FaCaretDown />
                    </div>
                </div>
                <Table className="w3-striped">
                    <tr className="tableUnderline">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Role</th>
                        <th>Action</th>
                    </tr>
                    <tr>
                        <td><div> <img src="" alt="User"/>Nana Kweku Adumatta</div></td>
                        <td>mr.adumatta@gmail.com</td>
                        <td>Active</td>
                        <td><button className="role-admin">Active</button></td>
                        <td>Edit</td>
                        <td><FaEllipsisH/></td>
                    </tr>
                </Table>
                </Toast> */}

					<Modal className='statement-modal' show={showStatementModal} onHide={() => setShowStatementModal(false)}>
						<Modal.Header closeButton>
							<div className=""></div>
						</Modal.Header>
						<Modal.Body style={{ padding: '30px 0 50px 0', marginRight: 'auto' }}>
							<div className='datetime-picker' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker
										views={['month', 'year']}
										minDate={new Date('2020-01-01')}
										maxDate={new Date('2050-12-31')}
										value={selectedMonthAndYear}
										onChange={(newValue) => {
											handleMonthAndYearChange(newValue);
										}}
										renderInput={(params) => <TextField {...params} helperText={null} />}
									/>
								</LocalizationProvider>
								<button type='button' style={{ width: '35%', cursor: 'pointer' }} className='button' onClick={downloadStatement}>Get Statement</button>
							</div>
						</Modal.Body>
					</Modal>

					<Modal show={show} onHide={handleClose} className={"text-primary mt-5"}
						style={{ fontSize: "1.3rem", fontWeight: 200 }}>
						<Modal.Header closeButton>
							<div className=""></div>
						</Modal.Header>
						<Modal.Body>
							<header>
								<div>
									<h2>Select Invoice</h2>
									<h2>Total: {SERVICES.getCurrency()} {totalAmount}</h2>
								</div>
								<button type='button' style={{ cursor: (!selectedInvoices || (selectedInvoices && selectedInvoices.length <= 0)) ? 'not-allowed' : 'pointer', borderRadius: "20px", }} disabled={!selectedInvoices || (selectedInvoices && selectedInvoices.length <= 0)} className="btn btn-outline-primary" onClick={showMakePaymentPage}>Continue</button>
							</header>
							{
								renderResultForUnpaidInvoices()
							}
						</Modal.Body>
					</Modal>

					<BlockerModal title="Sorry" isOpen={hasNoMobileWalletAccount} isClose={() => setHasNoMobileWalletAccount(false)} onSubmit={() => history.push("/settings/momopayment")} doneButton="  Account Setup  ">
						<p style={{ fontWeight: 400, fontSize: '1.05rem' }} className="text-muted">Whoa! You do not have a mobile wallet account set up. Please click on the button below to set up a mobile wallet account</p>
						<br />
						<img src={warning} alt="warning" />
					</BlockerModal>
					{/* <MobileWalletModal show={hasNoMobileWalletAccount} onHide={() => setHasNoMobileWalletAccount(false)}>
					{/*	<Modal.Header closeButton>*/}
					{/*	</Modal.Header>*/}
					{/*	<Modal.Body>*/}
					{/*		<section>*/}
					{/*			<h5 style={{marginBottom: '20px'}}>You do not have a mobile wallet account set up.</h5>*/}
					{/*			<p style={{marginBottom: '30px'}}>Please click on the button below to set up a mobile wallet account</p>*/}
					{/*			<div style={{textAlign: 'center'}}>*/}
					{/*				<Button variant="danger" size="md" onClick={displayMobileWalletAccountSetupPage}>Account Setup</Button>*/}
					{/*			</div>*/}
					{/*		</section>*/}
					{/*	</Modal.Body>*/}
					{/*</MobileWalletModal>*/}
				</Container>
			</div>
		</div>
	);
};

export default UserManagement;
