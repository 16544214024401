import { Component } from "react";
import { Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { companyServiceRxJS } from './services/CompanyServiceRxJS.jsx';
import uploadActivityIndicator from '../../assets/img/loading.gif';

export class CompanyRegulatoryDocuments extends Component {
    constructor() {
        super();

        this.state = {
            supportedRegulatoryDocuments: [],
            regDocIdToRegulatoryDocument: {},
            regulatoryDocumentComplianceResponse: [],
            outstandingSupportedRegulatoryDocuments: [],
            currentRegulatoryDocuments: [],
            isDocumentUploading: false,
            currentProcessingUploadSupportedDocumentId: null,
            userRole: '',
            roleChecked: false  // Add flag to ensure role check has completed
        };
    }

    regulatoryDocumentComplianceSubscription = null;
    deleteCompanyRegulatoryDocumentSubscription = null;
    uploadCompanyRegulatoryDocumentSubscription = null;

    componentDidMount = () => {
        const currentUserDetails = this.currentUserDetails();
        const companyId = currentUserDetails?.companyId;
        const userId = currentUserDetails?.id;
        const token = currentUserDetails?.token;
        const userRole = currentUserDetails?.role || '';

        // Store the user role in state for use in conditional rendering
        this.setState({
            userRole,
            roleChecked: true  // Mark that we've checked the role
        });

        console.log("Current user role:", userRole);
        console.log("Is host role:", this.isExactlyHostRole(userRole));

        // Only fetch documents if user is EXACTLY host role
        if (this.isExactlyHostRole(userRole)) {
            //get a list of required supported company regulatory documents
            companyServiceRxJS.getRegulatoryDocumentCompliance(companyId, userId, token);

            this.regulatoryDocumentComplianceSubscription = companyServiceRxJS.regulatoryDocumentComplianceSubject.subscribe(e => {
                const keys = Object.keys(e);

                if (keys.length > 0) {
                    const status = e?.status;

                    if (status === 200) {
                        const data = e?.data;

                        if (data) {
                            this.setState({
                                regulatoryDocumentComplianceResponse: data
                            });

                            data.map(x => {
                                const outstandingSupportedRegulatoryDocuments = x?.outstandingSupportedRegulatoryDocuments;
                                const currentRegulatoryDocuments = x?.currentRegulatoryDocuments;

                                this.setState({
                                    outstandingSupportedRegulatoryDocuments: outstandingSupportedRegulatoryDocuments,
                                    currentRegulatoryDocuments: currentRegulatoryDocuments
                                });

                                return null;
                            });
                        }
                    }
                }
            });

            this.deleteCompanyRegulatoryDocumentSubscription = companyServiceRxJS.deleteCompanyRegulatoryDocumentSubject.subscribe(res => {
                const keys = Object.keys(res);

                if (keys.length > 0) {
                    const status = res?.status;

                    if (status === 200) {
                        companyServiceRxJS.getRegulatoryDocumentCompliance(companyId, userId, token);
                    }
                }
            });

            this.uploadCompanyRegulatoryDocumentSubscription = companyServiceRxJS.uploadCompanyRegulatoryDocumentSubject.subscribe(res => {
                const keys = Object.keys(res);

                if (keys.length > 0) {
                    const status = res?.status;

                    if (status === 200) {
                        companyServiceRxJS.getRegulatoryDocumentCompliance(companyId, userId, token);
                    }

                    //set isDocumentUploading to false when response is received.
                    this.setIsDocumentUploadingToFalse();
                }
            });
        }
    };

    componentWillUnmount = () => {
        if (this.regulatoryDocumentComplianceSubscription !== undefined && this.regulatoryDocumentComplianceSubscription !== null) {
            this.regulatoryDocumentComplianceSubscription.complete();
        }

        if (this.deleteCompanyRegulatoryDocumentSubscription !== undefined && this.deleteCompanyRegulatoryDocumentSubscription !== null) {
            this.deleteCompanyRegulatoryDocumentSubscription.complete();
        }

        if (this.uploadCompanyRegulatoryDocumentSubscription !== undefined && this.uploadCompanyRegulatoryDocumentSubscription !== null) {
            this.uploadCompanyRegulatoryDocumentSubscription.complete();
        }

        // set isDocumentUploading state to false
        this.setIsDocumentUploadingToFalse();
    };

    currentUserDetails = () => {
        let userDetails = {};
        const jsonStr = localStorage.getItem("currentUser");

        if (jsonStr !== undefined && jsonStr !== null) {
            try {
                userDetails = JSON.parse(jsonStr);
            } catch (e) {
                console.error("Error parsing user details:", e);
            }
        }

        return userDetails;
    };

    // Check for EXACTLY ROLE_HOST - not even administrators
    isExactlyHostRole = (role) => {
        // Only ROLE_HOST can access - administrators are excluded
        return role === 'ROLE_HOST';
    };

    removeCompanyRegulatoryDocument = (id) => {
        if (id !== undefined) {
            const currentUserDetails = this.currentUserDetails();
            const userId = currentUserDetails?.id;
            const token = currentUserDetails?.token;

            companyServiceRxJS.deleteCompanyRegulatoryDocument(id, userId, token);
        }
    };

    handleFileUpload = (e, supportRegDocId, documentName) => {
        const selectFiles = e.target.files;

        const currentUserDetails = this.currentUserDetails();
        const userId = currentUserDetails?.id;
        const token = currentUserDetails?.token;
        const companyId = currentUserDetails?.companyId;

        const formData = new FormData();
        formData.append('companyId', companyId);
        formData.append('supportedRegulatoryDocumentId', supportRegDocId);
        formData.append('userId', userId);
        formData.append('files', selectFiles[0], selectFiles[0].name);

        companyServiceRxJS.uploadCompanyRegulatoryDocument(userId, supportRegDocId, formData, token);

        //set isDocumentUploading to true
        this.setIsDocumentUploadingToTrue();

        //set currentProcessingUploadSupportedDocumentId
        this.setCurrentProcessingUploadSupportedDocumentId(supportRegDocId);
    };

    checkMimeTypeIsImage = (mimeType) => {
        let answer = false;
        if (mimeType !== undefined) {
            const pre = mimeType.substr(0, mimeType.indexOf("/"));
            answer = pre === 'image';
        }
        return answer;
    };

    setIsDocumentUploadingToFalse = () => {
        this.setState({
            isDocumentUploading: false
        });
    };

    setIsDocumentUploadingToTrue = () => {
        this.setState({
            isDocumentUploading: true
        });
    };

    setCurrentProcessingUploadSupportedDocumentId = (supportRegDocId) => {
        this.setState({
            currentProcessingUploadSupportedDocumentId: supportRegDocId
        });
    };

    isCurrentSupportedDocumentUploading = (currentSupportRegDocId) => {
        let answer = false;

        const { currentProcessingUploadSupportedDocumentId } = this.state;

        if (currentSupportRegDocId && currentProcessingUploadSupportedDocumentId !== null) {
            answer = (currentSupportRegDocId === currentProcessingUploadSupportedDocumentId);
        }

        return answer;
    };

    render() {
        const { outstandingSupportedRegulatoryDocuments, currentRegulatoryDocuments, isDocumentUploading, userRole, roleChecked } = this.state;

        // Ensure we've checked the role before making a decision
        if (!roleChecked) {
            return null; // Don't render anything until role check completes
        }

        // Show ONLY for exact ROLE_HOST users - NOT administrators
        if (!this.isExactlyHostRole(userRole)) {
            console.log("Not the exact host role, rendering nothing");
            return null;
        }

        // From here, we know the user is EXACTLY ROLE_HOST
        console.log("Rendering for ROLE_HOST only:", userRole);
        const isBothEmpty = (currentRegulatoryDocuments.length === 0 && outstandingSupportedRegulatoryDocuments.length === 0);

        return <div>
            {
                isBothEmpty ? <span className="text text-danger small text-center">No supported regulatory document required or set</span> : ""
            }

            {
                currentRegulatoryDocuments.map(doc => {
                    const label = doc?.regulatoryDocument?.label;
                    const url = doc?.url;
                    const docId = doc?.id;
                    const mimeType = doc?.mimeType;
                    const filename = doc?.filename;
                    const hasImage = this.checkMimeTypeIsImage(mimeType);

                    return <Row key={docId}>
                        <div className="form-group col">
                            <ul style={{ "listStyleType": "none" }}>
                                <li> {label} </li>
                                <li><a href={url} rel={"noreferrer"} target={"_blank"}>
                                    {hasImage ? <img src={url} alt={label} width={'128px'} height={'128px'} /> : filename}
                                </a>
                                </li>
                                <li><Link onClick={() => this.removeCompanyRegulatoryDocument(docId)} ><span className="text text-danger small text-center">Remove</span></Link></li>
                            </ul>
                        </div></Row>;
                })
            }

            <br />

            {outstandingSupportedRegulatoryDocuments.map(document => {
                const supportRegDocId = document?.id;
                const code = document?.code;
                const documentName = document.label;

                return <div key={supportRegDocId} className="form-group" >
                    {
                        isDocumentUploading && this.isCurrentSupportedDocumentUploading(supportRegDocId) ? <span><img key={supportRegDocId} src={uploadActivityIndicator} alt="Uploading document" />&nbsp;&nbsp;&nbsp;</span> : <span>&nbsp;</span>
                    }

                    <label htmlFor="">{document?.label}</label>
                    <input type="file" value={""} style={{ padding: 5, borderRadius: 5, border: '1px solid #DDDDDD', width: '100%' }}
                        className="form-control-file" onChange={(e) => this.handleFileUpload(e, supportRegDocId, documentName)} name={code} id="fileId" placeholder="" aria-describedby="fileHelpId" />
                </div>;
            })}
        </div>;
    }
}
