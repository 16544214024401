import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { WithContext as ReactTags } from 'react-tag-input';

import {
  Container,
  Tabs,
  Tab,
  Row,
  Dropdown,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import uploadButton from "../../assets/img/Web/Spacia/Group 426.png";
import imgplaceholder from "../../assets/img/Web/Spacia/imgplaceholder.png";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import { useHistory, useParams } from "react-router-dom";

import "react-bootstrap-tagsinput/dist/index.css";
import * as SERVICES from "../../services/localService";
import { Constants } from "../../utils/constants";

import InformationModal from "../../components/informationModal";
import { ErrorAlert } from "../../components/Settings/alerts/ErrorAlert";
import { FaTrashAlt, FaCloudUploadAlt } from "react-icons/fa";
import StayAmenities from "../../components/ListProperties/Form/StayAmenities";
import WorkAmenities from "../../components/ListProperties/Form/WorkAmenities";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Photo from "./Photo";
import Gallery from "react-photo-gallery";
import { arrayMoveImmutable } from "array-move";
import { v4 as uuidv4 } from "uuid";
import apiService from '../../services/apiService';
import logger from "../../utils/logger";


/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement(item => {
  return <Photo {...item} />;
});

const SortableGallery = SortableContainer(({ items }) => {
  logger.log('items in sortable gallery:', items);

  return <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />;
});

const EditProperty = () => {

  const [loadingState, setLoadingState] = useState(false);
  const [chosenImage, setChosenImage] = useState(false);
  const [tOfSpace, settOfSpace] = useState("Not Set");
  const [tOfWorkspace, settOfWorkspace] = useState("Not Set");

  let { id } = useParams();
  // const editUrl = `${Constants.BASE_URL}/booking/api/v1/listings/by/id/${id}`;


  const [propertyListing, setPropertyListing] = useState({
    "id": 3010,
    "name": "Hard Coded Value",
    "description": "Oh Charley",
    "propertyPrice": {
      "id": 3013,
      "price": 1.0,
      "billingPeriod": "HOURLY",
      "createdOn": "2021-09-06T21:18:09.542Z",
      "updatedOn": "2021-09-06T21:18:09.558Z"
    },
    "isApproved": false,
    "location": {
      "id": 3012,
      "latitude": "-1.2580611290848371",
      "longitude": "36.76854358858873",
      "street": "",
      "address": "PQR9+PG2, Nairobi, Kenya Loresho",
      "city": "Nairobi",
      "country": "ke"
    },
    "media": {
      "id": 3009,
      "title": "asdf",
      "description": "fdsa",
      "images": [
        {
          "id": 3008,
          "fileName": "IMG_0340.jpg",
          "mimeType": "image/jpeg",
          "size": 109057,
          "resourceUrl": "https://storage.googleapis.com/download/storage/v1/b/spacia-16ab5.appspot.com/o/property-media-file-1630962725973-_MG_0340.jpg?generation=1630962719459323&alt=media",
          "mediaId": 3009,
          "mediaPostId": 0,
          "createdOn": "2021-09-06T21:12:06.823Z",
          "updatedOn": "2021-09-06T21:12:06.834Z"
        }
      ],
      "videoOption": {
        "uploadOption": {
          "ordinal": 0,
          "value": "YOUTUBE",
          "label": "YouTube"
        },
        "embedVideoID": "sdds"
      },
      "listingId": 3010,
      "createdOn": "2021-09-06T21:12:06.828Z",
      "updatedOn": "2021-09-06T21:18:09.649Z"
    },
    "tags": [
      "asdf",
      "asdf",
      "sdf"
    ],
    "amenities": [
      {
        "id": 24,
        "value": "Bar",
        "label": "Bar"
      },
      {
        "id": 25,
        "value": "Lifts",
        "label": "Lifts"
      },
      {
        "id": 31,
        "value": "GarbageCollection",
        "label": "Garbage Collection"
      },
      {
        "id": 30,
        "value": "Gym",
        "label": "Gym"
      },
      {
        "id": 27,
        "value": "AmpleParking",
        "label": "Ample Parking"
      },
      {
        "id": 34,
        "value": "CCTV",
        "label": "CCTV"
      },
      {
        "id": 35,
        "value": "hotWater",
        "label": "Hot water"
      },
      {
        "id": 39,
        "value": "StandbyBackupGenerator",
        "label": "Standby Backup Generator"
      }
    ],
    "listingDetails": {
      "id": 3011,
      "propertySize": 2,
      "floorNumber": "4",
      "capacity": 3,
      "listingId": 3010
    },
    "status": {
      "ordinal": 0,
      "value": "published"
    },
    "category": "Stay",
    "subCategory": "Serviced Apartments",
    "type": null,
    "subType": null,
    "propertyStatus": {
      "ordinal": 0,
      "value": "FOR_RENT",
      "label": "FOR RENT"
    },
    "createdBy": {
      "id": 17,
      "username": "ebo.bentil+spacia@gmail.com",
      "firstName": "Ebo Test",
      "lastName": "Bentil",
      "name": "Ebo Test Bentil",
      "userRole": "ROLE_SUBSCRIBER_OWNER",
      "isEnabled": true,
      "createdOn": "2021-07-18T18:51:33Z",
      "updatedOn": "2021-09-06T21:18:09.640Z"
    },
    "createdOn": "2021-09-06T21:18:09.539Z",
    "updatedOn": "2021-09-07T01:02:53.389Z"
  });

  const [loading, setLoading] = useState(false);
  const editPropertyItemFrom = JSON.parse(localStorage.getItem("editpropertyItem"));

  useEffect(() => {
    localStorage.setItem("propertyType", editPropertyItemFrom.category.toUpperCase());

    settOfSpace(eTypeOfSpace(localStorage.getItem("propertyType")));

    settOfWorkspace(eTypeOfSpace(localStorage.getItem("Workspace")));

    redefineAmenities();
  }, []);

  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };

  const delimiters = [...KeyCodes.enter, KeyCodes.comma];

  const [state, setState] = useState({
    tags: editPropertyItemFrom.tags
  });

  const handleDelete = (i) => {
    const { tags } = state;
    setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  };

  const handleAddition = (tag) => {
    logger.log(tag);
    setState(state => ({ tags: [...state.tags, tag] }));
    logger.log(state);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const tags = [...state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setState({ tags: newTags });
  };
  const { tags, suggestions } = state;

  const userCurrency = SERVICES.getCurrency();

  const [dropdown2, setDropdown2] = useState(editPropertyItemFrom.propertyPrice.billingPeriod);
  const [mediaId, setMediaId] = useState(editPropertyItemFrom.media.id);
  const [imgTitle, setImgTitle] = useState(editPropertyItemFrom.media ? editPropertyItemFrom.media.title : "Media Title Unavailable");
  const [imgDescription, setImgDescription] = useState(editPropertyItemFrom.media ? editPropertyItemFrom.media.description : "Media Description Unavailable");

  const [formImage, setFormImage] = useState([]);

  // Amenities
  const [loungeSpace, setLoungeSpace] = useState();
  const [mothersRoom, setMothersRoom] = useState();
  const [outdoorSpace, setOutdoorSpace] = useState();
  const [whiteboard, setWhiteboard] = useState();
  const [bicycleRacks, setBicycleRacks] = useState();
  const [kitchenFacilities, setKitchenFacilities] = useState();
  const [onsiteDining, setOnsiteDining] = useState();
  const [printingServices, setPrintingServices] = useState();
  const [storage, setStorage] = useState();
  const [disableFriendly, setDisableFriendly] = useState();
  const [onSiteParking, setOnSiteParking] = useState();
  const [vcFacilities, setVcFacilities] = useState();

  // Stay Amenities
  const [cancelation, setCancelation] = useState();
  const [pool, setPool] = useState();
  const [airportShuttle, setAirportShuttle] = useState();
  const [spa, setSpa] = useState();
  const [nonSmokingRooms, setNonSmokingRooms] = useState();
  const [frontView, setFrontView] = useState();
  const [restaurant, setRestaurant] = useState();
  const [freeWifi, setFreeWifi] = useState();
  const [coffeeMaker, setCoffeeMaker] = useState();
  const [bar, setBar] = useState();
  const [lifts, setLifts] = useState();
  const [food, setFood] = useState();
  const [parking, setParking] = useState();
  const [rooms, setRooms] = useState();
  const [washingMachine, setWashingMachine] = useState();
  const [gym, setGym] = useState();
  const [garbage, setGarbage] = useState();
  const [laundry, setLaundry] = useState();
  const [housekeeping, setHousekeeping] = useState();
  const [cctv, setCctv] = useState();
  const [hotWater, setHotWater] = useState();
  const [bills, setBills] = useState();
  const [cookers, setCookers] = useState();
  const [security, setSecurity] = useState();
  const [generator, setGenerator] = useState();
  const [dstv, setDstv] = useState();
  const [fence, setFence] = useState();


  const [imageTitleError, setImageTitleError] = useState("");
  const [imageDescriptionError, setImageDescriptionError] = useState("");
  // const [state, setstate] = useState(editPropertyItemFrom.tags)
  const [linkToVideoError, setLinkToVideoError] = useState("");

  const [video, setVideo] = useState(editPropertyItemFrom.media.videoOption.uploadOption.label);
  const [uploadOption, setUploadOption] = useState("");
  const [videoId, setVideoId] = useState(editPropertyItemFrom.media ? editPropertyItemFrom.media.videoOption.embedVideoID : "propertyListing.media.videoOption.embedVideoID");
  const [zone, setZone] = useState(editPropertyItemFrom.zone ? editPropertyItemFrom.zone.name : null);
  const [listingType, setListingType] = useState((editPropertyItemFrom && editPropertyItemFrom.status && editPropertyItemFrom.status.value) ?
    editPropertyItemFrom.status.value.toUpperCase() : null);

  const [access, setAccess] = useState(false);

  // Define Form Data
  const formData = new FormData();

  const defaultCenter = {
    lat: -1.286389,
    lng: 36.817223,
  };

  const [ismediaUploaded, setIsmediaUploaded] = useState(true);

  const deletedImages = [];

  // remove uploaded image
  // const removeImage = (file) => {
  //   logger.log(file)
  //   logger.log(previewFilesArray)
  //   axios.delete(`https://sandbox.spacia.page/booking/api/v1/listings/media/delete/image/${file.id}`).
  //   then(res => {
  //     logger.log(res)
  //     logger.log("Successfully deleted Item")
  //   }).catch(res => {
  //     logger.error(res)
  //   }
  //
  //   )
  //   // https://sandbox.spacia.page/booking/api/v1/listings/media/delete/image/{imageId}
  //   setPreviewFilesArray(() => previewFilesArray.splice(file,1));
  //   // setPreviewFilesArray(previewFilesArray.filter(function(ele){
  // //     return ele !== file;
  // // }))
  //   // setPreviewFilesArray(previewFilesArray.splice(file, 1))
  //   logger.log(previewFilesArray)
  //
  //   deletedImages.push(file.id)
  //   logger.log(deletedImages)
  //
  //   setPreviewFilesArray([...previewFilesArray])
  //   // setFilesArray(filesArray)
  // }

  // For Blob
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
        logger.log(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const [dataUri, setDataUri] = useState(imgplaceholder);

  const [mediaLoadingState, setMediaLoadingState] = useState(false);

  const [filesArray, setFilesArray] = useState([]);

  const fileMapArray = [];

  const [previewFilesArray, setPreviewFilesArray] = useState([]);
  const [sortableImages, setSortableImages] = useState(previewFilesArray);
  const [imagesUpdatedBeforeDelete, setImagesUpdatedBeforeDelete] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  const removeImage = (image) => {
    logger.log('inside remove image function. sortable images array:', sortableImages);

    setSortableImages(prevState => {
      logger.log('sortable image prev state:', prevState);
      return prevState;
    });

    setImagesUpdatedBeforeDelete(true);
    setImageToDelete(image);
  };

  useEffect(() => {
    if (imagesUpdatedBeforeDelete) {
      logger.log('about to remove images. sortable images:', sortableImages);

      const idOfImageToDelete = imageToDelete.id;
      const imageClientId = imageToDelete.clientId;

      if (idOfImageToDelete) {
        apiService.deleteListingImage(idOfImageToDelete)
          .then(res => {
            logger.log('image successfully deleted. remove from preview files array');
            setPreviewFilesArray(sortableImages.filter(file => file.id !== idOfImageToDelete));
          })
          .catch(err => {
            // handle exception
            logger.error(err);
          });
      } else {
        // this is a newly added image so just remove it from the preview files array
        setPreviewFilesArray(sortableImages.filter(file => file.clientId !== imageClientId));
      }

      setImagesUpdatedBeforeDelete(false);
    }
  }, [sortableImages, imagesUpdatedBeforeDelete]);

  useEffect(() => {
    setImgDescription(imgTitle);
  }, [imgTitle]);


  // For Previously loaded images
  const parseURI = (d) => {
    const reader = new FileReader();    /* https://developer.mozilla.org/en-US/docs/Web/API/FileReader */
    reader.readAsDataURL(d);          /* https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL */
    return new Promise((res, rej) => {  /* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise */
      reader.onload = (e) => {        /* https://developer.mozilla.org/en-US/docs/Web/API/FileReader/onload */
        res(e.target.result);
      };
    });
  };

  async function getDataBlob(url) {
    const res = await fetch(url);
    const blob = await res.blob();
    const uri = await parseURI(blob);

    const inputURL = uri;

    const blobObject = blobCreationFromURL(inputURL);

    // Create Blob file from URL
    function blobCreationFromURL(inputURI) {
      let binaryVal;

      // mime extension extraction
      const inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0];

      // Extract remaining part of URL and convert it to binary value
      if (inputURI.split(',')[0].indexOf('base64') >= 0) {
        logger.log("Remaining Extracts");
        binaryVal = atob(inputURI.split(',')[1]);
      }

      // Decoding of base64 encoded string
      else
        binaryVal = unescape(inputURI.split(',')[1]);

      // Computation of new string in which hexadecimal
      // escape sequences are replaced by the character 
      // it represents

      // Store the bytes of the string to a typed array
      const blobArray = [];
      for (let index = 0; index < binaryVal.length; index++) {
        blobArray.push(binaryVal.charCodeAt(index));
      }

      return new Blob([blobArray], {
        type: inputMIME
      });
    }

    const fDataObj = new FormData();

    // Create formData object and append the object
    // file to the name 'Blob file'
    formData.append("files", blobObject);

    logger.log("fDataObj");
    logger.log(fDataObj);
  }

  const onChangePlace = (target) => {
    logger.log('on change place function called.');
    setChosenImage(true);

    logger.log('target files:', target.files);

    const maxFileSizeLimit = Constants.maxFileSizeLimit;

    const validateUploadedFile = (files) => {
      const areSomeInvalid = [...files].some(file => file.size >= Number(maxFileSizeLimit));

      if (areSomeInvalid) {
        setUploadErrorModal(true);
        setUploadErrorMsg('Selected image exceeds the maximum file size limit');
      }
    };

    validateUploadedFile(target.files);

    const allValidImages = [...target.files].filter(file => file.size < Number(maxFileSizeLimit));
    logger.log('valid images:', allValidImages);

    Promise.all(
      Array.from(allValidImages).map((file, i) => {
        return new Promise((resolve, reject) => {
          // This introduces the new Array and changes it to A URI for blob
          fileToDataUri(file)
            .then((dataUri) => {
              const fileName = file ? file.name : '';

              const updatedFile = {
                clientId: uuidv4(),
                alt: fileName,
                blob: dataUri,
                file: file,
                width: 1,
                height: 1,
                src: dataUri,
                removeImage
              };

              resolve(updatedFile);
            }).catch(reject);
        });
      })
    ).then((array) => {
      logger.log('results from converting files to data uri:', array);
      setPreviewFilesArray([...previewFilesArray, ...array]);
    });
  };


  const [image, setImage] = useState([]);
  const [uploadErrorModal, setUploadErrorModal] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState('');

  const [mediaUpload, setMediaUpload] = useState(true);

  const uploadError = () => {
    // do something
  };

  const submitMedia = async () => {
    try {
      setChosenImage(false);

      const formData = new FormData();
      formData.append("title", imgTitle);
      formData.append("description", imgDescription);
      formData.append("listingId", editPropertyItemFrom.listingDetails.listingId);
      formData.append("userId", SERVICES.getUser().id);

      for (let i = 0; i < previewFilesArray.length; i++) {
        formData.append("files", previewFilesArray[i].file);
      }

      formData.append("embedVideoId", videoId);
      formData.append("uploadFrom", video.toUpperCase());

      setMediaLoadingState(true);

      const response = await apiService.updateListingMedia(editPropertyItemFrom.media.id, formData);
      const newMediaId = response.data.data[0].id;

      setMediaId(newMediaId);
      setIsmediaUploaded(true);
      setMediaLoadingState(false);

      return { success: true, mediaId: newMediaId };
    } catch (error) {
      setMediaLoadingState(false);
      setUploadErrorModal(true);
      setUploadErrorMsg(error.response?.data?.message || "Error uploading media");
      return { success: false, error: error.response?.data?.message || "Failed to upload media" };
    }
  };

  const iCountry = (country) => {
    let countryNameInFull = null;

    if (country != null) {
      switch (country) {
        case "ke":
          countryNameInFull = "Kenya";
          break;

        case "ng":
          countryNameInFull = "Nigeria";
          break;

        case "gh":
          countryNameInFull = "Ghana";
          break;

        default:
          countryNameInFull = "";
      }
    }

    return countryNameInFull;
  };


  const stayAmenities = [];

  const transformImageToSortableSignature = (image) => {
    return {
      id: (image != null) ? image.id : 0,
      alt: (image != null) ? image.fileName : '',
      src: (image != null) ? image.resourceUrl : '',
      removeImage
    };
  };

  // set uploaded images immediately the page loads
  useEffect(() => {
    // Loop Images to preview with edit function
    const media = editPropertyItemFrom?.media;
    const images = (media != null) ? media.images : [];

    logger.log('listing images from database:', images);

    const transformedImages = images.map(image => transformImageToSortableSignature(image));

    setPreviewFilesArray([...transformedImages]);

    // for (let i = 0; i < images.length; i++) {
    //   logger.log(images[i])
    //   getDataBlob(images[i])
    //   previewFilesArray.push(images[i])
    // }
  }, []);

  const [additionalSpec, setAdditionalSpec] = useState(editPropertyItemFrom.name);
  const [description, setDescription] = useState(editPropertyItemFrom.description);
  const [detailsId, setDetailsId] = useState(editPropertyItemFrom.listingDetails.id);

  // details
  const [capacity, setCapacity] = useState(editPropertyItemFrom.listingDetails ? editPropertyItemFrom.listingDetails.capacity : "Unavailable");
  const [floorNumber, setFloorNumber] = useState(editPropertyItemFrom.listingDetails ? editPropertyItemFrom.listingDetails.floorNumber : "Unavailable");
  const [propertySize, setPropertySize] = useState(editPropertyItemFrom.listingDetails ? editPropertyItemFrom.listingDetails.propertySize : "Unavailable");
  const [coWorkingSpaceNumber, setCoWorkingSpaceNumber] = useState(editPropertyItemFrom.listingDetails ? editPropertyItemFrom.listingDetails.maxCapacity : "0");

  // location
  const [address, setAddress] = useState(editPropertyItemFrom.location.address);
  const [city, setCity] = useState(editPropertyItemFrom.location.city);
  const [region, setRegion] = useState(editPropertyItemFrom.location.city);
  const [country, setCountry] = useState(iCountry(editPropertyItemFrom.location.country));
  const [language, setLanguage] = useState("");
  const [longitude, setLongitude] = useState(editPropertyItemFrom.location.longitude);

  const [latitude, setLatitude] = useState(editPropertyItemFrom.location.latitude);
  const [streetName, setStreetName] = useState("");

  const [billingPeriod, setBillingPeriod] = useState("");
  const [createOn, setCreateOn] = useState("");
  const [price, setPrice] = useState(editPropertyItemFrom.propertyPrice.price);
  const [updatedOn, setUpdatedOn] = useState("");

  const [propertyStatus, setPropertyStatus] = useState("");
  const [propertyTitle, setPropertyTitle] = useState(editPropertyItemFrom ? editPropertyItemFrom.name : "Unavailable");
  const [propertyType, setPropertyType] = useState("");
  const [typeOfWorkspace, setTypeOfWorkspace] = useState("");
  const [digitalAddress, setDigitalAddress] = useState(editPropertyItemFrom.location.address);
  const [propertyAmenities, setPropertyAmenities] = useState(editPropertyItemFrom.amenities);

  const shapes = [];

  const [enumCountry, setEnumCountry] = useState("N/A");

  let history = useHistory();

  const eCountry = (country) => {
    if (country === "Kenya") {
      return "ke";
    } else if (country === "Nigeria") {
      return "ng";
    } else if (country === "Ghana") {
      return "gh";
    }
  };

  useEffect(() => {
    logger.log('localstorage structure here:', localStorage);
  }, []);


  const eTypeOfSpace = (space) => {
    if (space === "WORK") {
      logger.log("Work -> WORK");
      return "OFFICE_SPACE";
    } else if (space === "Concierge") {
      logger.log("Concierge -> SERVICE");
      return "SERVICE";
    } else if (space === "Stay") {
      logger.log("Stay -> RESIDENTIAL_SPACE");
      return "RESIDENTIAL_SPACE";
    } else if (space === "STAY") {
      logger.log("STAY -> RESIDENTIAL_SPACE");
      return "RESIDENTIAL_SPACE";
    }
  };

  const eAddSpec = (space) => {
    if (space === "Junior Suite") {
      return "JUNIOR_SUITE";
    }
    if (space === "Standard") {
      return "STANDARD";
    }
    if (space === "Executive Suite") {
      return "EXECUTIVE_SUITE";
    }
    if (space === "1 Bedroom") {
      return "ONE_BEDROOM";
    }
    if (space === "2 Bedroom") {
      return "TWO_BEDROOM";
    }
    if (space === "Presidential Suite") {
      return "PRESIDENTIAL_SUITE";
    }
    else {
      return space;
    }
  };

  const eWorkspace = (space) => {
    if (space === "Private Office") {
      return "PRIVATE_OFFICE";
    }
    if (space === "HotDesk") {
      return "HOT_DESK";
    }
    if (space === "Serviced Apartments") {
      return "SERVICED_APARTMENTS";
    }
    if (space === "Hotel") {
      return "HOTEL";
    }
    if (space === "Unfurnished") {
      return "UNFURNISHED";
    }
    if (space === "Meeting & Venue") {
      return "MEETING_AND_VENUE";
    }
  };

  const [amenities, setamenities] = useState([]);

  const submitForm = async () => {
    try {
      // Reset states
      setErrorAlert(false);
      setUploadErrorModal(false);
      setLoadingState(true);

      // Validate image title
      if (!imgTitle) {
        setImageTitleError("Please add an image title");
        setKey("Media");
        setLoadingState(false);
        return;
      }

      // Validate files
      if (previewFilesArray.length === 0) {
        setUploadErrorModal(true);
        setUploadErrorMsg("Please select at least one image");
        setKey("Media");
        setLoadingState(false);
        return;
      }

      // Upload media first and get ID
      const mediaResponse = await submitMedia();
      if (!mediaResponse.success) {
        setErrorAlert(true);
        setLoadingState(false);
        return;
      }

      // Now we have the media ID, proceed with form submission
      findAmenities();
      await submit();
    } catch (error) {
      setLoadingState(false);
      setErrorAlert(true);
      setRequestHasErrors(true);
      logger.error("Error during form submission:", error);
      history.push(`/properties`, { from: 'failed' });
    }
  };

  const arrayOfAmenities = [bicycleRacks, onSiteParking, onsiteDining];

  const amenities2 = [];

  const findAmenities = () => {
    logger.log(`inside 'findAmenities'. 'washingMachine' value: ${washingMachine}`);

    if (bicycleRacks) {
      stayAmenities.push("BicycleRacks");
    }

    if (vcFacilities) {
      stayAmenities.push("VCFacilities");
    }

    if (access) {
      stayAmenities.push("24HrAccess");
    }

    if (gym) {
      stayAmenities.push("Gym");

    }

    if (lifts) {
      stayAmenities.push("Lifts");
    }

    if (loungeSpace) {
      stayAmenities.push("LoungeSpace");
    }

    if (mothersRoom) {
      stayAmenities.push("MothersRoom");
    }

    if (outdoorSpace) {
      stayAmenities.push("OutdoorSpace");
    }

    if (whiteboard) {
      stayAmenities.push("Whiteboard");
    }

    if (kitchenFacilities) {
      stayAmenities.push("KitchenFacilities");
    }

    if (onsiteDining) {
      stayAmenities.push("OnsiteDining");
    }

    if (printingServices) {
      stayAmenities.push("PrintingServices");
    }

    if (storage) {
      stayAmenities.push("Storage");
    }

    if (disableFriendly) {
      stayAmenities.push("DisabledFriendly");
    }

    if (onSiteParking) {
      stayAmenities.push("OnsiteParking");
    }

    if (cancelation) {
      stayAmenities.push("CancelationFlexibility");
    }

    if (lifts) {
      stayAmenities.push("Lifts");
    }

    if (pool) {
      stayAmenities.push("SwimmingPools");
    }

    if (airportShuttle) {
      stayAmenities.push("AirportShuttle");
    }

    if (spa) {
      stayAmenities.push("Spa");
    }

    if (nonSmokingRooms) {
      stayAmenities.push("NonSmokingRooms");
    }

    if (frontView) {
      stayAmenities.push("CityView");
    }

    if (restaurant) {
      stayAmenities.push("Restaurant");
    }

    if (freeWifi) {
      stayAmenities.push("FreeWiFi");
    }

    if (rooms) {
      stayAmenities.push("MeetingRooms");
    }

    if (coffeeMaker) {
      stayAmenities.push("CoffeeMakerInRoom");
    }

    if (bar) {
      stayAmenities.push("Bar");
    }

    if (garbage) {
      stayAmenities.push("GarbageCollection");
    }

    if (laundry) {
      stayAmenities.push("LaundryServices");
    }

    if (housekeeping) {
      stayAmenities.push("Housekeeping");
    }

    if (cctv) {
      stayAmenities.push("CCTV");
    }

    if (parking) {
      stayAmenities.push("AmpleParking");
    }

    if (hotWater) {
      stayAmenities.push("HotWater");
    }

    if (bills) {
      stayAmenities.push("WaterAndElectricBills");
    }

    if (cookers) {
      stayAmenities.push("Cookers");
    }

    if (security) {
      stayAmenities.push("24HourSecurity");
    }

    if (generator) {
      stayAmenities.push("StandbyBackupGenerator");
    }

    if (dstv) {
      stayAmenities.push("TV");
    }

    if (fence) {
      stayAmenities.push("ElectricFence");
    }

    if (food) {
      stayAmenities.push("BreakfastLunchDinner");
    }

    if (washingMachine) {
      logger.log('washing machine exists.... about to push value onto \'stayAmenities\'');

      stayAmenities.push("WashingMachine");
    }

    logger.log('about to set \'lsstayamenities\' in local storage. stayAmenities value is:', stayAmenities);

    localStorage.setItem("lsstayamenities", JSON.stringify(stayAmenities));
    (
      localStorage.getItem("propertyType") === 'WORK'
        ?
        setAmenitiesArray(amenities2)
        :
        setAmenitiesArray(stayAmenities)
    );

    return stayAmenities;
  };

  const definedAmenities = [];

  const checkAmenity = (value) => {
    logger.log("STARTING CHECK FIX");
    checkFix(value, 'Bar', setBar);
    checkFix(value, 'CancelationFlexibility', setCancelation);
    checkFix(value, 'SwimmingPools', setPool);
    checkFix(value, 'AirportShuttle', setAirportShuttle);
    checkFix(value, 'Spa', setSpa);
    checkFix(value, 'NonSmokingRooms', setNonSmokingRooms);
    checkFix(value, 'CityView', setFrontView);
    checkFix(value, 'Restaurant', setRestaurant);
    checkFix(value, 'FreeWiFi', setFreeWifi);
    checkFix(value, 'CoffeeMakerInRoom', setCoffeeMaker);
    checkFix(value, 'Lifts', setLifts);
    checkFix(value, 'BreakfastLunchDinner', setFood);
    checkFix(value, 'AmpleParking', setParking);
    checkFix(value, 'MeetingRooms', setRooms);
    checkFix(value, 'WashingMachine', setWashingMachine);
    checkFix(value, 'Gym', setGym);
    checkFix(value, 'GarbageCollection', setGarbage);
    checkFix(value, 'LaundryServices', setLaundry);
    checkFix(value, 'Housekeeping', setHousekeeping);
    checkFix(value, 'CCTV', setCctv);
    checkFix(value, 'HotWater', setHotWater);
    checkFix(value, 'WaterAndElectricBills', setBills);
    checkFix(value, 'Cookers', setCookers);
    checkFix(value, '24HourSecurity', setSecurity);
    checkFix(value, 'StandbyBackupGenerator', setGenerator);
    checkFix(value, 'TV', setDstv);
    checkFix(value, 'ElectricFence', setFence);
    // -------- workAmenities
    checkFix(value, 'LoungeSpace', setLoungeSpace);
    checkFix(value, '24HrAccess', setAccess);
    checkFix(value, 'OnsiteParking', setOnSiteParking);
    checkFix(value, 'Storage', setStorage);
    checkFix(value, 'BicycleRacks', setBicycleRacks);
    checkFix(value, 'OutdoorSpace', setOutdoorSpace);
    checkFix(value, 'VCFacilities', setVcFacilities);
    checkFix(value, 'MothersRoom', setMothersRoom);
    checkFix(value, 'PrintingServices', setPrintingServices);
    checkFix(value, 'DisabledFriendly', setDisableFriendly);
    checkFix(value, 'OnsiteDining', setOnsiteDining);
    checkFix(value, 'Whiteboard', setWhiteboard);
    checkFix(value, 'KitchenFacilities', setKitchenFacilities);
  };

  const checkFix = (check, fix, stateVariable) => {
    // 2 parameters 
    // check - "Value from Backend"
    // fix - state variable to be changed

    if (check === fix) {
      logger.log(`'check' value: ${check}`);
      logger.log(`'fix' value: ${fix}`);

      stateVariable(true);
    }
  };

  const redefineAmenities = () => {
    // Loops through the amenities array
    for (let i = 0; i < propertyAmenities.length; i++) {
      logger.log(propertyAmenities[i].value);

      definedAmenities.push(propertyAmenities[i].value);

      logger.log(`Amenity ${(i + 1)}: ${propertyAmenities[i].value}`);
      checkAmenity(propertyAmenities[i].value);
    }

    return "stayAmenities";
  };

  const [amenitiesArray, setAmenitiesArray] = useState([]);



  const submit = () => {
    const currentUser = SERVICES.getUser();
    const stayAmenitiesFromLocalStorage = localStorage.getItem("lsstayamenities");
    let amenities = [];

    if (stayAmenitiesFromLocalStorage != null) {
      const parsedStringFromLocalStorage = JSON.parse(stayAmenitiesFromLocalStorage);
      if (parsedStringFromLocalStorage != null && Array.isArray(parsedStringFromLocalStorage)) {
        amenities = parsedStringFromLocalStorage;
      }
    }

    const payload = {
      additionalSpec: (localStorage.getItem("addSpec")) ? eAddSpec(localStorage.getItem("addSpec")).toUpperCase() : "UNFURNISHED",
      amenities: amenities,
      description: description,
      details: {
        capacity: capacity,
        floorNumber: floorNumber,
        id: editPropertyItemFrom.listingDetails.id,
        propertySize: propertySize,
        maxCapacity: coWorkingSpaceNumber
      },
      id: editPropertyItemFrom.id,
      location: {
        address: address,
        city: city,
        country: eCountry(country),
        id: editPropertyItemFrom.location.id,
        latitude: latitude,
        longitude: longitude,
        streetName: streetName
      },
      mediaId: mediaId,
      propertyPrice: {
        billingPeriod: dropdown2,
        id: editPropertyItemFrom.propertyPrice.id,
        price: price
      },
      propertyStatus: "FOR_RENT",
      listingType: (listingType) ? listingType.toLowerCase() : null,
      propertyTitle: propertyTitle,
      propertyType: eTypeOfSpace(localStorage.getItem("propertyType")),
      tags: state.tags,
      typeOfWorkspace: eWorkspace(localStorage.getItem("Workspace")),
      userId: currentUser.id,
      zone: zone
    };

    apiService.updateListing(id, payload)
      .then(res => {
        logger.log('done adding new property. res.data:', res.data.data);
        // Rest of the success handling code...
        SERVICES.setnewProperty(true);
        history.push(`/properties`, { from: 'successful' });
      })
      .catch((err) => {
        logger.log("Error: " + err);
        history.push(`/properties`, { from: 'failed' });
        history.push(`/properties`);
      });
  };

  let marker = null;
  const placeMarker = (e, maps, map, Geocoder) => {
    marker.setMap(null);

    const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    Geocoder.geocode({ location: latLng })
      .then((response) => {
        if (response.results[0]) {
          const result = response.results[0];
          setCountry(
            result.address_components.find(
              (o) => o.types.indexOf("country") > -1
            )?.long_name
          );
          setCity(
            result.address_components.find(
              (o) => o.types.indexOf("locality") > -1
            )?.long_name || ""
          );
          setRegion(
            result.address_components.find(
              (o) => o.types.indexOf("administrative_area_level_1") > -1
            )?.long_name
          );
          setAddress(
            `${e.placeName ? e.placeName + ', ' : ''}${result.formatted_address} ${result.address_components.find(
              (o) => o.types.indexOf("sublocality") > -1
            )?.long_name || ""
            }`
          );
        }
      })
      .catch(console.error);

    setLatitude(latLng.lat);
    setLongitude(latLng.lng);

    marker = new maps.Marker({
      position: latLng,
      map,
      title: "Location",
    });
  };


  const drawZones = (map, maps, Geocoder) => {

    const promises = [];
    for (let i = 1; i <= 12; ++i) {
      promises.push(
        new Promise((resolve, reject) => {

          fetch(`/zones/Spacia Zone ${i}.txt`).then(function (response) {
            let reader = response.body.getReader();
            let decoder = new TextDecoder("utf-8");

            reader.read().then(function (result) {
              let fillColor = "#F0ADA5";
              let strokeOpacity = 0.8;
              let zoneName = [];



              const latLngs = decoder
                .decode(result.value)
                .split("\n")
                .map((line, i) => {
                  if (i > 0) {
                    const parts = line.split("\t");
                    if (i === 1) {
                      fillColor = parts[3] || fillColor;
                      strokeOpacity = parts[4] || strokeOpacity;
                      zoneName = (parts[6] || zoneName).toUpperCase().replace(/\s+/g, '_');
                    }

                    if (parts[1] && parts[2]) {
                      return new maps.LatLng(
                        Number(parts[1]),
                        Number(parts[2])
                      );
                    }
                  }
                  return null;
                });


              resolve({
                zoneName,
                polygon: new maps.Polygon({
                  paths: latLngs.filter((o) => !!o),
                  strokeColor: "#B4B2C0",
                  strokeOpacity,
                  strokeWeight: 2,
                  fillColor,
                  fillOpacity: 0.35,
                }),
              });
            });
          }, reject);
        })
      );
    }

    Promise.all(promises).then((shapes) => {

      shapes.forEach(({ zoneName, polygon }, i) => {
        maps.event.addListener(polygon, "click", function (e) {
          marker.setMap(null);

          setZone(zoneName);

          placeMarker(e, maps, map, Geocoder);
        });

        polygon.setMap(map);
      });
    });
  };

  const handleApiLoaded = (map, maps) => {

    // use map and maps objects

    // options={{zoomControl: true,}}

    const Geocoder = new maps.Geocoder();

    maps.Polygon.prototype.Contains = function (point) {

      let crossings = 0,
        path = this.getPath();


      if (!path) {
        return false;
      }

      // for each edge
      for (let i = 0; i < path.getLength(); i++) {
        let a = path.getAt(i),
          j = i + 1;
        if (j >= path.getLength()) {
          j = 0;
        }

        const b = path.getAt(j);
        if (rayCrossesSegment(point, a, b)) {
          crossings++;
        }
      }

      // odd number of crossings?
      return crossings % 2 == 1;

      function rayCrossesSegment(point, a, b) {
        let px = point.lng(),
          py = point.lat(),
          ax = a.lng(),
          ay = a.lat(),
          bx = b.lng(),
          by = b.lat();
        if (ay > by) {
          ax = b.lng();
          ay = b.lat();
          bx = a.lng();
          by = a.lat();
        }
        // alter longitude to cater for 180 degree crossings
        if (px < 0) {
          px += 360;
        }
        if (ax < 0) {
          ax += 360;
        }
        if (bx < 0) {
          bx += 360;
        }

        if (py == ay || py == by) py += 0.00000001;
        if (py > by || py < ay || px > Math.max(ax, bx)) return false;
        if (px < Math.min(ax, bx)) return true;

        var red = ax != bx ? (by - ay) / (bx - ax) : Infinity;
        var blue = ax != px ? (py - ay) / (px - ax) : Infinity;
        return blue >= red;
      }
    };


    marker = new maps.Marker({
      position: defaultCenter,
      map,
      title: "Location",
    });

    map.addListener("click", (e) => {
      setZone([]);
      placeMarker(e, maps, map, Geocoder);

    });

    drawZones(map, maps, Geocoder);
    // onChildClick={(key, childProps) => onChildClick(key, childProps)}


    const input = document.getElementById("pac-input");

    const autocomplete = new maps.places.Autocomplete(input, {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: ["establishment"],
    });


    autocomplete.addListener("place_changed", (e) => {

      const place = autocomplete.getPlace();

      if (place?.geometry?.location) {

        placeMarker(
          { placeName: place.name, latLng: place.geometry.location },
          maps,
          map,
          Geocoder
        );
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }

        let found = false;
        shapes.some(({ zoneName, polygon }, i, arr) => {

          if (polygon.Contains(place.geometry.location)) {
            setZone(zoneName);
            found = true;
            return;
          } else if (i === arr.length - 1 && !found) {
            setZone([]);
          }
        });
      }


    });


    const promises = [];
    for (let i = 1; i <= 12; ++i) {

      promises.push(new Promise((resolve, reject) => {
        fetch(`zones/Spacia Zone ${i}.txt`).then(function (response) {
          logger.log(response);
          let reader = response.body.getReader();
          let decoder = new TextDecoder("utf-8");
          logger.log("READ ZONES SUCCESSfuLLY❤️");
          reader.read().then(function (result) {
            let fillColor = "#F0ADA5";
            let strokeOpacity = 0.8;
            // let zoneName = [];
            let zoneName = '';

            const latLngs = decoder
              .decode(result.value)
              .split("\n")
              .map((line, i) => {
                if (i > 0) {

                  const parts = line.split("\t");
                  if (i === 1) {
                    fillColor = parts[3] || fillColor;
                    strokeOpacity = parts[4] || strokeOpacity;
                    zoneName = (parts[6] || zoneName).toUpperCase().replace(/\s+/g, '_');

                  }

                  if (parts[1] && parts[2]) {

                    return new maps.LatLng(Number(parts[1]), Number(parts[2]));
                  }

                }
                return null;
              });


            const shape = {
              zoneName,
              polygon: new maps.Polygon({
                paths: latLngs.filter((o) => !!o),
                strokeColor: "#B4B2C0",
                strokeOpacity,
                strokeWeight: 2,
                fillColor,
                fillOpacity: 0.35,
              }),
            };
            shapes.push(shape);

            resolve(shape);
          });
        });

      }));
    }

    Promise.all(promises).then(() => {
      shapes.forEach((polygon, i) => {
        maps.event.addListener(polygon, "click", function (e) {
          // marker.setMap(null);

          if ('setMap' in marker) {
            marker.setMap(null);
          }

          setZone(`ZONE_${i + 1}`);

          placeMarker(e);
        });

        // polygon.setMap(map);

        if ('setMap' in polygon) {
          polygon.setMap(map);
        }
      });
    });
  };

  const changeImage = (e) => {
    logger.log(e.target.src);

    setImage(e.target.src);
    setFormImage(e.target);
  };

  const fit = {
    display: "flex",
    padding: "20px 0",
  };

  const label = {
    fontWeight: "300",
    marginBottom: 10,
  };

  const imgholder = {
    width: 100,
    height: 100,
    objectFit: "cover",
    marginRight: 10,
    postion: 'absolute'
  };

  const [key, setKey] = useState("Description");

  logger.log(document.getElementById('controlled-tab-example-tab-2.Media'));

  useEffect(() => {
    logger.log('updated preview files array:', previewFilesArray);
    setSortableImages(previewFilesArray);
  }, [previewFilesArray]);

  useEffect(() => {
    logger.log('sortable images updated', sortableImages);
  }, [sortableImages]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    logger.log('on sort end called.');

    logger.log('inside on sort end. preview files array:', previewFilesArray);
    setPreviewFilesArray(arrayMoveImmutable(sortableImages, oldIndex, newIndex));
  };

  const [errorAlert, setErrorAlert] = useState(false);
  const [requestHasErrors, setRequestHasErrors] = useState(false);

  return loading ? (
    <h4>Loading</h4>
  ) : (
    <div>
      <ErrorAlert
        message={
          "An error occurred while editing listing. Please try again!"
        }
        show={errorAlert}
        close={() => setErrorAlert(false)}
      />
      <div style={{ display: "flex" }}>
        <div className="headerListPropBtn">
          <div className="strip header">
            <h4>
              <b>Edit Property {id}</b>
            </h4>
          </div>
        </div>
        {/* <div className='headerListPropBtn'>
              <button>List a Properties</button>
            </div> */}
      </div>

      <Container>
        <Tabs
          fill
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            // chosenImage ? submitMedia() : logger.log("Image is not chosen");
            setKey(k);
            // k == '3.Location' ? submitMedia() : logger.log("Not Yet!!")
          }}
        >
          <Tab
            eventKey="Description"
            title="Description"
            className="tagMargin"
          >
            <br />
            <Container>
              <h5 style={{ marginBottom: "20px" }}>
                <b>Property Description</b>
              </h5>

              <div className="form-group">
                <label style={label} htmlFor="">
                  Property Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  name=""
                  id=""
                  aria-describedby="helpId"
                  value={propertyTitle}
                  onChange={(e) => setPropertyTitle(e.target.value)}
                  placeholder=""
                />
              </div>
              <div className="form-group">
                <label style={label} htmlFor="">
                  Property Descriptions
                </label>
                <textarea
                  style={{ height: "180px" }}
                  className="form-control"
                  name=""
                  id=""
                  aria-describedby="helpId"
                  placeholder=""
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              {/* <div className="form-group"> */}
              <label style={label} htmlFor="">
                Add Tags
              </label>
              <br />
              <div style={{ background: "white", flexWrap: "wrap" }}>
                <div
                  style={{
                    margin: 0,
                    background: "#DDDDDD",
                  }}
                >

                  {/* <InputTags
                  style={{
                    backgroundColor: "white",
                    fontSize: 16,

                  }}
                  values={state}
                  onTags={(value) => setstate(value.values)}
                /> */}

                  <ReactTags
                    tags={tags}
                    placeholder={tags ? "" : "Enter here"}
                    suggestions={suggestions}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    delimiters={delimiters}
                  />
                </div>
              </div>
              <small className="text-muted">
                Press Enter after every entry
              </small>
              {/* </div> */}
            </Container>
            <Container>
              <br />

              <h5>
                <b>Property Price</b>
              </h5>

              <div className="form-group">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <label style={label} htmlFor="">
                      Price in {userCurrency}(only numbers)
                    </label>
                    <input
                      type="number"
                      style={{ width: "80%" }}
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      value={price}
                      onChange={(e) => {
                        e.target.value > 0
                          ? setPrice(e.target.value)
                          : setPrice(0);
                      }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <label style={label} htmlFor="">
                      Billing Period
                    </label>
                    <div
                      className="selectCard"
                      style={{
                        width: "100%",
                        padding: "5px",
                      }}
                    >
                      {/* billingperiod = {this dropdown} */}
                      <h6 className="flatText" style={{ fontSize: '0.9rem' }}>{dropdown2}</h6>
                      <Dropdown>
                        <Dropdown.Toggle
                          style={{
                            backgroundColor: "transparent",
                            color: "black",
                            border: "none",
                          }}
                          id="dropdown-basic"
                        ></Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => setDropdown2("HOURLY")}
                            href="#/action-2"
                          >
                            Hourly
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setDropdown2("DAILY")}
                            href="#/action-3"
                          >
                            Daily
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setDropdown2("WEEKLY")}
                            href="#/action-3"
                          >
                            Weekly
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setDropdown2("MONTHLY")}
                            href="#/action-3"
                          >
                            Monthly
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
            <Container>
              <br />
              <h5>
                <b>Details</b>
              </h5>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "50%" }}>
                  <label style={label} htmlFor="">
                    Size in ft2(only numbers)
                  </label>
                  <input
                    type="number"
                    style={{ width: "80%" }}
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                    value={propertySize}
                    onChange={(e) => {
                      e.target.value > 0
                        ? setPropertySize(e.target.value)
                        : setPropertySize(0);
                    }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <label style={label} htmlFor="">
                    Floor Number
                  </label>
                  <input
                    type="number"
                    style={{ width: "80%" }}
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                    value={floorNumber}
                    onChange={(e) => {
                      e.target.value > 0
                        ? setFloorNumber(e.target.value)
                        : setFloorNumber(0);
                    }}
                  />
                </div>
              </div>
              <div className={'pt-4'} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: "50%" }}>
                  <label style={label} htmlFor="">
                    Capacity(number of people)
                  </label>
                  <input
                    type="number"
                    style={{ width: "80%" }}
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                    value={capacity}
                    onChange={(e) => {
                      e.target.value > 0
                        ? setCapacity(e.target.value)
                        : setCapacity(0);
                    }}
                  />
                </div>
                <div style={{ width: "50%" }} className="form-group d-flex">
                  <div className={'me-2'} style={{ alignSelf: "flex-end" }}>
                    <input type="checkbox" />
                  </div>
                  <div className={'w-100'}>
                    <label style={label} htmlFor="">
                      Number of co-working space
                    </label>
                    <input
                      type="number"
                      style={{ width: "80%" }}
                      className="form-control"
                      name=""
                      id=""
                      min={1}
                      aria-describedby="helpId"
                      placeholder=""
                      value={coWorkingSpaceNumber}
                      onChange={(e) => {
                        setCoWorkingSpaceNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/*<div style={{width: "50%"}}>*/}
                {/*    <br/>*/}
                {/*    <label style={label} htmlFor="">*/}
                {/*        Type*/}
                {/*    </label>*/}
                {/*    <div*/}
                {/*        className="selectCard"*/}
                {/*        style={{*/}
                {/*            width: "80%",*/}
                {/*            padding: "5px",*/}
                {/*            marginTop: 0*/}
                {/*        }}*/}
                {/*    >*/}
                {/* billingperiod = {this dropdown} */} {/*todo: This part was already commented out*/}
                {/*<h6 className="flatText" style={{fontSize: '0.9rem'}}>{listingType}</h6>*/}
                {/*<Dropdown>*/}
                {/*    <Dropdown.Toggle*/}
                {/*        style={{*/}
                {/*            backgroundColor: "transparent",*/}
                {/*            color: "black",*/}
                {/*            border: "none",*/}
                {/*        }}*/}
                {/*        id="dropdown-basic"*/}
                {/*    ></Dropdown.Toggle>*/}
                {/**/}
                {/*    <Dropdown.Menu>*/}
                {/*        <Dropdown.Item*/}
                {/*            onClick={() => setListingType("PRIVATE")}*/}
                {/*        >*/}
                {/*            Private*/}
                {/*        </Dropdown.Item>*/}
                {/*        <Dropdown.Item*/}
                {/*            onClick={() => setListingType("PUBLISHED")}*/}
                {/*        >*/}
                {/*            Published*/}
                {/*        </Dropdown.Item>*/}
                {/*    </Dropdown.Menu>*/}
                {/*</Dropdown>*/}
                {/*</div>*/}
                {/*</div>*/}






              </div>
            </Container>
            <Container>
              {/* <br />
							<h5>
								<b>Select Property Status</b>
							</h5>
							<div style={{ width: "50%" }}>
								<label style={label} htmlFor="">
									Property status
								</label>
								<div
									className="selectCard"
									style={{ width: "100%", padding: "5px" }}
								>

									<h6 className="flatText">{dropdown1}</h6>
									<Dropdown>
										<Dropdown.Toggle
											style={{
												backgroundColor: "transparent",
												color: "black",
												border: "none",
											}}
											id="dropdown-basic"
										></Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() =>
													setdropdown1("Published")
												}
												href="#/action-2"
											>
												Published
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() =>
													setdropdown1(
														"Not Published",
													)
												}
												href="#/action-3"
											>
												Not Published
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div> */}

              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* <button className="button-calm">Back</button> */}
                <button
                  style={{ marginLeft: 20 }}
                  onClick={() => setKey("Media")}
                  className="button"
                >
                  Next Step
                </button>
                <button
                  style={{
                    marginLeft: 20,
                    border: "1px solid red",
                    backgroundColor: "white",
                    color: "red",
                  }}
                  onClick={() => history.push("/properties")}
                  className="button"
                >
                  Cancel
                </button>
              </div>
            </Container>
            {/* <button className="ListPropBtn">Next Step</button> */}
          </Tab>
          <Tab eventKey="Media" title="Media" marginLeft="20px">
            {/* Upload Box */}

            <Container>
              <br />
              <ErrorAlert
                show={uploadErrorModal}
                close={() => setUploadErrorModal(false)}
                message={uploadErrorMsg || "There was a problem uploading your images, please check and try again."}
              />
              <h6>Upload Pictures of your property (You can upload not more than 1MB for a single image)</h6>
              <div className="uploadBox">
                <div className="upload-content text-center">
                  <FaCloudUploadAlt size={48} className="text-primary mb-3" />
                  <p className="mb-3">Drag and drop images here or click to browse</p>
                  <input
                    type="file"
                    id="editPropertyFileInput"
                    multiple="multiple"
                    accept='image/*'
                    onChange={(event) => onChangePlace(event.currentTarget || null)}
                    className="d-none"
                  />
                  <label htmlFor="editPropertyFileInput" className="uploadButton">
                    Choose Images
                  </label>
                  <p className="text-muted mt-3">
                    {previewFilesArray.length === 0
                      ? "No files chosen"
                      : `${previewFilesArray.length} file(s) selected`}
                  </p>
                  <small className="text-muted d-block">
                    Supports: JPG, PNG, GIF (Max: 1MB)
                  </small>
                </div>
              </div>

              {/* placeholders */}
              <SortableGallery items={sortableImages} onSortEnd={onSortEnd} axis={"xy"} />

              {/*<div style={fit} className="fitImage">*/}
              {/*  {previewFilesArray.map((file, i) => (*/}
              {/*    // <Task key={task.id} task={task} onDelete={onDelete} onToggle={onToggle}/>*/}
              {/*    <div key={i}>*/}
              {/*      <FaTrashAlt*/}
              {/*        onClick={() => removeImage(file, i)}*/}
              {/*        style={{ position: "absolute", color: "grey" }}*/}
              {/*      />*/}
              {/*      <img*/}
              {/*        onClick={changeImage}*/}
              {/*        src={file.blob ? file.blob : file.resourceUrl}*/}
              {/*        style={imgholder}*/}
              {/*        alt={file.alt}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  ))}*/}
              {/*</div>*/}


              {/* <h6>Upload Pictures of your property</h6>
            <div className="uploadBox">
              <div>
              <img src={uploadButton} alt="upload" />
              </div>
            <button className="uploadButton">Select Media</button>

            <input type="file" accept="image/*" id="primaryButton" onclick="ExistingLogic()" />
      <input type="button"
       id="secondaryButton"
       value="Esss"
       onClick="document.getElementById('primaryButton').click()" />
            </div> */}

              {/* placeholders */}

              {/* <div style={fit} className='fitImage'>
                <div>
                    <img onClick={changeImage} src={imgplaceholder} style={imgholderActive} alt="placeholder" />
                </div>
                <div>
                    <img onClick={changeImage} src={imgplaceholder} style={imgholder} alt="placeholder" />
                </div>
                <div>
                    <img onClick={changeImage} src={imgplaceholder} style={imgholder} alt="placeholder" />
                </div>
            </div> */}

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={label} htmlFor="">
                      Image Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      value={imgTitle}
                      onChange={(e) => {
                        setImgTitle(e.target.value);
                        setChosenImage(true);
                        setIsmediaUploaded(false);
                        logger.log("ready to submit");
                      }}
                    />
                    <small style={{ color: "red" }}>{imageTitleError}</small>
                  </div>

                  {/*<div className="form-group">*/}
                  {/*  <label style={label} htmlFor="">*/}
                  {/*    Image Description*/}
                  {/*  </label>*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    className="form-control"*/}
                  {/*    name=""*/}
                  {/*    id=""*/}
                  {/*    aria-describedby="helpId"*/}
                  {/*    placeholder=""*/}
                  {/*    value={imgDescription}*/}
                  {/*    onChange={(e) => {*/}
                  {/*      setImgDescription(e.target.value);*/}
                  {/*      setChosenImage(true);*/}
                  {/*      setIsmediaUploaded(false);*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*  <small style={{ color: "red" }}>*/}
                  {/*    {imageDescriptionError}*/}
                  {/*  </small>*/}
                  {/*</div>*/}

                  <br />

                  <h5>
                    <b>Video Option</b>
                  </h5>
                  <div className="form-group">
                    <label style={label} htmlFor="">
                      Upload From
                    </label>
                    <div style={{ width: "100%" }}>
                      <div
                        className="selectCard"
                        style={{ width: "100%", padding: "5px" }}
                      >
                        {/* Property Status picks from this dropdown */}
                        <h6 className="flatText">{video}</h6>
                        <Dropdown>
                          <Dropdown.Toggle
                            style={{
                              backgroundColor: "transparent",
                              color: "black",
                              border: "none",
                            }}
                            id="dropdown-basic"
                          ></Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => setVideo("YouTube")}
                              href="#/action-2"
                            >
                              YouTube
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label style={label} htmlFor="">
                      Link to video
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      value={videoId}
                      onChange={(e) => {
                        setVideoId(e.target.value);
                      }}
                    />
                    <small style={{ color: "red" }}>{linkToVideoError}</small>
                  </div>
                </div>
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <button
                    className="button-calm"
                    onClick={() => {
                      setKey("Description");
                    }}
                  >
                    Back
                  </button>
                  <button
                    style={{ marginLeft: 20 }}
                    // onClick={submitMedia}
                    onClick={() => {
                      setKey("Location");
                    }}
                    className="button"

                  >
                    Next Step
                  </button>
                </div>

                <button
                  style={{
                    marginLeft: 20,
                    border: "1px solid red",
                    backgroundColor: "white",
                    color: "red",
                  }}
                  onClick={() => history.push("/properties")}
                  className="button"
                >
                  Cancel
                </button>
              </div>
            </Container>
          </Tab>

          <Tab
            onClick={() => logger.log("Look I am clicking the tab!!!")}
            eventKey="Location"
            title="Location"
          >
            <br />
            <div className="form-group">
              <label style={label} htmlFor="">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id="pac-input"
                aria-describedby="helpId"
                placeholder="Search by location name or address on Google maps"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <Row>
              <div className="col-md-6">
                <div className="form-group">
                  <label style={label} htmlFor="">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label style={label} htmlFor="">
                    Region/Province
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                  />
                </div>
              </div>
            </Row>

            <Row>
              <div className="col-md-6">
                <div className="form-group">
                  <label style={label} for="">
                    Zone
                  </label>
                  {/* <select className="form-control">
                    {shapes.map((shape, i) => (
                      <option key={i}>ZONE_{i}</option>
                    ))}
                  </select> */}
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="zoneId"
                    placeholder=""
                    onChange={(e) => setZone(e.target.value)}
                    value={zone}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <div style={{ width: "100%" }}>
                    <label style={label} htmlFor="">
                      Country
                    </label>
                    <div
                      className="selectCard"
                      style={{ width: "100%", padding: "5px", marginTop: 0 }}
                    >
                      {/* Property Status picks from this dropdown */}
                      <h6 className="flatText">{country}</h6>
                      <Dropdown>
                        <Dropdown.Toggle
                          style={{
                            backgroundColor: "transparent",
                            color: "black",
                            border: "none",
                          }}
                          id="dropdown-basic"
                        ></Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setCountry("Ghana")}>
                            Ghana
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setCountry("Kenya")}>
                            Kenya
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setCountry("Nigeria")}>
                            Nigeria
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </Row>

            <Row>
              <div className="col-md-6">
                <div className="form-group">
                  <label style={label} htmlFor="">
                    Latitude
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label style={label} htmlFor="">
                    Longitude
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                  />
                </div>
              </div>
            </Row>

            <div className="mapouter">
              <div className="gmap_canvas">
                <div style={{ height: "100vh", width: "100%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: Constants.firebaseConfig.apiKey,
                      libraries: ["places"],
                    }}
                    defaultCenter={defaultCenter}
                    defaultZoom={13}
                    options={(maps) => ({
                      zoomControl: true,
                      zoomControlOptions: {
                        position: maps.ControlPosition.TOP_RIGHT,
                      },
                    })}
                    onGoogleApiLoaded={({ map, maps }) =>
                      handleApiLoaded(map, maps)
                    }
                    yesIWantToUseGoogleMapApiInternals={true}
                  ></GoogleMapReact>
                </div>

                {/* <iframe
									width="100%"
									height="300"
									title="Maps"
									id="gmap_canvas"
									src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
									frameborder="0"
									scrolling="no"
									marginheight="0"
									marginwidth="0"
								></iframe> */}
                <br />
                {/* <a href="https://www.embedgooglemap.net">
									embed responsive google map
								</a> */}
              </div>
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <button
                  onClick={() => {
                    setKey("Media");
                  }}
                  className="button-calm"
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    setKey("Amenities");
                  }}
                  style={{ margin: "auto 20" }}
                  className="button"
                >
                  Next Step
                </button>
              </div>

              <button
                style={{
                  marginLeft: 20,
                  border: "1px solid red",
                  backgroundColor: "white",
                  color: "red",
                }}
                onClick={() => history.push("/properties")}
                className="button"
              >
                Cancel
              </button>
            </div>
          </Tab>
          <Tab eventKey="Amenities" title="Amenities">
            <div className="form-group">
              <br />
              <h6 style={{ marginBottom: "auto 20" }}>
                <b>Other facilities available</b>
              </h6>

              {/* Undo From Here */}

              {localStorage.getItem("propertyType") === "WORK" ? (
                <WorkAmenities
                  access={access}
                  changeAccess={(e) => {
                    setAccess(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  disableFriendly={disableFriendly}
                  changeDisableFriendly={(e) => {
                    setDisableFriendly(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  loungeSpace={loungeSpace}
                  changeLoungeSpace={(e) => {
                    setLoungeSpace(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  kitchenFacilities={kitchenFacilities}
                  changeKitchenFacilities={(e) => {
                    setKitchenFacilities(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  mothersRoom={mothersRoom}
                  changeMothersRoom={(e) => {
                    setMothersRoom(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  onsiteDining={onsiteDining}
                  changeOnSiteDining={(e) => {
                    setOnsiteDining(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  onSiteParking={onSiteParking}
                  changeOnSiteParking={(e) => {
                    setOnSiteParking(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  outdoorSpace={outdoorSpace}
                  changeOutdoorSpace={(e) => {
                    setOutdoorSpace(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  printingServices={printingServices}
                  changePrintingServices={(e) => {
                    setPrintingServices(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  vcFacilities={vcFacilities}
                  changeVcFacilities={(e) => {
                    setVcFacilities(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  whiteboard={whiteboard}
                  changeWhiteboard={(e) => {
                    setWhiteboard(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  bicycleRacks={bicycleRacks}
                  changeBicycleRacks={(e) => {
                    setBicycleRacks(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  storage={storage}
                  changeStorage={(e) => {
                    setStorage(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                />
              ) : (
                <StayAmenities
                  cancelation={cancelation}
                  changeCancelation={(e) => {
                    setCancelation(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  pool={pool}
                  changePool={(e) => {
                    setPool(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  airportShuttle={airportShuttle}
                  changeairportShuttle={(e) => {
                    setAirportShuttle(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  spa={spa}
                  changeSpa={(e) => {
                    logger.log('setting the \'spa\' value here. value is: ' + e.currentTarget.checked);
                    setSpa(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  nonSmokingRooms={nonSmokingRooms}
                  changenonSmokingRooms={(e) => {
                    setNonSmokingRooms(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  restaurant={restaurant}
                  changeRestaurant={(e) => {
                    setRestaurant(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  freeWifi={freeWifi}
                  changefreeWifi={(e) => {
                    setFreeWifi(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  coffeeMaker={coffeeMaker}
                  changecoffeeMaker={(e) => {
                    setCoffeeMaker(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  bar={bar}
                  changeBar={(e) => {
                    setBar(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  lifts={lifts}
                  changeLifts={(e) => {
                    setLifts(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  food={food}
                  changeFood={(e) => {
                    setFood(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  parking={parking}
                  changeParking={(e) => {
                    setParking(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  rooms={rooms}
                  changeROoms={(e) => {
                    setRooms(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  washingMachine={washingMachine}
                  changewashingMachine={(e) => {
                    setWashingMachine(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  gym={gym}
                  changeGym={(e) => {
                    setGym(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  garbage={garbage}
                  changeGarbage={(e) => {
                    setGarbage(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  laundry={laundry}
                  changeLaundry={(e) => {
                    setLaundry(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  housekeeping={housekeeping}
                  changeHousekeeping={(e) => {
                    setHousekeeping(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  cctv={cctv}
                  changeCctv={(e) => {
                    setCctv(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  hotWater={hotWater}
                  changehotWater={(e) => {
                    setHotWater(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  bills={bills}
                  changeBills={(e) => {
                    setBills(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  cookers={cookers}
                  changeCookers={(e) => {
                    setCookers(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  security={security}
                  changeSecurity={(e) => {
                    setSecurity(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  generator={generator}
                  changeGenerator={(e) => {
                    setGenerator(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  dstv={dstv}
                  changeDstv={(e) => {
                    setDstv(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  fence={fence}
                  changeFence={(e) => {
                    setFence(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                  frontView={frontView}
                  changefrontView={(e) => {
                    setFrontView(e.currentTarget.checked);
                    findAmenities();
                    logger.log(stayAmenities);
                    logger.log(e.currentTarget.checked);
                  }}
                />
              )}

              <br />
              <br />
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <button
                    className="button-calm"
                    onClick={() => {
                      setKey("Location");
                    }}
                  >
                    Back
                  </button>
                  {mediaLoadingState ? (
                    <button
                      style={{
                        backgroundColor: "#FE9C93",
                        marginLeft: 20,
                        minWidth: 60,
                        width: "auto",
                        whiteSpace: "nowrap"
                      }}
                      onClick={submitForm}
                      className="button"
                    >
                      <Spinner size="sm" animation="border" role="status">
                        <span className="visually-hidden"></span>
                      </Spinner>{" "}
                      Uploading Images
                    </button>
                  ) : (
                    <button
                      style={{
                        marginLeft: 20,
                        minWidth: 60,
                        width: "auto",
                        whiteSpace: "nowrap"
                      }}
                      onClick={submitForm}
                      className="button"
                    >
                      {loadingState ? (
                        <Spinner size="sm" animation="border" role="status">
                          <span className="visually-hidden"></span>
                        </Spinner>
                      ) : (
                        `Submit Property`
                      )}
                    </button>
                  )}
                </div>
                <button
                  style={{
                    marginLeft: 20,
                    border: "1px solid red",
                    backgroundColor: "white",
                    color: "red",
                  }}
                  onClick={() => history.push("/properties")}
                  className="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Tab>
        </Tabs>
      </Container>

      {/* <FeedbackModal title="Error" isOpen={uploadErrorModal} isClose={()=> setUploadErrorModal()} doneButton="Try Again" onSubmit={()=>setUploadErrorModal(false)} >
				<Spinner animation="border" />
				<br/>
				Please wait while your images are being uploaded
			</FeedbackModal> */}

      <InformationModal title></InformationModal>
    </div>
  );
};

export default EditProperty;
