import React, { useState, useEffect, useCallback } from 'react';
import { Container, Table, Row, Col, InputGroup, FormControl, Card, Form, Modal } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import InformationModal from '../../../../components/informationModal';
import FeedbackModall from '../../../../components/FeedbackModall';
import SERVICES from '../../../../services';
import apiService from '../../../../services/apiService';
import Pagination from "../../../../components/Pagination";
import { HostStyles } from "../../../../components/HostStyles";
import SuccessAlert from '../../../../components/Settings/alerts/SuccessAlert';
import logger from "../../../../utils/logger";
import moment from 'moment';

const DisabledHostRow = ({ companyName, ownerName, emails, signUpDate, companyId, companyAddress }) => {
    return (
        <tbody>
            <tr>
                <td>
                    <div className="d-flex align-items-start flex-column" style={{ height: '80px' }}>
                        <div className="mb-auto"><h6>{companyName}</h6></div>
                        <div><h6>{companyAddress ? companyAddress : 'N/A'}</h6></div>
                    </div>
                </td>
                <td>
                    <div>
                        <h6>{ownerName ? ownerName : 'N/A'}</h6>
                        <h6>{emails && Array.isArray(emails) && emails.length > 0 && emails[0]}</h6>
                    </div>
                </td>
                <td className="align-top">
                    {signUpDate && moment(signUpDate).format('DD-MMM-YYYY')}
                </td>
            </tr>
        </tbody>
    );
};

const DisabledHosts = () => {
    console.log("RENDERING DISABLED HOSTS COMPONENT");
    document.title = "Disabled Hosts";

    const [disabledHosts, setDisabledHosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [enableReason, setEnableReason] = useState('');

    // Accept Changes Modal
    const [showModal, setShowModal] = useState(false);
    const [showEnableReasonModal, setShowEnableReasonModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    // Show Feedback Modal
    const [showFeedbackModal, setshowFeedbackModal] = useState(false);

    const openAcceptModalFeedback = useCallback(() => {
        setShowModal(false);
        const companyIds = disabledHosts.filter(o => o.checked === true).map(o => o.companyId);
        const currentUser = SERVICES.getUser();
        const id = currentUser && currentUser.id;

        apiService.updateCompanyStatus(companyIds.join(','), id, true)
            .then(res => {
                if (res.status === 200) {
                    window.location.reload(false);
                }
            })
            .finally(() => {
                setShowModal(false);
            });
    });

    const closeFeedbackModal = () => setshowFeedbackModal(false);

    useEffect(() => {
        console.log("DisabledHosts component mounted");
        console.log("Calling API:", `/platform/company/v1/company/corporate/customer/status?enabled=false&hostStatus=inactive&page=${currentPage}`);

        apiService.getDisabledHosts(currentPage)
            .then(res => {
                console.log("API Response:", res);
                if (res.status === 200) {
                    console.log("Data received:", res.data);
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(10);

                    // Add checked property to each host
                    const hostsWithChecked = (res.data.data || []).map(host => ({
                        ...host,
                        checked: false
                    }));

                    setDisabledHosts(hostsWithChecked);
                    const anyHostSelected = res.data.data.some(host => host.checked);
                    setIsAnyHostSelected(anyHostSelected);
                }
            })
            .catch(error => {
                console.error("API Error:", error);
            });
    }, []);

    const enableChanges = (e) => {
        e.preventDefault();
        const companyIds = disabledHosts.filter(o => o.checked === true).map(o => o.companyId);
        const currentUser = SERVICES.getUser();
        const id = currentUser && currentUser.id;

        apiService.updateCompanyStatus(companyIds.join(','), id, true, enableReason)
            .then(res => {
                handleStuffOnModalHide();
                setshowFeedbackModal(true);
            })
            .finally(() => {
                handleStuffOnModalHide();
                setshowFeedbackModal(true);
            });
    };

    const changePage = (selectedObj) => {
        // Extract the page number from the object passed from the Pagination component
        const pageNumber = Number(selectedObj.selected) || 1;

        // Save as current page (1-based for UI)
        setCurrentPage(pageNumber);

        // Convert to zero-based for API call (API expects 0-based indexing)
        const pageIndex = pageNumber - 1;

        // Only include search param if it has a value
        const searchParam = searchValue?.trim() ? searchValue : undefined;

        apiService.getDisabledHosts(pageIndex, searchParam)
            .then(res => {
                if (res.status === 200) {
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(10);
                    setDisabledHosts(res.data.data || []);
                }
            });
    };

    const performSearch = () => {
        // Reset to first page when searching
        setCurrentPage(1);

        // Only include search param if it has a value
        const searchParam = searchValue?.trim() ? searchValue : undefined;

        apiService.getDisabledHosts(0, searchParam)
            .then(res => {
                if (res.status === 200) {
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(10);
                    setDisabledHosts(res.data.data || []);
                }
            });
    };

    function handleStuffOnModalHide() {
        setShowEnableReasonModal(false);

        const disabledHostsContainer = document.querySelector('.disabled-hosts');
        if (disabledHostsContainer != null) {
            disabledHostsContainer.classList.remove('blur-background');
        }
    }

    const displayEnableModal = () => {
        setShowEnableReasonModal(true);

        const disabledHostsContainer = document.querySelector('.disabled-hosts');
        if (disabledHostsContainer != null) {
            disabledHostsContainer.classList.add('blur-background');
        }
    };

    const [isAnyHostSelected, setIsAnyHostSelected] = useState(false);

    const updateIsAnyHostSelected = () => {
        const anyHostSelected = disabledHosts.some(host => host.checked);
        setIsAnyHostSelected(anyHostSelected);
    };

    return (
        <Container fluid className={'disabled-hosts'}>
            <SuccessAlert show={showFeedbackModal} message={"Successfully enabled hosts"} close={() => setshowFeedbackModal(false)} title="Hosts Enabled" alertStyle={{}} />

            <Row style={{ alignItems: 'center' }}>
                <Col md="5">
                    <h4><b>Disabled Hosts</b></h4>
                </Col>
                <Col md="4">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1" onClick={performSearch}><FaSearch /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <HostStyles>
                        <Card>
                            <Card.Body>
                                <div className='table-wrapper'>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th><h6>Company</h6></th>
                                                <th><h6>Company Rep.</h6></th>
                                                <th><h6>Sign Up Date</h6></th>
                                            </tr>
                                        </thead>
                                        {Array.isArray(disabledHosts) && disabledHosts.length > 0 &&
                                            disabledHosts.map((disabledHost, index) => (
                                                <DisabledHostRow
                                                    key={disabledHost.companyId}
                                                    {...disabledHost}
                                                />
                                            ))
                                        }
                                    </Table>
                                    {Array.isArray(disabledHosts) && disabledHosts.length > 0 &&
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage || 1}
                                            totalCount={totalPageSize}
                                            pageSize={itemsPerPage}
                                            onPageChange={changePage}
                                        />
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </HostStyles>
                </Col>
            </Row>

            <Modal show={showEnableReasonModal} onHide={handleStuffOnModalHide} onEnter={e => logger.log(e)} contentClassName="padding-40px"
                style={{
                    overflow: "hidden",
                    fontFamily: "Montserrat",
                    borderRadius: "30px",
                }}>

                <Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "0px",
                        paddingRight: "20px",
                        margin: "0px",
                    }}
                    closeButton
                >
                    <Modal.Title
                        style={{ width: "100%", textAlign: "center" }}
                    >
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ height: '' }}>
                    <div className="text-center position-relative" style={{ bottom: '20px' }}>
                        <p className={'text-primary mt-0 mb-2 mx-auto'} style={{ fontSize: '1.3rem', width: '60%', fontWeight: 400 }}>
                            Reason For Enabling
                        </p>
                        <form className={'mt-0'} onSubmit={enableChanges}>
                            <textarea
                                className={'form-control'}
                                placeholder={'Text Here'}
                                cols="30" rows="4"
                                onChange={e => setEnableReason(e.target.value)}
                                style={{ resize: 'none' }} />
                            <div className={'mt-4'}>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        borderRadius: "20px",
                                        marginLeft: "0px",
                                        width: "5rem"
                                    }}
                                    disabled={!enableReason}
                                    type={'submit'}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <InformationModal body="Are you sure you want to enable host?" title="Confirm Enable Host" isOpen={showModal} isClose={closeModal} onSubmit={openAcceptModalFeedback} acceptButton="Enable" declineButton="Cancel" >
                <div style={{ fontWeight: 400, fontSize: "1.2rem" }}>Are you sure you want to enable host?</div>
            </InformationModal>

            {/* Feedback Modal */}
            <FeedbackModall body="Host enabled successfully" title="Done" isOpen={showFeedbackModal} isClose={closeFeedbackModal} onSubmit={closeFeedbackModal} acceptButton="Okay" doneButton="Okay" declineButton="Cancel">
                <img src="assets/img/Web/Spacia/check.png" alt="check" />
                <div style={{ paddingTop: 20, fontWeight: 400 }}>Hosts enabled</div>
            </FeedbackModall>
        </Container>
    );
};

export default DisabledHosts; 