import React from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { FaWifi, FaSwimmingPool, FaCar, FaUtensils, FaCheckCircle } from "react-icons/fa";

const AmenitiesTab = ({ formData, updateFormData, updateAmenities, loadingState, mediaLoadingState, requestHasErrors, submitForm, moveToPropertiesPage, onBack }) => {
    // Grouped amenities for better organization
    const amenityGroups = {
        basicServices: [
            { key: "access", label: "24-Hour Access" },
            { key: "security", label: "24-Hour Security" },
            { key: "disableFriendly", label: "Disabled-Friendly" },
            { key: "freewifi", label: "Free Wi-Fi" },
            { key: "generator", label: "Backup Generator" },
            { key: "lifts", label: "Elevators/Lifts" },
            { key: "cctv", label: "CCTV" },
        ],
        facilities: [
            { key: "loungeSpace", label: "Lounge Space" },
            { key: "kitchenFacilities", label: "Kitchen Facilities" },
            { key: "onsiteDining", label: "On-site Dining" },
            { key: "outdoorspace", label: "Outdoor Space" },
            { key: "printingServices", label: "Printing Services" },
            { key: "restaurant", label: "Restaurant" },
            { key: "bar", label: "Bar" },
            { key: "rooms", label: "Meeting Rooms" },
        ],
        comfortAmenities: [
            { key: "pool", label: "Swimming Pool" },
            { key: "spa", label: "Spa" },
            { key: "gym", label: "Gym/Fitness Center" },
            { key: "nonsmokingrooms", label: "Non-Smoking Rooms" },
            { key: "frontview", label: "City View" },
            { key: "coffeemaker", label: "Coffee Maker in Room" },
            { key: "hotwater", label: "Hot Water" },
            { key: "washingmachine", label: "Washing Machine" },
        ],
        parkingAndTransport: [
            { key: "onSiteParking", label: "On-site Parking" },
            { key: "bicycleRacks", label: "Bicycle Racks" },
            { key: "airportshuttle", label: "Airport Shuttle" },
            { key: "parking", label: "Ample Parking" },
        ],
        additionalServices: [
            { key: "mothersRoom", label: "Mother's Room" },
            { key: "housekeeping", label: "Housekeeping" },
            { key: "laundry", label: "Laundry Services" },
            { key: "garbage", label: "Garbage Collection" },
            { key: "bills", label: "Water & Electric Bills Included" },
            { key: "storage", label: "Storage" },
            { key: "cancelation", label: "Flexible Cancellation" },
            { key: "food", label: "Breakfast/Lunch/Dinner" },
        ],
    };

    const isLoading = loadingState || mediaLoadingState;

    return (
        <Container>
            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaCheckCircle className="me-2" /> Property Amenities</h5>
                    <p>Select all amenities that are available at your property</p>
                </div>

                <div className="amenities-description mb-4">
                    <p>
                        Offering accurate information about your property's amenities helps potential
                        clients understand what to expect. Check all that apply.
                    </p>
                </div>

                {/* Basic Services */}
                <div className="amenity-group mb-4">
                    <h6 className="amenity-group-title">
                        <FaWifi className="me-2" /> Basic Services
                    </h6>
                    <Row>
                        {amenityGroups.basicServices.map((amenity) => (
                            <Col md={4} key={amenity.key}>
                                <div className="amenity-checkbox">
                                    <input
                                        type="checkbox"
                                        id={amenity.key}
                                        className="form-check-input"
                                        checked={formData.selectedAmenities[amenity.key] || false}
                                        onChange={(e) => updateAmenities(amenity.key, e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor={amenity.key}>
                                        {amenity.label}
                                    </label>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>

                {/* Facilities */}
                <div className="amenity-group mb-4">
                    <h6 className="amenity-group-title">
                        <FaUtensils className="me-2" /> Facilities
                    </h6>
                    <Row>
                        {amenityGroups.facilities.map((amenity) => (
                            <Col md={4} key={amenity.key}>
                                <div className="amenity-checkbox">
                                    <input
                                        type="checkbox"
                                        id={amenity.key}
                                        className="form-check-input"
                                        checked={formData.selectedAmenities[amenity.key] || false}
                                        onChange={(e) => updateAmenities(amenity.key, e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor={amenity.key}>
                                        {amenity.label}
                                    </label>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>

                {/* Comfort Amenities */}
                <div className="amenity-group mb-4">
                    <h6 className="amenity-group-title">
                        <FaSwimmingPool className="me-2" /> Comfort & Leisure
                    </h6>
                    <Row>
                        {amenityGroups.comfortAmenities.map((amenity) => (
                            <Col md={4} key={amenity.key}>
                                <div className="amenity-checkbox">
                                    <input
                                        type="checkbox"
                                        id={amenity.key}
                                        className="form-check-input"
                                        checked={formData.selectedAmenities[amenity.key] || false}
                                        onChange={(e) => updateAmenities(amenity.key, e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor={amenity.key}>
                                        {amenity.label}
                                    </label>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>

                {/* Parking & Transport */}
                <div className="amenity-group mb-4">
                    <h6 className="amenity-group-title">
                        <FaCar className="me-2" /> Parking & Transport
                    </h6>
                    <Row>
                        {amenityGroups.parkingAndTransport.map((amenity) => (
                            <Col md={4} key={amenity.key}>
                                <div className="amenity-checkbox">
                                    <input
                                        type="checkbox"
                                        id={amenity.key}
                                        className="form-check-input"
                                        checked={formData.selectedAmenities[amenity.key] || false}
                                        onChange={(e) => updateAmenities(amenity.key, e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor={amenity.key}>
                                        {amenity.label}
                                    </label>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>

                {/* Additional Services */}
                <div className="amenity-group">
                    <h6 className="amenity-group-title">
                        <FaCheckCircle className="me-2" /> Additional Services
                    </h6>
                    <Row>
                        {amenityGroups.additionalServices.map((amenity) => (
                            <Col md={4} key={amenity.key}>
                                <div className="amenity-checkbox">
                                    <input
                                        type="checkbox"
                                        id={amenity.key}
                                        className="form-check-input"
                                        checked={formData.selectedAmenities[amenity.key] || false}
                                        onChange={(e) => updateAmenities(amenity.key, e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor={amenity.key}>
                                        {amenity.label}
                                    </label>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>

            <div className="button-container">
                <button className="button-calm" onClick={onBack} disabled={isLoading}>
                    Back
                </button>
                <button
                    className={`button button-ripple ms-3 ${isLoading ? 'submit-progress loading' : ''}`}
                    onClick={submitForm}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <>
                            <Spinner animation="border" role="status" size="sm">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            Submitting Property...
                        </>
                    ) : (
                        "Submit"
                    )}
                </button>
            </div>
        </Container>
    );
};

export default AmenitiesTab; 