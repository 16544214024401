import { Constants } from "../utils/constants";
import logger from "../utils/logger";

class GeminiService {
    private readonly apiKey: string;
    private readonly baseUrl: string = "https://generativelanguage.googleapis.com/v1";
    private readonly model: string = "gemini-pro";
    private readonly visionModel: string = "gemini-pro-vision";

    constructor() {
        this.apiKey = Constants.GEMINI_API_KEY;
    }

    public async extractEmailsFromText(text: string): Promise<string[]> {
        try {
            const prompt = `
                Extract all valid email addresses from the following text. 
                Return ONLY a valid JSON array of strings containing the email addresses.
                For example: ["email1@example.com", "email2@example.com"]
                
                Text to process:
                ${text}
            `;

            const response = await this.generateText(prompt);
            return this.parseEmailsFromResponse(response);
        } catch (error) {
            logger.error("Error extracting emails from text:", error);
            throw new Error("Failed to extract emails from text");
        }
    }

    public async extractEmailsFromFile(file: File): Promise<string[]> {
        try {
            if (!file) {
                throw new Error("No file provided");
            }

            if (file.type === "text/plain") {
                const text = await this.readFileAsText(file);
                return this.extractEmailsFromText(text);
            }

            const imageData = await this.fileToBase64(file);
            const prompt = `
                Extract all valid email addresses from this file.
                Return ONLY a valid JSON array of strings containing the email addresses.
                For example: ["email1@example.com", "email2@example.com"]
            `;

            const response = await this.generateTextFromImage(prompt, imageData);
            return this.parseEmailsFromResponse(response);
        } catch (error) {
            logger.error("Error extracting emails from file:", error);
            throw new Error("Failed to extract emails from file");
        }
    }

    private async generateText(prompt: string): Promise<string> {
        const url = `${this.baseUrl}/models/${this.model}:generateContent?key=${this.apiKey}`;

        const data = {
            contents: [
                {
                    parts: [
                        {
                            text: prompt
                        }
                    ]
                }
            ]
        };

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Gemini API error: ${errorData.error?.message || response.statusText}`);
        }

        const result = await response.json();
        return result.candidates[0].content.parts[0].text || "";
    }

    private async generateTextFromImage(prompt: string, imageData: string): Promise<string> {
        const url = `${this.baseUrl}/models/${this.visionModel}:generateContent?key=${this.apiKey}`;

        const data = {
            contents: [
                {
                    parts: [
                        {
                            text: prompt
                        },
                        {
                            inline_data: {
                                mime_type: "image/jpeg",
                                data: imageData
                            }
                        }
                    ]
                }
            ]
        };

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Gemini Vision API error: ${errorData.error?.message || response.statusText}`);
        }

        const result = await response.json();
        return result.candidates[0].content.parts[0].text || "";
    }

    private parseEmailsFromResponse(response: string): string[] {
        try {
            const match = response.match(/\[.*?\]/s);
            if (!match) return [];

            const jsonArray = JSON.parse(match[0]);
            if (!Array.isArray(jsonArray)) return [];

            return jsonArray.filter(item =>
                typeof item === 'string' &&
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(item)
            );
        } catch (error) {
            logger.error("Error parsing emails from response:", error);
            return [];
        }
    }

    private async fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    const base64 = reader.result.split(',')[1];
                    resolve(base64);
                } else {
                    reject(new Error("Failed to convert file to base64"));
                }
            };
            reader.onerror = error => reject(error);
        });
    }

    private async readFileAsText(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    reject(new Error("Failed to read file as text"));
                }
            };
            reader.onerror = error => reject(error);
        });
    }
}

export const geminiService = new GeminiService();
export default geminiService;