import React from 'react';
import { Toaster as SonnerToaster } from 'sonner';

export const Toaster = () => {
    return (
        <SonnerToaster
            position="bottom-right"
            toastOptions={{
               
                closeButton: true,
                actionLabel: "Clos",
            }}
            richColors
            closeButton
        />
    );
};

export default Toaster; 