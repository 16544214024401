import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Image } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import styled from 'styled-components';
import SERVICES from '../../../services';
import { Constants } from '../../../utils/constants';
import confirm from '../../../img/Web/Spacia/bookings-received.svg';
import moneybag from '../../../img/Web/Spacia/revenue.svg';
import booking_icon from '../../../img/Web/Spacia/bookings.svg';
import home from '../../../img/Web/Spacia/total-props.svg';
import published from '../../../img/Web/Spacia/published-props.svg';
import pending from '../../../img/Web/Spacia/pending-props.svg';
import totalUsersIcon from '../../../img/Web/Spacia/total-users.svg';
import disabledUserIcon from '../../../img/Web/Spacia/disabled-users.svg';
import unpublishedListingIcon from '../../../img/Web/Spacia/unpublished-listings.svg';
import pendingInvitesIcon from '../../../img/Web/Spacia/pending-invites.svg';
import bookingCancelledIcon from '../../../img/Web/Spacia/booking-cancelled.svg';
import moment from "moment-timezone";
import ReactTooltip from 'react-tooltip';

import { useHistory } from "react-router-dom";
import apiService from '../../../services/apiService';
import { getCurrency } from '../../../services/localService';
import logger from "../../../utils/logger";
import { BlockerModal } from '../../../components/FeedbackModall';
// import warnin from '../../../assets/img/Web/Spacia/warning.png';
import warnin from '../../../img/Web/Spacia/t-warning 3.svg';

const shimmerKeyframes = `
  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`;

const ShimmerStyles = styled.div`
  ${shimmerKeyframes}
  
  .shimmer {
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #f6f7f8 4%, #edeef1 25%, #f6f7f8 36%);
    background-size: 1000px 100%;
  }
  
  .shimmer-card {
    height: 100%;
    border-radius: 5px;
    padding: 1.75rem;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    border: 1px solid #f0f0f0;
  }
  
  .shimmer-content {
    display: flex;
    align-items: center;
  }
  
  .shimmer-icon {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    margin-right: 1.25rem;
  }
  
  .shimmer-text {
    flex: 1;
  }
  
  .shimmer-title {
    height: 12px;
    width: 60%;
    margin-bottom: 0.75rem;
    border-radius: 4px;
  }
  
  .shimmer-value {
    height: 24px;
    width: 80%;
    border-radius: 4px;
  }
`;

const DashboardContainer = styled.div`
  height: calc(100vh - 80px);
  overflow-y: auto;
  padding: 24px;
  background: #F8F9FA;
`;

const StyledCard = styled(motion.div)`
    background: ${props => props.gradient || 'white'};
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    padding: 1.25rem;
    height: 100%;
    transition: all 0.3s ease;
    border: 1px solid #f0f0f0;
    
    ${props => props.darkText && `
        h1, h2, h3, h4, h5, h6 {
            color: #2c3e50;
        }
    `}

    ${props => props.gradient && `
        color: white;
        border: none;
    `}

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    }

    h6 {
        color: ${props => props.darkText ? '#2c3e50' : '#fff'};
        font-size: 0.9rem;
        font-weight: 600;
        margin-bottom: 0.75rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        opacity: 0.9;
    }

    h1 {
        font-size: 2.2rem;
        font-weight: 700;
        margin-bottom: 0;
        color: ${props => props.darkText ? '#2c3e50' : '#fff'};
    }

    img {
        width: 48px;
        height: 48px;
        margin-right: 1.25rem;
        filter: ${props => props.darkText ? 'none' : 'brightness(0) invert(1)'};
        opacity: 0.9;
    }
`;

const DashboardHeader = styled.div`
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .greeting-section {
        h2 {
            color: #1a1a1a;
            font-size: 1.75rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        p {
            color: #6c757d;
            font-size: 1rem;
            margin: 0;
        }
    }

    .date-chip {
        background: rgba(0, 123, 255, 0.1);
        border: 1px solid rgba(0, 123, 255, 0.2);
        padding: 0.5rem 1rem;
        border-radius: 100px;
        color: #007bff;
        font-weight: 500;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: all 0.3s ease;

        &:hover {
            background: rgba(0, 123, 255, 0.15);
            transform: translateY(-1px);
        }

        svg {
            width: 16px;
            height: 16px;
        }
    }
`;

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.6,
            ease: "easeOut"
        }
    }
};

const MetricCard = ({ icon, title, value, gradient, isLoading }) => {
    // Function to add line breaks between words
    const formatTitle = (title) => {
        return title.split(' ').join('\n');
    };

    if (isLoading) {
        return (
            <div className="shimmer-card">
                <div className="shimmer-content">
                    <div className="shimmer shimmer-icon" />
                    <div className="shimmer-text">
                        <div className="shimmer shimmer-title" />
                        <div className="shimmer shimmer-value" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <StyledCard
            variants={cardVariants}
            gradient={gradient}
        >
            <div className="d-flex align-items-center">
                <Image src={icon} alt={title} />
                <div>
                    <h6 style={{
                        color: "#ffffff",
                        fontSize: "0.9rem",
                        whiteSpace: "pre-line",
                        lineHeight: "1.2"
                    }}>
                        {formatTitle(title)}
                    </h6>
                    <h2 style={{ color: "#ffffff" }}>{value}</h2>
                </div>
            </div>
        </StyledCard>
    );
};

const RecentBookingsShimmer = () => (
    <div style={{ maxHeight: '380px', overflowY: 'auto' }}>
        {[1, 2, 3].map((index) => (
            <div key={index} className="shimmer-card" style={{ marginBottom: '1rem' }}>
                <div className="shimmer-content">
                    <div className="shimmer-text">
                        <div className="shimmer shimmer-title" style={{ width: '70%', marginBottom: '0.5rem' }} />
                        <div className="shimmer shimmer-value" style={{ width: '50%', marginBottom: '0.5rem' }} />
                        <div className="shimmer shimmer-value" style={{ width: '30%' }} />
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const RecentPropertiesCard = ({ properties, loading }) => {
    return (
        <StyledCard
            variants={cardVariants}
            className="h-100"
            darkText={true}
        >
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Recent Properties</h5>
                <ViewAllButton
                    to="/properties"
                    className="btn btn-outline-primary btn-sm"
                >
                    View All Properties
                </ViewAllButton>
            </div>
            {loading ? (
                <RecentBookingsShimmer />
            ) : (
                <div className="table-responsive" style={{ maxHeight: '380px', overflowY: 'auto' }}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Location</th>
                                <th>Price</th>
                                <th>Added On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {properties.map((property) => (
                                <tr key={property.id}>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div
                                                style={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    backgroundColor: property.status?.value === 'published' ? '#4CAF50' : '#757575',
                                                    marginRight: 8,
                                                    flexShrink: 0
                                                }}
                                            />
                                            {property.name || 'Unnamed Property'}
                                        </div>
                                    </td>
                                    <td>{property.location?.city || 'N/A'}</td>
                                    <td>{property.propertyPrice?.price ? amountFormatter(property.propertyPrice.price, 1) : 'N/A'}</td>
                                    <td>{formatDateTime(property.createdOn)}</td>
                                </tr>
                            ))}
                            {properties.length === 0 && (
                                <tr>
                                    <td colSpan="4" className="text-center text-muted">
                                        Get started by creating your first property
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </StyledCard>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                background: 'rgba(255, 255, 255, 0.95)',
                padding: '1rem',
                border: '1px solid rgba(0,0,0,0.05)',
                borderRadius: '12px',
                boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
            }}>
                <p style={{
                    margin: 0,
                    color: '#2c3e50',
                    fontWeight: 700,
                    fontSize: '0.9rem',
                    marginBottom: '0.5rem'
                }}>{label}</p>
                {payload.map((entry, index) => (
                    <p key={index} style={{
                        margin: '0.25rem 0',
                        color: entry.color,
                        fontSize: '0.85rem',
                        fontWeight: 500
                    }}>
                        {entry.name}: {entry.value}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const getStatusClass = (status) => {
    // If status is undefined or null, return default
    if (!status) return 'pending';

    // Ensure we're working with a string
    const statusStr = String(status).toLowerCase().trim();

    switch (statusStr) {
        case 'confirmed':
        case 'active':
            return 'active';
        case 'pending':
        case 'draft':
            return 'pending';
        case 'cancelled':
        case 'rejected':
            return 'cancelled';
        default:
            return 'pending';
    }
};

const getStatusDisplay = (status) => {
    // If status is undefined or null, return default
    if (!status) return 'Pending';

    // Ensure we're working with a string
    const statusStr = String(status).toLowerCase().trim();

    switch (statusStr) {
        case 'confirmed':
        case 'active':
            return 'Active';
        case 'pending':
        case 'draft':
            return 'Pending';
        case 'cancelled':
        case 'rejected':
            return 'Cancelled';
        default:
            return 'Pending';
    }
};

const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good Morning";
    if (hour < 18) return "Good Afternoon";
    return "Good Evening";
};

const amountFormatter = (num, digits = 1) => {
    const currency = getCurrency() || '£';
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },    // lowercase k for thousand
        { value: 1e6, symbol: "m" },    // lowercase m for million
        { value: 1e9, symbol: "b" },    // b for billion instead of G
        { value: 1e12, symbol: "t" },   // t for trillion
        { value: 1e15, symbol: "q" },   // q for quadrillion
        { value: 1e18, symbol: "qt" }   // qt for quintillion
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return currency + ' ' + (item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0");
};

const getStatusColor = (status) => {
    // For debugging
    logger.log('Status object:', status);

    if (!status || !status.value) return '#757575';

    // Convert to lowercase for case-insensitive comparison
    const statusValue = status.value.toLowerCase();

    const statusColors = {
        'active': '#4CAF50',    // Green
        'new': '#2196F3',       // Blue
        'pending': '#F3E921FF',   // Yellow
        'cancelled': '#F44336', // Red
    };

    return statusColors[statusValue] || '#757575';
};

const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date('2025-01-11T10:08:59Z'); // Using provided current time
    const diff = Math.floor((date - now) / (1000 * 60 * 60 * 24)); // difference in days

    // Format time consistently
    const timeStr = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });

    // Future dates
    if (diff > 0) {
        if (diff === 1) return `Tomorrow at ${timeStr}`;
        // All other future dates show regular format
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        }) + ` at ${timeStr}`;
    }

    // Past dates
    if (diff < 0) {
        if (diff === -1) return `Yesterday at ${timeStr}`;
        if (diff > -4) return `${Math.abs(diff)} days ago at ${timeStr}`;
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        }) + ` at ${timeStr}`;
    }

    // Today
    return `Today at ${timeStr}`;
};

const BookingItem = ({ booking }) => {
    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div
                        style={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            backgroundColor: getStatusColor(booking.status),
                            marginRight: 8,
                            flexShrink: 0
                        }}
                    />
                    {booking.listingItem?.name || 'Unnamed Property'}
                </div>
            </td>
            <td>{booking.referenceCode}</td>
            <td>{formatDateTime(booking.startOn)}</td>
            <td>{formatDateTime(booking.endOn)}</td>
            <td>{booking.requestingUser?.firstName} {booking.requestingUser?.lastName}</td>
        </tr>
    );
};

const ViewAllButton = styled(Link)`
  &.btn-outline-primary {
    color: white;
    background-color: #0d6efd;
    border-color: #0d6efd;
    border-radius: 6px;
    padding: 6px 16px;
    font-size: 14px;
    font-weight: 500;
    
    &:hover, &:focus, &:active {
      color: white !important;
      background-color: #0d6efd !important;
      border-color: #0d6efd !important;
    }
  }
`;

const Dashboard = () => {

    const [totalPropertiesCount, setTotalPropertiesCount] = useState(0);
    const [totalBookingsToday, setTotalBookingsToday] = useState(0);
    const [totalBookingCancellationWithin7Days, setTotalBookingCancellationWithin7Days] = useState(0);
    const [publishedPropertiesTotal, setPublishedPropertiesTotal] = useState(0);
    const [unpublishedPropertiesTotal, setUnpublishedPropertiesTotal] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [pendingInvitesCount, setPendingInvitesCount] = useState(0);
    const [inactiveUsersCount, setInactiveUsersCount] = useState(0);
    const [bookings, setBookings] = useState(0);
    const [pendingPropertiesLength, setPendingPropertiesLength] = useState(0);
    const [bookingsReceived, setBookingsReceived] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0.00);

    const [publishedProperties, setPublishedProperties] = useState([]);
    const [pendingProperties, setPendingProperties] = useState([]);
    const [showFinishSetupModal, setShowFinishSetupModal] = useState(false);
    const [recentBookings, setRecentBookings] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [loadingBookings, setLoadingBookings] = useState(true);

    const [userRole, setUserRole] = useState(null);
    const [recentProperties, setRecentProperties] = useState([]);
    const [loadingProperties, setLoadingProperties] = useState(false);

    const history = useHistory();

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const [loading, setLoading] = useState({
        properties: true,
        bookings: true,
        bookingsReceived: true,
        revenue: true,
        chart: true
    });

    const [currentTime, setCurrentTime] = useState(moment());
    const currentUser = SERVICES.getUser();
    const userName = currentUser?.firstName || 'User';

    const [showDocumentWarning, setShowDocumentWarning] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(moment());
        }, 60000); // Update every minute

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;
        const role = currentUser?.role;

        setUserRole(role);

        // If not ROLE_SUBSCRIBER_OWNER, fetch recent properties
        if (role !== 'ROLE_SUBSCRIBER_OWNER') {
            setLoadingProperties(true);
            apiService.getUserListings(userId, {
                page: 0,
                pageSize: 5,
                sortBy: 'createdOn',
                sortDir: 'desc'
            })
                .then(res => {
                    if (res.status === 200) {
                        setRecentProperties(res.data.data || []);
                    }
                })
                .finally(() => {
                    setLoadingProperties(false);
                });
        }

        // Common metrics for both Administrator and Host
        if (role === 'ROLE_ADMINISTRATOR' || role === 'ROLE_HOST') {
            apiService.getTotalPropertiesCount(userId)
                .then(res => {
                    if (res.status === 200) {
                        setTotalPropertiesCount(res.data);
                    }
                })
                .finally(() => setLoading(prev => ({ ...prev, properties: false })));

            apiService.getPublishedPropertiesCount(userId)
                .then(res => {
                    if (res.status === 200) {
                        setPublishedPropertiesTotal(res.data);
                    }
                })
                .finally(() => setLoading(prev => ({ ...prev, publishedProperties: false })));
        }

        // Role-specific metrics
        if (role === 'ROLE_ADMINISTRATOR') {
            apiService.getTotalRevenue(userId)
                .then(res => {
                    if (res.status === 200) {
                        setTotalRevenue(res.data);
                    }
                })
                .finally(() => setLoading(prev => ({ ...prev, revenue: false })));

            apiService.getBookingsMadeCount(userId)
                .then(res => {
                    if (res.status === 200) {
                        setBookings(res.data);
                    }
                })
                .finally(() => setLoading(prev => ({ ...prev, bookings: false })));
        } else if (role === 'ROLE_HOST') {
            const today = moment().format('YYYY-MM-DDTHH:mm:ss[Z]');
            const tomorrow = moment().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss[Z]');

            apiService.getHostDailyBookings({
                hostId: userId,
                from: today,
                to: tomorrow
            }).then(res => {
                if (res.status === 200) {
                    setTotalBookingsToday(res.data);
                }
            })
                .finally(() => setLoading(prev => ({ ...prev, todayBookings: false })));

            apiService.getHostDailyBookings({
                hostId: userId,
                from: today,
                to: tomorrow,
                status: 'CANCELLED'
            }).then(res => {
                if (res.status === 200) {
                    setTotalBookingCancellationWithin7Days(res.data);
                }
            })
                .finally(() => setLoading(prev => ({ ...prev, cancelledBookings: false })));
        } else if (role === 'ROLE_SUBSCRIBER_OWNER') {
            apiService.getActiveUsersCount(userId)
                .then(res => {
                    if (res.status === 200) {
                        setTotalUsers(res.data);
                    }
                })
                .finally(() => setLoading(prev => ({ ...prev, activeUsers: false })));

            apiService.getBookingsMadeCount(userId)
                .then(res => {
                    if (res.status === 200) {
                        setBookings(res.data);
                    }
                })
                .finally(() => setLoading(prev => ({ ...prev, activeBookings: false })));

            apiService.getPendingInvites(userId)
                .then(res => {
                    if (res.status === 200) {
                        setPendingInvitesCount(res.data);
                    }
                })
                .finally(() => setLoading(prev => ({ ...prev, pendingInvites: false })));

            apiService.getInactiveUsersCount(userId)
                .then(res => {
                    if (res.status === 200) {
                        setInactiveUsersCount(res.data);
                    }
                })
                .finally(() => setLoading(prev => ({ ...prev, inactiveUsers: false })));
        }
    }, []);

    useEffect(() => {
        const fetchRecentBookings = async () => {
            setLoadingBookings(true);
            try {
                const currentUser = SERVICES.getUser();
                if (!currentUser) return;

                const params = {
                    sortBy: 'updatedOn',
                    status: 'ACTIVE',
                    page: 0,
                    pageSize: 5
                };

                let response;
                if (currentUser.role === 'ROLE_ADMINISTRATOR') {
                    response = await apiService.getBookings(params);
                } else {
                    response = await apiService.getUserBookings(currentUser.id, params);
                }

                if (response.status === 200 && response.data?.data) {
                    const formattedBookings = response.data.data.map(booking => ({
                        ...booking,
                        propertyName: booking.propertyName || booking.property?.name || 'Unnamed Property',
                        tenantName: booking.tenantName || booking.tenant?.name || 'Unknown',
                        status: booking.status || 'PENDING'  // Ensure status always has a value
                    }));
                    logger.log('Formatted bookings:', formattedBookings);
                    setRecentBookings(formattedBookings);
                }
            } catch (error) {
                logger.error('Error fetching recent bookings:', error);
                setRecentBookings([]);
            } finally {
                setLoadingBookings(false);
            }
        };

        fetchRecentBookings();
    }, []);

    useEffect(() => {
        const currentUser = SERVICES.getUser();
        const userRole = currentUser?.role;

        // Only check for HOST roles
        if (userRole === 'ROLE_HOST' || userRole === 'ROLE_HOST_OPERATIONS') {
            const userId = currentUser?.id;
            const companyId = currentUser?.companyId;

            if (userId && companyId) {
                apiService.getCompanyDetails(companyId, userId)
                    .then(res => {
                        if (res.status === 200) {
                            const company = res.data?.data?.[0];
                            // Check if required documents are missing
                            const hasRequiredDocs = company?.documents?.length > 0;
                            if (!hasRequiredDocs) {
                                setShowDocumentWarning(true);
                            }
                        }
                    })
                    .catch(error => {
                        logger.error('Error checking company documents:', error);
                    });
            }
        }
    }, []);

    const renderMetrics = () => {
        const metrics = [];

        if (userRole === 'ROLE_ADMINISTRATOR') {
            metrics.push(
                <Col key="total-properties" md={6} xl={3}>
                    <MetricCard
                        icon={home}
                        title="Total Properties"
                        value={loading.properties ? 'Loading...' : totalPropertiesCount}
                        gradient="linear-gradient(45deg, #FF6B6B, #FF8E53)"
                        isLoading={loading.properties}
                    />
                </Col>,
                <Col key="published-properties" md={6} xl={3}>
                    <MetricCard
                        icon={published}
                        title="Published Properties"
                        value={loading.publishedProperties ? 'Loading...' : publishedPropertiesTotal}
                        gradient="linear-gradient(45deg, #4CAF50, #81C784)"
                        isLoading={loading.publishedProperties}
                    />
                </Col>,
                <Col key="revenue" md={6} xl={3}>
                    <MetricCard
                        icon={moneybag}
                        title="Total Revenue"
                        value={loading.revenue ? 'Loading...' : `$${amountFormatter(totalRevenue)}`}
                        gradient="linear-gradient(45deg, #2196F3, #64B5F6)"
                        isLoading={loading.revenue}
                    />
                </Col>,
                <Col key="total-bookings" md={6} xl={3}>
                    <MetricCard
                        icon={booking_icon}
                        title="Total Bookings"
                        value={loading.bookings ? 'Loading...' : bookings}
                        gradient="linear-gradient(45deg, #9C27B0, #BA68C8)"
                        isLoading={loading.bookings}
                    />
                </Col>
            );
        } else if (userRole === 'ROLE_HOST') {
            metrics.push(
                <Col key="total-properties" md={6} xl={3}>
                    <MetricCard
                        icon={home}
                        title="Total Properties"
                        value={loading.properties ? 'Loading...' : totalPropertiesCount}
                        gradient="linear-gradient(45deg, #FF6B6B, #FF8E53)"
                        isLoading={loading.properties}
                    />
                </Col>,
                <Col key="published-properties" md={6} xl={3}>
                    <MetricCard
                        icon={published}
                        title="Published Properties"
                        value={loading.publishedProperties ? 'Loading...' : publishedPropertiesTotal}
                        gradient="linear-gradient(45deg, #4CAF50, #81C784)"
                        isLoading={loading.publishedProperties}
                    />
                </Col>,
                <Col key="today-bookings" md={6} xl={3}>
                    <MetricCard
                        icon={booking_icon}
                        title="Today's Bookings"
                        value={loading.todayBookings ? 'Loading...' : totalBookingsToday}
                        gradient="linear-gradient(45deg, #2196F3, #64B5F6)"
                        isLoading={loading.todayBookings}
                    />
                </Col>,
                <Col key="cancelled-bookings" md={6} xl={3}>
                    <MetricCard
                        icon={bookingCancelledIcon}
                        title="Cancelled Bookings"
                        value={loading.cancelledBookings ? 'Loading...' : totalBookingCancellationWithin7Days}
                        gradient="linear-gradient(45deg, #F44336, #E57373)"
                        isLoading={loading.cancelledBookings}
                    />
                </Col>
            );
        } else if (userRole === 'ROLE_SUBSCRIBER_OWNER') {
            metrics.push(
                <Col key="active-users" md={6} xl={3}>
                    <MetricCard
                        icon={totalUsersIcon}
                        title="Active Users"
                        value={loading.activeUsers ? 'Loading...' : totalUsers}
                        gradient="linear-gradient(45deg, #4CAF50, #81C784)"
                        isLoading={loading.activeUsers}
                    />
                </Col>,
                <Col key="active-bookings" md={6} xl={3}>
                    <MetricCard
                        icon={booking_icon}
                        title="Active Bookings"
                        value={loading.activeBookings ? 'Loading...' : bookings}
                        gradient="linear-gradient(45deg, #2196F3, #64B5F6)"
                        isLoading={loading.activeBookings}
                    />
                </Col>,
                <Col key="pending-invites" md={6} xl={3}>
                    <MetricCard
                        icon={pendingInvitesIcon}
                        title="Pending Invites"
                        value={loading.pendingInvites ? 'Loading...' : pendingInvitesCount}
                        gradient="linear-gradient(45deg, #FF9800, #FFB74D)"
                        isLoading={loading.pendingInvites}
                    />
                </Col>,
                <Col key="inactive-users" md={6} xl={3}>
                    <MetricCard
                        icon={disabledUserIcon}
                        title="Inactive Users"
                        value={loading.inactiveUsers ? 'Loading...' : inactiveUsersCount}
                        gradient="linear-gradient(45deg, #F44336, #E57373)"
                        isLoading={loading.inactiveUsers}
                    />
                </Col>
            );
        }

        return metrics;
    };

    const handleStuffOnModalHide = () => {
        setShowFinishSetupModal(false);

        const dashboardContainer = document.querySelector('.dashboard-container');
        if (dashboardContainer != null) {
            dashboardContainer.classList.remove('blur-background');
        }
    };

    return (
        <DashboardContainer>
            <ShimmerStyles>
                <Container fluid className="px-4 py-4">
                    <DashboardHeader>
                        <div className="greeting-section">
                            <h2>
                                {getGreeting()}, {userName} 👋
                            </h2>
                            <p>Here's what's happening with your properties</p>
                        </div>
                        <div className="date-chip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                                <line x1="16" y1="2" x2="16" y2="6" />
                                <line x1="8" y1="2" x2="8" y2="6" />
                                <line x1="3" y1="10" x2="21" y2="10" />
                            </svg>
                            {currentTime.format('MMMM D, YYYY')}
                        </div>
                    </DashboardHeader>

                    <motion.div
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                    >
                        <Row className="g-4 mb-4">
                            {renderMetrics()}
                        </Row>

                        <Row className="mb-4">
                            <Col>
                                {userRole === 'ROLE_SUBSCRIBER_OWNER' ? (
                                    <StyledCard
                                        variants={cardVariants}
                                        className="h-100"
                                        darkText={true}
                                    >
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <h5 className="mb-0">Recent Bookings</h5>
                                            <ViewAllButton
                                                to="/reservations"
                                                className="btn btn-outline-primary btn-sm"
                                            >
                                                View All Reservations
                                            </ViewAllButton>
                                        </div>
                                        {loadingBookings ? (
                                            <RecentBookingsShimmer />
                                        ) : (
                                            <div className="table-responsive" style={{ maxHeight: '380px', overflowY: 'auto' }}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Property</th>
                                                            <th>Reference</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Customer</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {recentBookings.map((booking) => (
                                                            <BookingItem key={booking.id} booking={booking} />
                                                        ))}
                                                        {recentBookings.length === 0 && (
                                                            <tr>
                                                                <td colSpan="5" className="text-center text-muted">
                                                                    No recent bookings
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </StyledCard>
                                ) : (
                                    <RecentPropertiesCard
                                        properties={recentProperties}
                                        loading={loadingProperties}
                                    />
                                )}
                            </Col>
                        </Row>
                    </motion.div>
                </Container>
            </ShimmerStyles>

            
        </DashboardContainer>
    );
};

export default Dashboard;
