import React, { useState, useEffect } from 'react';
import { NavDropdown, Badge } from 'react-bootstrap';
import apiService from '../../services/apiService';
import moment from 'moment';
import logger from "../../utils/logger";
import {
    BsPerson,
    BsGearFill,
    BsPeopleFill,
    BsBell,
    BsCheckAll
} from 'react-icons/bs';

export const NotificationsPopover = ({ currentUser }) => {
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const [activeTab, setActiveTab] = useState('unread');
    const [expandedMessages, setExpandedMessages] = useState({});

    const getNotificationIcon = (type) => {
        switch (type) {
            case 'private':
                return <BsPerson className="notification-icon" />;
            case 'public':
                return <BsGearFill className="notification-icon" />;
            case 'roleBased':
                return <BsPeopleFill className="notification-icon" />;
            default:
                return <BsBell className="notification-icon" />;
        }
    };

    const processNotifications = (notifications) => {
        const { privateNotifications = [], publicNotifications = [], roleBasedNotifications = [] } = notifications;

        const processedNotifications = [
            ...privateNotifications.map(n => ({
                ...n,
                type: 'private',
                message: n.body,
                isRead: n.readStatus
            })),
            ...publicNotifications.map(n => ({
                ...n,
                type: 'public',
                message: n.body,
                isRead: n.readStatus
            })),
            ...roleBasedNotifications.map(n => ({
                ...n,
                type: 'roleBased',
                message: n.body,
                isRead: n.readStatus
            }))
        ];

        return processedNotifications.sort((a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
    };

    const fetchNotifications = async () => {
        if (loading || !currentUser?.id) return;
        setLoading(true);

        try {
            logger.log('Fetching notifications for user:', currentUser.id);
            const response = await apiService.fetchNotifications(page, 220, currentUser.id);
            logger.log('Notifications response:', response.data);

            if (response.status === 200) {
                const processedNotifications = processNotifications(response.data);

                logger.log('Processed notifications:', processedNotifications);

                setNotifications(processedNotifications);
                setUnreadCount(processedNotifications.filter(n => !n.isRead).length);
                setPage(prev => prev + 1);
            }
        } catch (error) {
            logger.error('Error fetching notifications:', error);
            logger.error('Error details:', error.response?.data);
        } finally {
            setLoading(false);
        }
    };

    const markAllAsRead = async () => {
        try {
            await apiService.markAllNotificationsAsRead(currentUser.id);
            setNotifications(prev =>
                prev.map(notification => ({ ...notification, isRead: true }))
            );
            setUnreadCount(0);
        } catch (error) {
            logger.error('Error marking all as read:', error);
        }
    };

    const toggleMessageExpansion = (id) => {
        setExpandedMessages(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const renderMessage = (message, id) => {
        const maxLength = 100;
        if (message.length <= maxLength) return message;

        const isExpanded = expandedMessages[id];
        if (isExpanded) {
            return (
                <span>
                    {message}
                    <button
                        className="read-more-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleMessageExpansion(id);
                        }}
                    >
                        Show less
                    </button>
                </span>
            );
        }

        return (
            <span>
                {message.substring(0, maxLength)}...
                <button
                    className="read-more-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleMessageExpansion(id);
                    }}
                >
                    Read more
                </button>
            </span>
        );
    };

    useEffect(() => {
        if (currentUser?.id) {
            fetchNotifications();
        }
    }, [currentUser]);

    const getFilteredNotifications = (tab) => {
        switch (tab) {
            case 'unread':
                return notifications.filter(n => !n.isRead);
            case 'read':
                return notifications.filter(n => n.isRead);
            case 'all':
                return notifications;
            default:
                return [];
        }
    };

    const updateReadStatus = async (notificationId, readStatus) => {
        try {
            await apiService.updateNotificationReadStatus(notificationId, currentUser.id, readStatus);
            setNotifications(prev =>
                prev.map(notification =>
                    notification.id === notificationId
                        ? { ...notification, isRead: readStatus }
                        : notification
                )
            );
            setUnreadCount(prev => readStatus ? prev - 1 : prev + 1);
        } catch (error) {
            logger.error('Error updating read status:', error);
        }
    };

    const NotificationItem = ({ notification }) => {
        const [isExpanded, setIsExpanded] = useState(false);

        const renderMessage = () => {
            const message = notification.body || notification.message || '';
            if (!message) return '';

            if (isExpanded) {
                return message;
            }
            return message.length > 100 ? `${message.substring(0, 100)}...` : message;
        };

        const formattedDate = moment(new Date(
            notification.createdAt.year,
            notification.createdAt.monthValue - 1,
            notification.createdAt.dayOfMonth,
            notification.createdAt.hour,
            notification.createdAt.minute
        )).fromNow();

        return (
            <div
                className={`notification-item ${notification.readStatus ? 'read' : 'unread'}`}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className="notification-header">
                    <div className="icon-wrapper">
                        {getNotificationIcon(notification.type)}
                    </div>
                    <div className="notification-content">
                        <div className="title-row">
                            <h6>{notification.title}</h6>
                            <small>{formattedDate}</small>
                        </div>
                        <div className="message-content">
                            <p>{renderMessage()}</p>
                            {(notification.body || notification.message || '').length > 100 && (
                                <button
                                    className="expand-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsExpanded(!isExpanded);
                                    }}
                                >
                                    {isExpanded ? 'Show less' : 'Read more'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="notification-bell-wrapper me-3">
            <NavDropdown
                align="end"
                className="notifications-dropdown"
                title={
                    <div style={{ display: 'inline-block', position: 'relative' }}>
                        <BsBell className="notification-bell" />
                        {unreadCount > 0 && (
                            <span className="notification-indicator" />
                        )}
                    </div>
                }
                id="notification-dropdown"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="notifications-header">
                    <h6 className="mb-0">Notifications ({getFilteredNotifications(activeTab).length})</h6>
                    {unreadCount > 0 && (
                        <button
                            className="mark-all-read-btn"
                            onClick={markAllAsRead}
                            title="Mark all as read"
                        >
                            <BsCheckAll size={20} />
                        </button>
                    )}
                </div>

                <div className="notification-tabs">
                    <button
                        className={`tab-button ${activeTab === 'all' ? 'active' : ''}`}
                        onClick={() => setActiveTab('all')}
                    >
                        All ({notifications.length})
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'unread' ? 'active' : ''}`}
                        onClick={() => setActiveTab('unread')}
                    >
                        Unread ({notifications.filter(n => !n.isRead).length})
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'read' ? 'active' : ''}`}
                        onClick={() => setActiveTab('read')}
                    >
                        Read ({notifications.filter(n => n.isRead).length})
                    </button>
                </div>

                <div className="notification-content-wrapper">
                    {loading ? (
                        <div className="text-center p-3">Loading...</div>
                    ) : getFilteredNotifications(activeTab).length === 0 ? (
                        <div className="text-center p-3">No notifications</div>
                    ) : (
                        getFilteredNotifications(activeTab).map(notification => (
                            <NotificationItem key={notification.id} notification={notification} />
                        ))
                    )}
                </div>
            </NavDropdown>
        </div>
    );
};

const styles = `
.notification-bell-wrapper {
    position: relative;
}

.notifications-dropdown {
    min-width: 360px !important;
    padding: 0 !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.notification-item {
    padding: 12px 16px;
    border-bottom: 1px solid #edf2f7;
    transition: background-color 0.2s ease;
}

.notification-item:hover {
    background-color: #f8fafc;
    cursor: pointer;
}

.notification-item.unread {
    background-color: #f0f9ff;
}

.notification-header {
    display: flex;
    gap: 12px;
    align-items: flex-start;
}

.icon-wrapper {
    padding-top: 2px;
}

.notification-icon {
    color: #4a5568;
    font-size: 18px;
}

.notification-content {
    flex: 1;
}

.title-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4px;
}

.title-row h6 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #2d3748;
}

.title-row small {
    color: #718096;
    font-size: 12px;
    white-space: nowrap;
    margin-left: 8px;
}

.message-content p {
    margin: 0;
    font-size: 13px;
    color: #4a5568;
    line-height: 1.5;
}

.expand-button {
    background: none;
    border: none;
    color: #3182ce;
    font-size: 12px;
    padding: 4px 0;
    margin-top: 4px;
    cursor: pointer;
}

.expand-button:hover {
    color: #2c5282;
    text-decoration: underline;
}

.notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #edf2f7;
}

.notifications-header h6 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.notification-tabs {
    display: flex;
    padding: 8px 16px;
    border-bottom: 1px solid #edf2f7;
    background: #f8fafc;
}

.tab-button {
    background: none;
    border: none;
    padding: 6px 12px;
    font-size: 13px;
    color: #4a5568;
    cursor: pointer;
    border-radius: 4px;
}

.tab-button:hover {
    background-color: #edf2f7;
}

.tab-button.active {
    background-color: #e2e8f0;
    color: #2d3748;
    font-weight: 500;
}

.mark-all-read-btn {
    background: none;
    border: none;
    color: #3182ce;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
}

.mark-all-read-btn:hover {
    background-color: #ebf8ff;
}
`; 