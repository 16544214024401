import React, { Component } from "react";
import { Row, Modal } from "react-bootstrap";
import { FaCamera } from "react-icons/fa";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import defaultProfileImage from "../../assets/img/profileplace.png";
import { userServiceRxJS } from "./services/UserServiceRxJS.jsx";
import { SuccessAlert } from './alerts/SuccessAlert';
import { ErrorAlert } from './alerts/ErrorAlert';
import { inputValidationService } from './services/InputValidationService';
import { Constants } from '../../utils/constants';
import apiService from '../../services/apiService';
import logger from "../../utils/logger";
import toastUtils from "../../utils/toast";

export class Profile extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      phoneNoOptI: "",
      phoneNoOptII: "",
      isFirstNameInvalid: false,
      isLastNameInvalid: false,
      isPhoneNoOpt1Invalid: false,
      isPhoneNoOpt2Invalid: false,
      hasAvatar: false,
      avatar: "",
      showDeleteModal: false,
      isDeleting: false,
      countryCode: "gh",
    };
  }

  userDetailsSubscription = null;
  updateUserDetailsSubscription = null;

  componentDidMount = () => {
    const currentUser = this.currentUserDetails();
    if (!currentUser || !currentUser.id) {
      logger.log("No valid user found, redirecting to login");
      window.location.href = '/signin';
      return;
    }

    const id = currentUser.id;
    logger.log("Fetching user details for ID:", id);

    //fetch user details from web service
    userServiceRxJS.getUserDetails(id);

    //listen for response on fetch user details from web services
    this.userDetailsSubscription = userServiceRxJS.userDetailsSubject.subscribe((e) => {
      const keys = Object.keys(e);
      if (keys.length > 0) {
        const status = e?.status;
        if (status === 200) {
          const data = e?.data;
          // Check if data is an array and has items
          if (Array.isArray(data) && data.length > 0) {
            // Handle first item since we're dealing with a single user
            const userData = data[0];
            this.setState({
              firstName: userData?.firstName || '',
              lastName: userData?.lastName || '',
            });

            const contacts = userData?.contacts;
            const phoneNos = this.fetchPhoneNumbers(contacts);

            if (phoneNos.length > 0) {
              this.setState({
                phoneNoOptI: phoneNos[0] || '',
                phoneNoOptII: phoneNos[1] || '',
              });
            }

            const avatar = userData?.avatar;
            if (avatar && avatar.trim() !== "") {
              this.setState({
                avatar: avatar,
                hasAvatar: true,
              });
            }
          } else if (typeof data === 'object' && data !== null) {
            // Handle single object response
            this.setState({
              firstName: data?.firstName || '',
              lastName: data?.lastName || '',
            });

            const contacts = data?.contacts;
            const phoneNos = this.fetchPhoneNumbers(contacts);

            if (phoneNos.length > 0) {
              this.setState({
                phoneNoOptI: phoneNos[0] || '',
                phoneNoOptII: phoneNos[1] || '',
              });
            }

            const avatar = data?.avatar;
            if (avatar && avatar.trim() !== "") {
              this.setState({
                avatar: avatar,
                hasAvatar: true,
              });
            }
          } else {
            logger.error("Unexpected data format:", data);
            toastUtils.error("Error", {
              description: "Error loading user data"
            });
          }
        } else {
          logger.error("Error status:", status);
          toastUtils.error("Error", {
            description: "Error loading user data"
          });
        }
      }
    });

    //listen to response from update user details
    this.updateUserDetailsSubscription = userServiceRxJS.updateUserDetailsSubject.subscribe((e) => {
      const keys = Object.keys(e);
      if (keys.length > 0) {
        const status = e?.status;
        if (status === 200) {
          logger.log(e);
          toastUtils.success("Profile Update", {
            description: "Update completed successfully"
          });
        } else {
          toastUtils.error("Update Failed", {
            description: "Error occurred while updating your profile."
          });
        }
      }
    });
  };

  componentWillUnmount = () => {
    if (this.userDetailsSubscription !== null) {
      this.userDetailsSubscription.complete();
    }

    if (this.updateUserDetailsSubscription !== null) {
      this.updateUserDetailsSubscription.complete();
    }

    userServiceRxJS.resetUpdateUserDetailsSubject();
    userServiceRxJS.resetUserDetailsSubject();
  };

  fetchPhoneNumbers = (contacts) => {
    const phoneNos = [];
    if (contacts && Array.isArray(contacts)) {
      contacts.forEach(contact => {
        if (contact?.type === "mobile" && contact?.value) {
          let phoneNumber = contact.value;

          // If number doesn't start with +, add it
          if (!phoneNumber.startsWith('+')) {
            // Handle country codes
            if (phoneNumber.startsWith('233')) { // Ghana
              phoneNumber = `+${phoneNumber}`;
              this.setState({ countryCode: 'gh' });
            } else if (phoneNumber.startsWith('254')) { // Kenya
              phoneNumber = `+${phoneNumber}`;
              this.setState({ countryCode: 'ke' });
            } else {
              phoneNumber = `+${phoneNumber}`;
            }
          }

          logger.log('Formatted phone number:', phoneNumber);
          phoneNos.push(phoneNumber);
        }
      });
    }
    return phoneNos;
  };

  handlePhoneChange = (value, field) => {
    this.setState({
      [field]: value || ''
    });
  };

  onSubmit = () => {
    let payload = {};
    const { firstName, lastName, phoneNoOptI, phoneNoOptII, avatar } = this.state;
    const currentUser = this.currentUserDetails();

    if (!currentUser || !currentUser.id) {
      logger.error("No valid user found for update");
      toastUtils.error("Update Failed", {
        description: "User session expired. Please login again."
      });
      return;
    }

    // Reset error states
    this.setState({
      isFirstNameInvalid: false,
      isLastNameInvalid: false,
      isPhoneNoOpt1Invalid: false,
      isPhoneNoOpt2Invalid: false
    });

    payload["firstName"] = firstName || "";
    payload["lastName"] = lastName || "";

    // Format phone numbers for API
    const formatPhoneForApi = (phone) => {
      if (!phone) return "";
      // Remove any non-digit characters except +
      let formatted = phone.replace(/[^\d+]/g, '');
      // Remove + if present
      formatted = formatted.replace(/^\+/, '');
      return formatted;
    };

    const contacts = [
      {
        deviceTokens: [],
        isContactable: true,
        isPrimary: true,
        type: "mobile",
        value: formatPhoneForApi(phoneNoOptI)
      },
      {
        deviceTokens: [],
        isContactable: true,
        isPrimary: false,
        type: "mobile",
        value: formatPhoneForApi(phoneNoOptII)
      }
    ];

    payload["contacts"] = contacts;
    payload["avatar"] = avatar || null;

    const id = currentUser.id;
    const token = currentUser.token;

    logger.log("Updating user details for ID:", id);
    userServiceRxJS.updateUserDetails(id, payload, token);
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value,
    });
  };

  validatePhone = (value, isOptional) => {
    let answer = false;

    if (isOptional) {
      answer = true;
    } else {
      answer = inputValidationService.validatePhoneNo(value);
    }

    return answer;
  };

  currentUserDetails = () => {
    try {
      const jsonStr = localStorage.getItem("currentUser");
      if (!jsonStr) {
        logger.log("No user data in localStorage");
        return null;
      }

      const userDetails = JSON.parse(jsonStr);
      if (!userDetails || !userDetails.id) {
        logger.log("Invalid user data in localStorage:", userDetails);
        return null;
      }

      return userDetails;
    } catch (error) {
      logger.error("Error parsing user data from localStorage:", error);
      return null;
    }
  };

  isAdminUser = () => {
    const currentUser = this.currentUserDetails();
    return currentUser?.role === 'ROLE_ADMINISTRATOR';
  };

  triggerImage = (e) => {
    this.imageElement.click();
  };

  getBase64 = (file) => new Promise(function (resolve, reject) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject('Error: ', error);
  });

  changeImage = (e) => {
    const file = e.target.files[0];

    this.getBase64(file)
      .then(result => this.setState({ hasAvatar: true, avatar: result }))
      .catch(e => logger.log(e));
  };

  handleDeleteAccount = async () => {
    try {
      this.setState({ isDeleting: true });
      const currentUser = this.currentUserDetails();
      const userId = currentUser?.id;

      const response = await apiService.deleteUserAccount(userId);

      if (response.status === 200 ||
        (response.status === 401 && response.data?.message?.includes("Token has expired"))) {
        // Clear storage and redirect for both successful deletion and token expiration
        localStorage.clear();
        window.location.href = '/signin';
      } else if (response.data?.message?.includes("pending admin approval")) {
        // Show pending approval message
        toastUtils.success("Account Deletion", {
          description: "Your request to delete your account is pending admin approval."
        });
        this.setState({ showDeleteModal: false });
      } else {
        toastUtils.error("Account Deletion", {
          description: "Failed to delete account. Please try again."
        });
      }
    } catch (error) {
      logger.error('Error deleting account:', error);
      toastUtils.error("Account Deletion", {
        description: "Failed to delete account. Please try again."
      });
    } finally {
      this.setState({ isDeleting: false });
    }
  };

  render() {
    const {
      firstName,
      lastName,
      phoneNoOptI,
      phoneNoOptII,
      isFirstNameInvalid,
      isLastNameInvalid,
      isPhoneNoOpt1Invalid,
      isPhoneNoOpt2Invalid,
      hasAvatar,
      avatar,
      showDeleteModal,
      isDeleting,
      countryCode,
    } = this.state;

    return (
      <div>
        <p>&nbsp;</p>
        <h4>Edit Profile</h4>
        <div style={{ maxWidth: '200px', margin: '0' }}>
          <div style={{
            width: '150px',
            height: '150px',
            borderRadius: '50%',
            position: 'relative',
            cursor: 'pointer',
            margin: '20px auto',
            overflow: 'hidden'
          }}>
            <img
              src={hasAvatar ? avatar : defaultProfileImage}
              onClick={this.triggerImage}
              alt="Profile"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
            <FaCamera
              style={{
                position: 'absolute',
                bottom: '20px',
                right: '20px',
                color: '#3078FF',
                fontSize: '32px',
                zIndex: 2
              }}
            />
          </div>
        </div>
        {/* invisible input field for image */}
        <input style={{ display: 'none' }} type="file" ref={input => this.imageElement = input} onChange={(e) => this.changeImage(e)} id="" />
        <form>
          {/* <br /> */}
          <Row>
            <div className="form-group col">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                required={true}
                className={
                  isFirstNameInvalid
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="firstName"
                onChange={(e) => {
                  this.handleChange(e);
                }}
                defaultValue={firstName}
                id=""
                aria-describedby="emailHelpId"
                placeholder=""
              />
              <div className="invalid-feedback">
                your first name is required
              </div>
            </div>
            {/* <br /> */}
            <div className="form-group col">
              <label htmlFor="">Last Name</label>
              <input
                type="email"
                className={
                  isLastNameInvalid ? "form-control is-invalid" : "form-control"
                }
                name="lastName"
                onChange={(e) => {
                  this.handleChange(e);
                }}
                defaultValue={lastName}
                id=""
                aria-describedby="emailHelpId"
                placeholder=""
              />
              <div className="invalid-feedback">your last name is required</div>
            </div>
          </Row>
          {/* <br /> */}

          <div className="form-group">
            <label htmlFor="">Phone Number</label>
            <PhoneInput
              international
              defaultCountry="KE"
              value={phoneNoOptI}
              onChange={(value) => this.handlePhoneChange(value, 'phoneNoOptI')}
              className="form-control"
              style={{
                backgroundColor: '#F8F9FA'
              }}
            />
          </div>
          {/* <br /> */}
          <div className="form-group">
            <label htmlFor="">Alternative Phone Number</label>
            <PhoneInput
              international
              defaultCountry="GH"
              value={phoneNoOptII}
              onChange={(value) => this.handlePhoneChange(value, 'phoneNoOptII')}
              className="form-control"
              style={{
                backgroundColor: '#F8F9FA'
              }}
            />
          </div>
          {/* <br /> */}

          <button
            type="button"
            className="btn btn-primary"
            style={{
              padding: "8px 24px",
              fontSize: "14px",
              border: "1px solid transparent",
              width: "160px"
            }}
            onClick={() => this.onSubmit()}
          >
            Save
          </button>
        </form>

        <div className="mt-5 pt-4 border-top mb-5">
          {!this.isAdminUser() && (
            <div className="">
              <h5 className="text-danger mb-3" style={{ display: 'inline' }}>Danger Zone</h5>
              <span style={{ marginLeft: '10px', color: 'red' }}>(Deletion of your account cannot be reversed)</span>
              <div style={{ marginTop: '15px' }}>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  style={{
                    // borderRadius: "100px",
                    padding: "8px 24px",
                    fontSize: "14px",
                    border: "1px solid #dc3545",
                    width: "160px"
                  }}
                  onClick={() => this.setState({ showDeleteModal: true })}
                >
                  Delete Account
                </button>
              </div>
            </div>
          )}
        </div>

        <Modal
          show={showDeleteModal}
          onHide={() => this.setState({ showDeleteModal: false })}
          centered
          style={{
            borderRadius: "30px",
          }}
        >
          <Modal.Header
            style={{
              border: 'none',
              padding: '15px 15px 0 15px',
              display: 'flex',
              justifyContent: 'flex-end',
              minHeight: '40px'
            }}
          >
            <button
              className="btn-close"
              onClick={() => this.setState({ showDeleteModal: false })}
              style={{
                background: 'none',
                border: 'none',
                fontSize: '20px',
                color: '#000',
                padding: '0',
                margin: '0',
                opacity: 0.5,
                cursor: 'pointer',
                position: 'absolute',
                right: '15px',
                top: '10px'
              }}
              aria-label="Close"
            >
              ×
            </button>
          </Modal.Header>
          <Modal.Body
            className="text-center"
            style={{
              padding: "5px 20px 24px 20px"
            }}
          >
            <h5
              className="text-primary"
              style={{
                fontSize: "18px",
                marginTop: "10px",
                marginBottom: "24px",
                fontWeight: "normal"
              }}
            >
              You are about to delete your account
            </h5>
            <div className="d-flex justify-content-center gap-3">
              <button
                className="btn btn-outline-primary"
                style={{
                  borderRadius: "20px",
                  width: "8rem"
                }}
                onClick={() => this.setState({ showDeleteModal: false })}
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button
                className="btn btn-outline-primary"
                style={{
                  borderRadius: "20px",
                  width: "8rem"
                }}
                onClick={this.handleDeleteAccount}
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Deleting...</span>
                  </div>
                ) : 'Confirm'}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* CSS to match form styling */}
        <style jsx global>{`
          .PhoneInput {
            background-color: #F8F9FA !important;
          }

          .PhoneInput .PhoneInputInput {
            background-color: #F8F9FA !important;
            border: none !important;
            padding: 0.375rem 0.75rem;
            height: 25px !important;
            {/* font-size: 1rem; */}
          }

          .PhoneInput .PhoneInputCountry {
            margin-right: 0.2rem;
            padding-left: 0.2rem;
            height: 25px !important;
            display: flex;
            align-items: center;
          }
        `}</style>
      </div>
    );
  }
}

export default Profile;
