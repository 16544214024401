import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Spinner, Button, Form, Modal } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import moment from 'moment';
import SERVICES from '../../../../../services';
import apiService from '../../../../../services/apiService';
import Pagination from '../../../../../components/Pagination';
import { SuccessAlert } from '../../../../../components/Settings/alerts/SuccessAlert';
import { ErrorAlert } from '../../../../../components/Settings/alerts/ErrorAlert';
import logger from "../../../../../utils/logger";

interface Host {
  companyId: number;
  companyName: string;
  emails: string[];
  telephones: string[] | null;
  signUpDate: string;
  ownerId: number;
  ownerName: string;
  isEnabled: boolean;
  companyAddress: string | null;
  companyWebsite: string;
}

const MobileHostStyles = styled.div`
  background: #ffffff;
  min-height: 100vh;
  padding-bottom: 16px;

  .container {
    padding: 12px;
    max-width: 100%;
  }

  .header {
    background: #fff;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    margin-bottom: 16px;
  }

  .header h4 {
    color: #1a1a1a;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .search-container {
    position: relative;
  }

  .search-container .form-control {
    height: 44px;
    border-radius: 22px;
    padding: 0 48px 0 16px;
    border: 1px solid #e2e8f0;
    font-size: 0.95rem;
    width: 100%;
    
    &:focus {
      border-color: #4299e1;
      box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.15);
    }

    &::placeholder {
      color: #a0aec0;
    }
  }

  .search-button {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4299e1;
    border: none;
    color: white;
    
    &:active {
      transform: scale(0.95);
    }
  }

  .hosts-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .host-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    overflow: hidden;
    
    .host-header {
      padding: 12px 16px;
      background: #f7fafc;
      border-bottom: 1px solid #e2e8f0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.875rem;
      color: #4a5568;
    }

    .host-content {
      padding: 16px;

      .host-info {
        margin-bottom: 16px;

        .company-name {
          font-size: 1.125rem;
          font-weight: 600;
          color: #2d3748;
          margin-bottom: 8px;
        }

        .owner-name {
          font-size: 1rem;
          color: #4a5568;
          margin-bottom: 12px;
        }

        .contact-info {
          font-size: 0.875rem;
          color: #718096;
          display: flex;
          flex-direction: column;
          gap: 4px;

          .label {
            font-weight: 500;
            color: #4a5568;
            margin-right: 8px;
          }
        }

        .address {
          margin-top: 8px;
          font-size: 0.875rem;
          color: #718096;
        }

        .website {
          margin-top: 8px;
          font-size: 0.875rem;
          color: #4299e1;
          text-decoration: none;
          
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .host-actions {
        display: flex;
        gap: 8px;
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid #e2e8f0;
      }
    }
  }

  .empty-state {
    text-align: center;
    padding: 32px;
    color: #718096;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    padding: 32px;
  }

  .pagination-container {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
`;

const ApprovedHosts = () => {
  const [hosts, setHosts] = useState<Host[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [disableReason, setDisableReason] = useState<string>('');
  const [showDisableReasonModal, setShowDisableReasonModal] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<number>(0);
  const [showDisableHostModal, setShowDisableHostModal] = useState<boolean>(false);

  const fetchHosts = async (page: number) => {
    setIsLoading(true);
    try {
      const response = await apiService.getApprovedHosts(page, searchValue);
      if (response?.status === 200) {
        const approvedHosts = response.data.data || [];
        setTotalPageSize(response.data.totalCount);
        setHosts(approvedHosts);
      }
    } catch (error) {
      logger.error('Error fetching hosts:', error);
      setErrorMessage('Failed to fetch hosts');
      setShowErrorAlert(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchHosts(currentPage - 1);
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const performSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchHosts(0);
  };

  const disableHost = (companyId: number) => {
    setShowDisableReasonModal(false);
    const currentUser = SERVICES.getUser();
    const id = currentUser && currentUser.id;

    apiService.updateCompanyStatus(companyId.toString(), id, false, disableReason)
      .then(res => {
        if (res.status === 200) {
          setShowDisableReasonModal(false);
          window.location.reload();
        }
      })
      .finally(() => {
        setShowDisableReasonModal(false);
        setShowDisableHostModal(false);
      });
  };

  const handleDisableReasonModalHide = () => {
    setShowDisableReasonModal(false);
  };

  const displayDisableHostModal = (show: boolean, companyId: number) => {
    if (show) {
      setSelectedCompany(companyId);
      setShowDisableReasonModal(true);
    } else {
      setShowDisableHostModal(false);
    }
  };

  return (
    <MobileHostStyles>
      <SuccessAlert
        show={showSuccessAlert}
        message="Operation completed successfully"
        close={() => setShowSuccessAlert(false)}
        alertStyle={{}}
        title="Host Management"
      />
      <ErrorAlert
        show={showErrorAlert}
        message={errorMessage || "An error occurred"}
        close={() => setShowErrorAlert(false)}
        alertStyle={{}}
        title="Operation Failed"
      />

      <Container>
        <div className="header">
          <h4>Approved Hosts</h4>
          <Form onSubmit={performSearch} className="search-container">
            <Form.Group className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search hosts..."
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
              <Button
                type="submit"
                className="search-button"
              >
                <FaSearch />
              </Button>
            </Form.Group>
          </Form>
        </div>

        {isLoading ? (
          <div className="loading-container">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : hosts.length === 0 ? (
          <div className="empty-state">
            <p>No approved hosts found</p>
          </div>
        ) : (
          <>
            <div className="hosts-container">
              {hosts.map((host, index) => (
                <div key={host.companyId} className="host-card">
                  <div className="host-header">
                    <span>Joined: {moment(host.signUpDate).format('DD-MMM-YYYY')}</span>
                    <span>ID: {host.companyId}</span>
                  </div>
                  <div className="host-content">
                    <div className="host-info">
                      <div className="company-name">{host.companyName}</div>
                      <div className="owner-name">Owner: {host.ownerName}</div>

                      <div className="contact-info">
                        {host.emails && host.emails.length > 0 && (
                          <div>
                            <span className="label">Email:</span>
                            {host.emails.join(', ')}
                          </div>
                        )}

                        {host.telephones && host.telephones.length > 0 && (
                          <div>
                            <span className="label">Phone:</span>
                            {host.telephones.join(', ')}
                          </div>
                        )}

                        {host.companyAddress && (
                          <div className="address">
                            <span className="label">Address:</span>
                            {host.companyAddress}
                          </div>
                        )}

                        <a
                          href={host.companyWebsite}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="website"
                        >
                          View Website
                        </a>
                      </div>
                    </div>

                    <div className="host-actions">
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => displayDisableHostModal(true, host.companyId)}
                      >
                        Disable Host
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {hosts.length > 0 && (
              <div className="pagination-container">
                <Pagination
                  currentPage={currentPage}
                  totalCount={totalPageSize}
                  pageSize={itemsPerPage}
                  siblingCount={1}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </>
        )}
      </Container>

      <Modal
        show={showDisableReasonModal}
        onHide={handleDisableReasonModalHide}
        centered
        contentClassName="padding-40px"
        style={{
          overflow: "hidden",
          fontFamily: "Montserrat",
          borderRadius: "30px",
        }}
      >
        <Modal.Header
          style={{
            borderBottom: "none",
            paddingLeft: "0px",
            paddingRight: "20px",
            margin: "0px",
          }}
          closeButton
        >
          <Modal.Title
            style={{ width: "100%", textAlign: "center" }}
          >
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ height: '' }}>
          <div className="text-center position-relative" style={{ bottom: '20px' }}>
            <p className={'text-primary mt-0 mb-2 mx-auto'} style={{ fontSize: '1.3rem', width: '60%', fontWeight: 400 }}>
              Reason For Disabling
            </p>
            <form className={'mt-0'} onSubmit={(e) => {
              e.preventDefault();
              setShowDisableReasonModal(false);
              setShowDisableHostModal(true);
            }}>
              <textarea
                className={'form-control'}
                placeholder={'Text Here'}
                cols={30} rows={4}
                onChange={e => setDisableReason(e.target.value)}
                style={{ resize: 'none' }} />
              <div className={'mt-4'}>
                <button
                  className="btn btn-outline-primary"
                  style={{
                    borderRadius: "20px",
                    marginLeft: "0px",
                    width: "5rem"
                  }}
                  disabled={!disableReason}
                  type={'submit'}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDisableHostModal}
        onHide={() => setShowDisableHostModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Disable Host</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to disable this host?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDisableHostModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => disableHost(selectedCompany)}>
            Disable
          </Button>
        </Modal.Footer>
      </Modal>
    </MobileHostStyles>
  );
};

export default ApprovedHosts;
