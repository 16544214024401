// import {ScrollToTop} from "../../components";
import GlobalStyles from "../../styles/GlobalStyles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { About } from "./main";
import FAQs from "./main/FAQs";
import ReportIssue from "./main/ReportIssue";
import Signup from "./auth/signup-web";
import Confirm from "./confirm";
import SignIn from "./auth/login-web";
import SignInForCheckIn from "./auth/SignInForCheckIn";
import ResetPassword from "./auth/ResetPassword";
import SavePassword from "./SavePassword";
import GoToMail from "./auth/GoToMail";
import ConfirmInvite from "./ConfirmInvite";
import AppLayout from "../../components/shared/AppLayout";
import "../../index.scss";
// import {useLayoutEffect} from "react";
import HostLanding from "./host-landing-web";
import Landing from "./landing-web";
import PackageSignup from "./auth/signup-web/package-signup";
import OTPConfirmation from "./auth/signup-web/package-signup/otp-confirmation";
import PackageSelection from "./bundles/package-selection";
import PackageOrderSummary from "./bundles/package-order-summary";
import PricingPage from "./bundles/pricing-page";
import CorporatePage from "./bundles/corporate-page";
import BookingOrderSummary from "./booking/booking-order-summary";
import ScrollUpComponent from "../../scrollComponent";
import PaymentRedirect from '../PaymentRedirect';
import PrivacyPolicy from "../Legal/PrivacyPolicy";
import TermsofUse from "../Legal/Terms";
import DisabledHosts from "./main/hosts/DisabledHosts";

function DesktopLayout() {

    return (
        <Router>
            <ScrollUpComponent />
            <GlobalStyles />
            <Switch>
                <Route exact path='/home' component={Landing} />
                <Route exact path='/host' component={HostLanding} />
                {/*<Route exact path="/home" component={SearchPage} />*/}
                <Route exact path="/about" component={About} />
                <Route exact path="/frequently-asked-questions" component={FAQs} />
                <Route exact path="/report-issue" component={ReportIssue} />
                <Route exact path="/signup">
                    <Signup />
                </Route>
                <Route exact path="/package-signup" component={PackageSignup} />
                <Route exact path="/otp-confirmation" component={OTPConfirmation} />
                <Route exact path="/corporate-page" component={CorporatePage} />
                <Route exact path="/select-package" component={PackageSelection} />
                <Route exact path="/package-order-summary" component={PackageOrderSummary} />
                <Route exact path="/booking-order-summary" component={BookingOrderSummary} />
                <Route exact path="/payment-redirect" component={PaymentRedirect} />
                <Route exact path='/pricing' component={PricingPage} />
                <Route exact path="/confirm">
                    <Confirm />
                </Route>
                <Route exact path="/signin">
                    <SignIn />
                </Route>
                <Route path="/login/before/check-in">
                    <SignInForCheckIn />
                </Route>
                <Route path="/resetpassword">
                    <ResetPassword />
                </Route>
                <Route path="/savepassword">
                    <SavePassword />
                </Route>
                <Route path="/privacy-policy">
                    <PrivacyPolicy />
                </Route>
                <Route path="/terms-of-use">
                    <TermsofUse />
                </Route>
                <Route path="/gotomail">
                    <GoToMail />
                </Route>
                <Route path="/authentication/confirm-invite">
                    <ConfirmInvite />
                </Route>
                <Route exact path="/disabledHosts">
                    <AppLayout>
                        <DisabledHosts />
                    </AppLayout>
                </Route>
                <Route path="/">
                    <AppLayout />
                </Route>
            </Switch>
        </Router>
    );
};

export default DesktopLayout;
