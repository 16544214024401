import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';

/**
 * Toast utility functions for consistent toast styling across the application
 */
const toastUtils = {
    /**
     * Show a success toast
     * @param {string} message - The message to display (used as title)
     * @param {Object} options - Additional options for the toast
     * @param {string} options.description - Additional descriptive text
     * @param {Object} options.action - Action button configuration
     * @param {string} options.action.label - Button label
     * @param {Function} options.action.onClick - Button click handler
     * @param {string} options.id - Optional unique ID to prevent duplicates
     */
    success: (message, options = {}) => {
        const { action, onAutoClose, onDismiss, id, ...restOptions } = options;

        toast.success(message, {
            id: id || `success-${uuidv4()}`,
            description: options.description,
            action: action ? {
                label: action.label || 'Close',
                onClick: () => {
                    if (action && typeof action.onClick === 'function') {
                        action.onClick();
                    }
                }
            } : undefined,
            duration: 6000,
            ...restOptions,
            onAutoClose: () => {
                if (onAutoClose) onAutoClose();
            },
            onDismiss: () => {
                if (onDismiss) onDismiss();
            },
        });
    },

    /**
     * Show an error toast
     * @param {string} message - The message to display (used as title)
     * @param {Object} options - Additional options for the toast
     * @param {string} options.description - Additional descriptive text
     * @param {Object} options.action - Action button configuration
     * @param {string} options.action.label - Button label
     * @param {Function} options.action.onClick - Button click handler
     * @param {string} options.id - Optional unique ID to prevent duplicates
     */
    error: (message, options = {}) => {
        const { action, onAutoClose, onDismiss, id, ...restOptions } = options;

        toast.error(message, {
            id: id || `error-${uuidv4()}`,
            description: options.description,
            action: action ? {
                label: action.label || 'Close',
                onClick: () => {
                    if (action && typeof action.onClick === 'function') {
                        action.onClick();
                    }
                }
            } : undefined,
            duration: 6000,
            ...restOptions,
            onAutoClose: () => {
                if (onAutoClose) onAutoClose();
            },
            onDismiss: () => {
                if (onDismiss) onDismiss();
            },
        });
    },

    /**
     * Show a warning toast
     * @param {string} message - The message to display (used as title)
     * @param {Object} options - Additional options for the toast
     * @param {string} options.description - Additional descriptive text
     * @param {Object} options.action - Action button configuration
     * @param {string} options.action.label - Button label
     * @param {Function} options.action.onClick - Button click handler
     * @param {string} options.id - Optional unique ID to prevent duplicates
     */
    warning: (message, options = {}) => {
        const { action, onAutoClose, onDismiss, id, ...restOptions } = options;

        toast.warning(message, {
            id: id || `warning-${uuidv4()}`,
            description: options.description,
            action: action ? {
                label: action.label || 'Close',
                onClick: () => {
                    if (action && typeof action.onClick === 'function') {
                        action.onClick();
                    }
                }
            } : undefined,
            duration: 6000,
            ...restOptions,
            onAutoClose: () => {
                if (onAutoClose) onAutoClose();
            },
            onDismiss: () => {
                if (onDismiss) onDismiss();
            },
            style: {
                background: '#f0ad4e',
                color: 'white',
                border: 'none',
            },
        });
    },

    /**
     * Show an info toast
     * @param {string} message - The message to display (used as title)
     * @param {Object} options - Additional options for the toast
     * @param {string} options.description - Additional descriptive text
     * @param {Object} options.action - Action button configuration
     * @param {string} options.action.label - Button label
     * @param {Function} options.action.onClick - Button click handler
     * @param {string} options.id - Optional unique ID to prevent duplicates
     */
    info: (message, options = {}) => {
        const { action, onAutoClose, onDismiss, id, ...restOptions } = options;

        toast.info(message, {
            id: id || `info-${uuidv4()}`,
            description: options.description,
            action: action ? {
                label: action.label || 'Close',
                onClick: () => {
                    if (action && typeof action.onClick === 'function') {
                        action.onClick();
                    }
                }
            } : undefined,
            duration: 6000,
            ...restOptions,
            onAutoClose: () => {
                if (onAutoClose) onAutoClose();
            },
            onDismiss: () => {
                if (onDismiss) onDismiss();
            },
            style: {
                background: '#5bc0de',
                color: 'white',
                border: 'none',
            },
        });
    },
};

export default toastUtils; 