import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import configureStore from './redux/configureStore';

import { Constants } from './utils/constants';
import MobileLayout from './screens/MobileLayout';
import DesktopLayout from './screens/DesktopLayout';
import { isMobile } from 'react-device-detect';
import moment from 'moment-timezone';
// import { getMessaging, onMessage } from 'firebase/messaging';
// import { toast } from 'react-toastify';
import { getMessaging, onMessage } from 'firebase/messaging';
import { toast } from 'sonner';
import { SnackbarProvider } from 'notistack';
import ApprovedHosts from './screens/DesktopLayout/main/hosts/ApprovedHosts';
import apiService from './services/apiService';
import logger from "./utils/logger";
import Toaster from './components/Settings/alerts/Toaster';
import { enableAutoPageviews, enableAutoOutboundTracking } from './utils/plausible';

const { store, persistor } = configureStore();

const App = ({ browserSupport }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    logger.log('[App] Browser support:', browserSupport);

    if (!browserSupport.hasServiceWorker) {
      setError({
        type: 'serviceWorker',
        browser: browserSupport.isFirefox ? 'Firefox' : 'this browser'
      });
      return;
    }

    // Initialize Plausible Analytics
    const cleanupPageviews = enableAutoPageviews();
    const cleanupOutboundTracking = enableAutoOutboundTracking();

    // Cleanup Plausible event listeners on component unmount
    return () => {
      cleanupPageviews();
      cleanupOutboundTracking();
    };
  }, [browserSupport]);

  if (error) {
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        padding: '20px'
      }}>
        <div style={{
          width: '100%',
          maxWidth: '600px',
          background: 'white',
          borderRadius: '20px',
          padding: '40px',
          boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
          textAlign: 'center'
        }}>
          <h1 style={{
            fontSize: '28px',
            fontWeight: '600',
            color: '#1a1a1a',
            marginBottom: '10px'
          }}>
            Service Worker Not Supported
          </h1>
          <p style={{
            fontSize: '16px',
            color: '#666',
            maxWidth: '400px',
            lineHeight: '1.6'
          }}>
            Some features may not work properly in this browser mode. Please use a regular window for the best experience.
          </p>
        </div>
      </div>
    );
  }

  // Normal render
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={3000}
          maxSnack={2}
        >
          {isMobile ? <MobileLayout /> : <DesktopLayout />}
        </SnackbarProvider>
      </PersistGate>
      <Toaster />
    </Provider>
  );
};

export default App;

 // https://sandbox.spacia.page/users/v1.1/process/payment/response?references=GTDKMLUC
// add user id to subscription callback. BookingOrderSummary

