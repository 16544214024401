import {
    BsPerson,
    BsPersonFill,
    BsBag,
    BsInbox,
    BsClock,
    BsBox,
    BsCheckCircle,
    BsBell,
    BsPhone,
    BsGear,
    BsSearch,
    BsHouseDoor,
    BsCalendar,
    BsCollection
} from "react-icons/bs";
import { AiFillProfile } from "react-icons/ai";
import { MdAnnouncement, MdPhoneIphone } from "react-icons/md";
import { FaCheckCircle, FaHourglassHalf } from "react-icons/fa";
import React from "react";

export class Constants {
    static sidebarMenus = [
        {
            icon: 'dashboard.svg',
            name: "Dashboard",
            route: "/dashboard",
            restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_ADMINISTRATOR', 'ROLE_HOST']
        },
        {
            icon: 'property-location.svg',
            name: "Properties",
            route: "/properties",
            restricted_to: ['ROLE_ADMINISTRATOR', 'ROLE_HOST', 'ROLE_HOST_OPERATIONS']
        },
        {
            icon: 'find-a-space.svg',
            name: "Find a Space",
            route: "/startBooking",
            restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_SUBSCRIBER_APPROVER', 'ROLE_SUBSCRIBER_INDIVIDUAL', 'ROLE_EMPLOYEE', 'ROLE_APPROVER_EMPLOYEE']
        },
        {
            icon: 'calendar.svg',
            name: "Reservations",
            route: "/reservations",
            restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_SUBSCRIBER_APPROVER', 'ROLE_SUBSCRIBER_INDIVIDUAL', 'ROLE_EMPLOYEE', 'ROLE_APPROVER_EMPLOYEE']
        },
        // {
        // 	icon: 'calendar.svg',
        // 	name: "Booking",
        // 	route: "",
        //     restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_SUBSCRIBER_APPROVER', 'ROLE_SUBSCRIBER_INDIVIDUAL', 'ROLE_EMPLOYEE', 'ROLE_APPROVER_EMPLOYEE'],
        // 	children: [
        // 		{
        // 			name: "Find a Space",
        // 			route: "/startBooking",
        // 		},
        // 		{
        // 			name: "Reservations",
        // 			route: "/reservations",
        // 		}
        // 		// {
        // 		// 	name: "Pending Approvals",
        // 		// 	route: "/editbooking",
        // 		// },
        // 	],
        // },
        {
            icon: 'calendar.svg',
            name: "Reservations",
            route: "/reservations",
            restricted_to: ['ROLE_ADMINISTRATOR', 'ROLE_HOST', 'ROLE_HOST_OPERATIONS'],
        },
        {
            icon: 'users.svg',
            name: "Users",
            route: "/user-management",
            restricted_to: ['ROLE_SUBSCRIBER_OWNER', '', 'ROLE_HOST', 'ROLE_ADMINISTRATOR']
        },
        {
            icon: 'bill.svg',
            name: "Billing",
            route: "/host-billing",
            restricted_to: ['ROLE_HOST']
        },
        {
            icon: 'users.svg',
            name: "Hosts",
            route: "",
            restricted_to: ['ROLE_ADMINISTRATOR'],
            children: [
                {
                    name: "Pending",
                    route: "/companyApprovals",
                },
                {
                    name: "Approved",
                    route: "/approvedHosts",
                    style: this.menuItemStyle,
                    restricted_to: ['ROLE_ADMINISTRATOR']
                },
                {
                    name: "Disabled",
                    route: "/disabledHosts",
                },
            ],
        },
        {
            icon: 'membership-plan.svg',
            name: "Membership plan",
            route: "/membership-plan",
            restricted_to: ['ROLE_SUBSCRIBER_OWNER']
        },
        {
            icon: 'bill.svg',
            name: "Billing",
            route: "/billing",
            restricted_to: ['ROLE_SUBSCRIBER_OWNER', '']
        },
        {
            icon: 'bill.svg',
            name: "Orders",
            route: "/orders",
            restricted_to: ['ROLE_ADMINISTRATOR', '']
        },
        {
            icon: 'bill.svg',
            name: "Packages",
            route: "/packages",
            restricted_to: ['ROLE_ADMINISTRATOR', '']
        },
        {
            icon: 'chart.svg',
            name: "Announcements",
            route: "/announcements",
            restricted_to: ['ROLE_ADMINISTRATOR', '']
        },
        // {
        // 	icon: 'chart.svg',
        // 	name: "Reports",
        // 	route: "/report",
        //     restricted_to: ['ROLE_SUBSCRIBER_OWNER', 'ROLE_ADMINISTRATOR']
        // },
        {
            icon: 'settings.svg',
            name: "Settings",
            route: "/settings",
            restricted_to: [
                'ROLE_SUBSCRIBER_OWNER',
                'ROLE_SUBSCRIBER_APPROVER',
                'ROLE_SUBSCRIBER_INDIVIDUAL',
                'ROLE_HOST',
                'ROLE_EMPLOYEE',
                'ROLE_APPROVER_EMPLOYEE',
                'ROLE_ADMINISTRATOR'
            ]
        }
    ];

    static menuItemStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px',
        color: '#333',
        transition: 'all 0.2s ease',
        borderRadius: '12px',
        margin: '4px 8px',
        fontSize: '15px',
        fontWeight: 500,
    };

    static menuIconStyle = {
        width: '24px',
        height: '24px',
        marginRight: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    static mobileSidebarMenus = [
        // Landing Pages (No Auth Required)
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsHouseDoor size={20} color={'#666'} />
                </div>
            ),
            name: "Home",
            route: "/home",
            style: this.menuItemStyle,
            restricted_to: ['NO_AUTH']
        },
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsBag size={20} color={'#666'} />
                </div>
            ),
            name: "Enterprise Solutions",
            route: "/corporate-page",
            style: {
                ...this.menuItemStyle,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%'
            },
            restricted_to: ['NO_AUTH']
        },
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsPersonFill size={20} color={'#666'} />
                </div>
            ),
            name: "Host",
            route: "/mobile-host",
            style: this.menuItemStyle,
            restricted_to: ['NO_AUTH']
        },
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsInbox size={20} color={'#666'} />
                </div>
            ),
            name: "FAQs",
            route: "/frequently-asked-questions",
            style: this.menuItemStyle,
            restricted_to: ['NO_AUTH']
        },
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsPerson size={20} color={'#666'} />
                </div>
            ),
            name: "Sign In",
            route: "/signin",
            style: this.menuItemStyle,
            restricted_to: ['NO_AUTH']
        },
        // Search - For Individual, Owner, Employee, Approver Employee
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsSearch size={20} color={'#666'} />
                </div>
            ),
            name: "Search",
            route: "/searchPage",
            style: this.menuItemStyle,
            restricted_to: [
                'ROLE_SUBSCRIBER_INDIVIDUAL',
                'ROLE_SUBSCRIBER_OWNER',
                'ROLE_EMPLOYEE',
                'ROLE_APPROVER_EMPLOYEE'
            ]
        },
        // Properties - For Host, Host sub-account, Administrator
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <img src={'/assets/img/Web/Spacia/property-location-mobile.svg'} alt="Property Icon" style={{ width: '20px', height: '20px' }} />
                </div>
            ),
            name: "Properties",
            route: "/properties",
            style: this.menuItemStyle,
            restricted_to: [
                'ROLE_HOST',
                'ROLE_HOST_OPERATIONS',
                'ROLE_ADMINISTRATOR'
            ]
        },
        // Reservations - For all roles
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsCalendar size={20} color={'#666'} />
                </div>
            ),
            name: "Reservations",
            route: "/reservation",
            style: this.menuItemStyle,
            restricted_to: [
                'ROLE_SUBSCRIBER_INDIVIDUAL',
                'ROLE_SUBSCRIBER_OWNER',
                'ROLE_EMPLOYEE',
                'ROLE_APPROVER_EMPLOYEE',
                'ROLE_HOST',
                'ROLE_HOST_OPERATIONS',
                'ROLE_ADMINISTRATOR'
            ]
        },
        // Users - For Host, Owner subscriber, Administrator
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <img src={'/assets/img/Web/Spacia/users-mobile.svg'} alt="User Icon" style={{ width: '20px', height: '20px' }} />
                </div>
            ),
            name: "Users",
            route: "/user-management",
            style: this.menuItemStyle,
            restricted_to: [
                'ROLE_HOST',
                'ROLE_SUBSCRIBER_OWNER',
                'ROLE_ADMINISTRATOR'
            ]
        },
        // Administrator-specific menus
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsBell size={20} color={'#666'} />
                </div>
            ),
            name: "Announcements",
            route: "/mobile/announcements",
            style: this.menuItemStyle,
            restricted_to: ['ROLE_ADMINISTRATOR']
        },
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <MdAnnouncement size={20} color={'#666'} />
                </div>
            ),
            name: "Packages",
            route: "/mobile/packages",
            style: this.menuItemStyle,
            restricted_to: ['ROLE_ADMINISTRATOR']
        },
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsCollection size={20} color={'#666'} />
                </div>
            ),
            name: "Orders",
            route: "/mobile/orders",
            style: this.menuItemStyle,
            restricted_to: ['ROLE_ADMINISTRATOR']
        },
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsPerson size={20} color={'#666'} />
                </div>
            ),
            name: "Hosts",
            route: "/mobile/hosts",
            style: this.menuItemStyle,
            restricted_to: ['ROLE_ADMINISTRATOR'],
            subMenus: [
                {
                    icon: (
                        <div style={this.menuIconStyle}>
                            <BsCheckCircle size={20} color={'#666'} />
                        </div>
                    ),
                    name: "Approved",
                    route: "/mobile/hosts/approved",
                    style: this.menuItemStyle,
                    restricted_to: ['ROLE_ADMINISTRATOR']
                },
                {
                    icon: (
                        <div style={this.menuIconStyle}>
                            <BsClock size={20} color={'#666'} />
                        </div>
                    ),
                    name: "Pending",
                    route: "/mobile/hosts/pending",
                    style: this.menuItemStyle,
                    restricted_to: ['ROLE_ADMINISTRATOR']
                },
                {
                    icon: (
                        <div style={this.menuIconStyle}>
                            <BsCollection size={20} color={'#666'} />
                        </div>
                    ),
                    name: "Disabled",
                    route: "/mobile/hosts/disabledHosts",
                    style: this.menuItemStyle,
                    restricted_to: ['ROLE_ADMINISTRATOR']
                }
            ]
        },
        // Settings - For all roles
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsGear size={20} color={'#666'} />
                </div>
            ),
            name: "Settings",
            route: "/profile",
            style: this.menuItemStyle,
            restricted_to: [
                'ROLE_SUBSCRIBER_INDIVIDUAL',
                'ROLE_SUBSCRIBER_OWNER',
                'ROLE_EMPLOYEE',
                'ROLE_APPROVER_EMPLOYEE',
                'ROLE_HOST',
                'ROLE_HOST_OPERATIONS',
                'ROLE_ADMINISTRATOR'
            ]
        },
        // Notifications - For all roles
        {
            icon: (
                <div style={this.menuIconStyle}>
                    <BsBell size={20} color={'#666'} />
                </div>
            ),
            name: "Notifications",
            route: "/notifications",
            style: this.menuItemStyle,
            restricted_to: [
                'ROLE_SUBSCRIBER_INDIVIDUAL',
                'ROLE_SUBSCRIBER_OWNER',
                'ROLE_EMPLOYEE',
                'ROLE_APPROVER_EMPLOYEE',
                'ROLE_HOST',
                'ROLE_HOST_OPERATIONS',
                'ROLE_ADMINISTRATOR'
            ]
        },
    ];
    static BASE_URL = process.env.REACT_APP_BASE_URL || "https://app.spacia.africa/";
    static GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "AIzaSyBfJEWn4YSHm-v-JvTB_y0-XdkGc-Ys6UE";
    // Also use the Google API key for Gemini API
    static GEMINI_API_KEY = process.env.REACT_APP_GEMINI_API_KEY || process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "AIzaSyBfJEWn4YSHm-v-JvTB_y0-XdkGc-Ys6UE";
    static GUEST_API_URL = process.env.REACT_APP_GUEST_API_URL;

    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    static firebaseConfig = {
        apiKey: "AIzaSyBQQmGg2svzQTx_c2Op5q046-wFhcOSt0A",
        authDomain: "spacia-16ab5.firebaseapp.com",
        projectId: "spacia-16ab5",
        storageBucket: "spacia-16ab5.appspot.com",
        messagingSenderId: "1053152375145",
        appId: "1:1053152375145:web:2773e95b7024c05f3b4df8",
        measurementId: "G-FTF1GB80TL"
    };

    static getMenusByRole(role) {
        return this.sidebarMenus.filter((d) => d.restricted_to.includes(role));
    }

    static getMenusForMobileByRole(role) {
        return this.mobileSidebarMenus.filter((d) => d.restricted_to.includes(role));
    }

    static hideCard = process.env.REACT_APP_HIDE_CARD;
    static hideMobileWalletAccount = process.env.REACT_APP_HIDE_NEW_MOBILE_ACCOUNT;
    static paymentProcessingTimeout = process.env.REACT_APP_PAYMENT_PROCESSING_TIMEOUT;
    static maxFileSizeLimit = process.env.REACT_APP_MAX_FILE_SIZE_LIMIT;
    static paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
}
