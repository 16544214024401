import Plausible from 'plausible-tracker';

// Extract domain from environment variables based on environment
const getDomain = () => {
    const envBaseUrl = process.env.REACT_APP_BASE_URL || '';

    // Extract domain from URL
    if (envBaseUrl) {
        try {
            const url = new URL(envBaseUrl);
            return url.hostname;
        } catch (e) {
            console.error('Error parsing BASE_URL:', e);
        }
    }

    // Fallback based on environment
    if (process.env.NODE_ENV === 'production') {
        return 'app.spacia.africa';
    }
    return 'sandbox.spacia.page';
};

// Initialize Plausible instance for cases where we need the npm package features
// but primarily rely on the script tag for tracking
const plausibleInstance = Plausible({
    domain: getDomain(),
    trackLocalhost: true,
    hashMode: true, // Enable hash tracking to match the script
});

console.log(`[Plausible] Initialized with domain: ${getDomain()}`);

/**
 * Check if the script-based Plausible is available (window.plausible)
 * @returns {boolean} True if script-based Plausible is available
 */
const isScriptPlausibleAvailable = () => {
    return typeof window !== 'undefined' && typeof window.plausible === 'function';
};

/**
 * Track a page view
 * @param {Object} options - Options to override the default tracker configuration
 * @param {Object} eventOptions - Event-specific options like callbacks
 * @returns {void}
 */
export const trackPageview = (options = {}, eventOptions = {}) => {
    console.log(`[Plausible] Tracking pageview: ${window.location.pathname}`);

    // Use the script-based Plausible if available, otherwise use the npm package
    if (isScriptPlausibleAvailable()) {
        window.plausible('pageview', {
            props: eventOptions.props || {},
            callback: eventOptions.callback
        });
    } else {
        plausibleInstance.trackPageview(options, eventOptions);
    }
};

/**
 * Track a custom event
 * @param {string} eventName - Name of the event to track
 * @param {Object} eventOptions - Event options including props
 * @param {Object} options - Options to override the default tracker configuration
 * @returns {void}
 */
export const trackEvent = (eventName, eventOptions = {}, options = {}) => {
    console.log(`[Plausible] Tracking event: ${eventName}`, eventOptions);

    // Use the script-based Plausible if available, otherwise use the npm package
    if (isScriptPlausibleAvailable()) {
        window.plausible(eventName, {
            props: eventOptions.props || {},
            callback: eventOptions.callback
        });
    } else {
        plausibleInstance.trackEvent(eventName, eventOptions, options);
    }
};

/**
 * Track page navigation - just a placeholder since script handles this
 * @returns {Function} Cleanup function to remove event listeners
 */
export const enableAutoPageviews = () => {
    console.log('[Plausible] Auto pageviews enabled via script tag');

    // The script already handles auto pageviews, so this is a no-op
    // Just return a dummy cleanup function
    return () => { };
};

/**
 * Track outbound link clicks - just a placeholder since script handles this
 * @returns {Function} Cleanup function to remove event listeners
 */
export const enableAutoOutboundTracking = () => {
    console.log('[Plausible] Outbound tracking enabled via script tag');

    // The script already handles outbound tracking, so this is a no-op
    // Just return a dummy cleanup function
    return () => { };
};

/**
 * Track specific user actions like form submissions, button clicks, etc.
 * @param {string} category - Category of the action (e.g., 'Booking', 'Listing')
 * @param {string} action - Action performed (e.g., 'Submit', 'View', 'Click')
 * @param {string} label - Label for the action (optional)
 * @param {Object} additionalProps - Any additional properties to track
 */
export const trackUserAction = (category, action, label = '', additionalProps = {}) => {
    const eventName = `${category}_${action}`;

    // Structure props according to Plausible's expected format
    const props = {
        label,
        environment: process.env.NODE_ENV,
        ...additionalProps
    };

    trackEvent(eventName, { props });
};

/**
 * Exclude current user from analytics
 * Sets localStorage.plausible_ignore to "true"
 */
export const excludeFromAnalytics = () => {
    localStorage.setItem('plausible_ignore', 'true');
    console.log('[Plausible] This browser is now excluded from analytics');
};

/**
 * Include current user in analytics
 * Removes localStorage.plausible_ignore
 */
export const includeInAnalytics = () => {
    localStorage.removeItem('plausible_ignore');
    console.log('[Plausible] This browser is now included in analytics');
};

/**
 * Check if current user is excluded from analytics
 * @returns {boolean} True if excluded, false otherwise
 */
export const isExcludedFromAnalytics = () => {
    return localStorage.getItem('plausible_ignore') === 'true';
};

/**
 * Add data-plausible-event attribute to an element to track it
 * This is a helper function to make using tagged events easier
 * @param {HTMLElement} element - The element to add the attribute to
 * @param {string} eventName - The name of the event to track
 * @param {Object} props - Additional properties to track with the event
 */
export const tagElementForTracking = (element, eventName, props = {}) => {
    if (!element) return;

    element.setAttribute('data-plausible-event', eventName);

    if (Object.keys(props).length > 0) {
        // Convert props to JSON and set as attribute
        element.setAttribute('data-plausible-event-props', JSON.stringify(props));
    }
};

// Add a developer toggle to window for easy toggling in console
if (process.env.NODE_ENV !== 'production') {
    window.togglePlausibleTracking = () => {
        if (isExcludedFromAnalytics()) {
            includeInAnalytics();
        } else {
            excludeFromAnalytics();
        }
    };
    console.log('[Plausible] Use window.togglePlausibleTracking() to toggle analytics tracking');
}

// Export the entire instance if needed for advanced usage
export default plausibleInstance; 