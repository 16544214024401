import React, { useState, useEffect } from "react";
import { Container, Tabs, Tab, Spinner } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { FaFileAlt, FaImages, FaMapMarkerAlt, FaList } from "react-icons/fa";
import "./styles.scss";

// Tab components
import DescriptionTab from "./DescriptionTab";
import MediaTab from "./MediaTab";
import LocationTab from "./LocationTab";
import AmenitiesTab from "./AmenitiesTab";

// Services and utilities
import * as SERVICES from "../../services/localService";
import apiService from "../../services/apiService";
import { ErrorAlert } from "../../components/Settings/alerts/ErrorAlert";
import logger from "../../utils/logger";
import Header from "../../components/Header";

const ListPropertyForm = () => {
    const history = useHistory();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("Description");
    const [loadingState, setLoadingState] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [requestHasErrors, setRequestHasErrors] = useState(false);
    const [uploadErrorModal, setUploadErrorModal] = useState(false);
    const [uploadErrorMsg, setUploadErrorMsg] = useState("");
    const [mediaLoadingState, setMediaLoadingState] = useState(false);
    const userCurrency = SERVICES.getCurrency();

    // State for property data
    const [formData, setFormData] = useState({
        // Description Tab
        propertyTitle: "",
        description: "",
        tags: [],
        price: "",
        billingPeriod: "HOURLY",

        // Details section
        propertySize: "1",
        floorNumber: "",
        capacity: "1",
        coWorkingSpaceNumber: "2",
        isCoWorkingSpaceChecked: false,
        listingType: "PUBLISHED",

        // Media Tab
        mediaId: 0,
        imagesArray: [],
        imageTitle: "",
        imageDescription: "",

        // Location Tab
        address: "",
        city: "",
        region: "",
        country: "Kenya",
        latitude: "",
        longitude: "",
        streetName: "",
        zone: [],

        // Amenities Tab
        selectedAmenities: {},
    });

    // --- Load stored data if available ---
    useEffect(() => {
        const locationState = location.state;
        const storedListingData = SERVICES.getListingData();

        const ownerIdFromLocationState = locationState ? locationState.ownerId : 0;
        const ownerIdFromStorage = storedListingData ? storedListingData.ownerId : 0;

        if (storedListingData &&
            ownerIdFromLocationState &&
            ownerIdFromStorage &&
            ownerIdFromLocationState === ownerIdFromStorage) {

            // Load data from storage into form state
            try {
                const descriptionObj = storedListingData.listingDescription;
                const priceObj = storedListingData.listingPrice;
                const detailsObj = storedListingData.listingDetails;
                const media = storedListingData.listingMedia;
                const geolocation = storedListingData.geolocation;

                setFormData(prevState => ({
                    ...prevState,
                    // Description
                    propertyTitle: descriptionObj.propertyTitle || "",
                    description: descriptionObj.description || "",
                    tags: descriptionObj.tags || [],

                    // Price
                    price: priceObj.price || "",
                    billingPeriod: priceObj.billingPeriod || "HOURLY",

                    // Details
                    propertySize: detailsObj.propertySize || "1",
                    floorNumber: detailsObj.floorNumber || "",
                    capacity: detailsObj.capacity || "1",
                    listingType: detailsObj.listingType || "PUBLISHED",

                    // Media
                    mediaId: media?.mediaId || 0,
                    imageTitle: media?.imageTitle || "",
                    imageDescription: media?.imageDescription || "",

                    // Location
                    address: geolocation?.address || "",
                    city: geolocation?.city || "",
                    region: geolocation?.region || "",
                    country: geolocation?.country || "Kenya",
                    latitude: geolocation?.latitude || "",
                    longitude: geolocation?.longitude || "",
                    zone: geolocation?.zone || [],

                    // Amenities
                    selectedAmenities: storedListingData.selectedAmenities || {},
                }));

                // Handle images separately as they need special processing
                if (media && media.images) {
                    const bufferImages = [];
                    Promise.all(
                        Array.from(media.images).map(async (image) => {
                            const url = image.blob;
                            const id = image.id;
                            try {
                                const file = await dataUrlToFile(url, id);
                                bufferImages.push({ ...image, file });
                            } catch (error) {
                                logger.error("Error processing image", error);
                            }
                        })
                    ).finally(() => {
                        updateFormData("imagesArray", bufferImages);
                    });
                }
            } catch (error) {
                logger.error("Error loading stored data", error);
            }
        }
    }, []);

    // Helper function to convert data URL to file
    const dataUrlToFile = async (dataUrl, fileName) => {
        const res = await fetch(dataUrl);
        const blob = await res.blob();
        return new File([blob], fileName, { type: "image/png" });
    };

    // --- Save form data to local storage ---
    useEffect(() => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        const data = {
            ownerId: userId,
            listingDescription: {
                propertyTitle: formData.propertyTitle,
                description: formData.description,
                tags: formData.tags,
            },
            listingPrice: {
                price: formData.price,
                billingPeriod: formData.billingPeriod,
            },
            listingDetails: {
                propertySize: formData.propertySize,
                floorNumber: formData.floorNumber,
                capacity: formData.capacity,
                listingType: formData.listingType,
            },
            listingMedia: {
                mediaId: formData.mediaId,
                images: formData.imagesArray,
                imageTitle: formData.imageTitle,
                imageDescription: formData.imageDescription,
            },
            geolocation: {
                address: formData.address,
                city: formData.city,
                region: formData.region,
                zone: formData.zone,
                country: formData.country,
                latitude: formData.latitude,
                longitude: formData.longitude,
            },
            selectedAmenities: formData.selectedAmenities,
        };

        SERVICES.saveListingData(data);
    }, [formData]);

    // --- Function to update form data ---
    const updateFormData = (field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    // --- Function to update amenities ---
    const updateAmenities = (amenity, value) => {
        setFormData(prevState => ({
            ...prevState,
            selectedAmenities: {
                ...prevState.selectedAmenities,
                [amenity]: value
            }
        }));
    };

    // Helper to get tab status for progress indicators
    const getTabStatus = (tabKey) => {
        const tabOrder = ["Description", "Media", "Location", "Amenities"];
        const currentIndex = tabOrder.indexOf(activeTab);
        const tabIndex = tabOrder.indexOf(tabKey);

        if (tabKey === activeTab) return 'active';
        if (tabIndex < currentIndex) return 'completed';
        return '';
    };

    // --- Helper for eTypeOfSpace mapping ---
    const eTypeOfSpace = (space) => {
        if (space === "WORK") {
            return "OFFICE_SPACE";
        } else if (space === "Concierge") {
            return "SERVICE";
        } else if (space === "Stay" || space === "STAY") {
            return "RESIDENTIAL_SPACE";
        }
        return space;
    };

    // --- Helper for eAddSpec mapping ---
    const eAddSpec = (space) => {
        const mapping = {
            "Junior Suite": "JUNIOR_SUITE",
            "Standard": "STANDARD",
            "Executive Suite": "EXECUTIVE_SUITE",
            "1 Bedroom": "ONE_BEDROOM",
            "2 Bedroom": "TWO_BEDROOM",
            "3 Bedroom": "THREE_BEDROOM",
            "Presidential Suite": "PRESIDENTIAL_SUITE"
        };

        return mapping[space] || space?.toUpperCase();
    };

    // --- Helper for eWorkspace mapping ---
    const eWorkspace = (space) => {
        const mapping = {
            "Private Office": "PRIVATE_OFFICE",
            "Co-working Space": "HOT_DESK",
            "Serviced Apartments": "SERVICED_APARTMENTS",
            "Hotel": "HOTEL",
            "Unfurnished": "UNFURNISHED",
            "Meeting Room": "MEETING_AND_VENUE",
            "Complimentary Meeting Room": "COMPLIMENTARY_MEETING_ROOM",
            "Dedicated Desk": "DEDICATED_DESK",
            "Bed & Breakfast": "BED_AND_BREAKFAST",
            "Homestays": "HOMESTAYS",
        };

        return mapping[space] || space;
    };

    // --- Helper for eCountry mapping ---
    const eCountry = (country) => {
        const mapping = {
            "Kenya": "ke",
            "Nigeria": "ng",
            "Ghana": "gh"
        };

        return mapping[country] || country;
    };

    // --- Function to submit media ---
    const submitMedia = async () => {
        try {
            if (!formData.imageTitle) {
                setUploadErrorModal(true);
                setUploadErrorMsg("Please add an image title");
                setActiveTab("Media");
                return false;
            }

            if (formData.imagesArray.length === 0) {
                setUploadErrorModal(true);
                setUploadErrorMsg("Please select at least one image");
                setActiveTab("Media");
                return false;
            }

            const formDataObj = new FormData();
            formDataObj.append("title", formData.imageTitle);
            formDataObj.append("description", formData.imageDescription);

            for (let i = 0; i < formData.imagesArray.length; i++) {
                formDataObj.append("files", formData.imagesArray[i].file);
            }

            // Always append empty video ID and YOUTUBE as upload source
            formDataObj.append("embedVideoId", "");
            formDataObj.append("uploadFrom", "YOUTUBE");

            setMediaLoadingState(true);
            const userId = SERVICES.getUser().id;

            const response = await (formData.mediaId
                ? apiService.updateListingMediaWithFormData(formData.mediaId, formDataObj, userId)
                : apiService.createListingMediaWithFormData(formDataObj, userId));

            const newMediaId = response.data.data[0].id;

            // Update the media ID
            updateFormData("mediaId", newMediaId);

            setMediaLoadingState(false);
            return true;
        } catch (error) {
            setMediaLoadingState(false);
            setUploadErrorModal(true);
            setUploadErrorMsg(error.response?.data?.message || "Error uploading media");
            logger.error("Media upload error", error);
            return false;
        }
    };

    // --- Function to collect amenities ---
    const collectAmenities = () => {
        const amenities = [];
        const amenityMapping = {
            access: "24HrAccess",
            disableFriendly: "DisabledFriendly",
            loungeSpace: "LoungeSpace",
            kitchenFacilities: "KitchenFacilities",
            mothersRoom: "MothersRoom",
            onsiteDining: "OnsiteDining",
            onSiteParking: "OnsiteParking",
            outdoorspace: "OutdoorSpace",
            printingServices: "PrintingServices",
            vcFacilities: "VCFacilities",
            whiteboard: "Whiteboard",
            bicycleRacks: "BicycleRacks",
            storage: "Storage",
            cancelation: "CancelationFlexibility",
            pool: "SwimmingPools",
            airportshuttle: "AirportShuttle",
            spa: "Spa",
            nonsmokingrooms: "NonSmokingRooms",
            frontview: "CityView",
            restaurant: "Restaurant",
            freewifi: "FreeWiFi",
            coffeemaker: "CoffeeMakerInRoom",
            bar: "Bar",
            lifts: "Lifts",
            food: "BreakfastLunchDinner",
            parking: "AmpleParking",
            rooms: "MeetingRooms",
            washingmachine: "WashingMachine",
            gym: "Gym",
            garbage: "GarbageCollection",
            laundry: "LaundryServices",
            housekeeping: "Housekeeping",
            cctv: "CCTV",
            hotwater: "HotWater",
            bills: "WaterAndElectricBills",
            cookers: "Cookers",
            security: "24HourSecurity",
            generator: "StandbyBackupGenerator",
            dstv: "TV",
            fence: "ElectricFence"
        };

        Object.entries(formData.selectedAmenities).forEach(([key, value]) => {
            if (value && amenityMapping[key]) {
                amenities.push(amenityMapping[key]);
            }
        });

        return amenities;
    };

    // --- Function to submit the entire form ---
    const submitForm = async () => {
        try {
            // Reset states
            setErrorAlert(false);
            setErrorMsg("");
            setUploadErrorModal(false);
            setUploadErrorMsg("");
            setLoadingState(true);

            // Validate property size
            if (formData.propertySize && parseInt(formData.propertySize) < 1) {
                const propertySizeInput = document.getElementById('propertySize');
                if (propertySizeInput) propertySizeInput.reportValidity();
                setActiveTab("Description");
                setLoadingState(false);
                return;
            }

            // Validate capacity
            if (formData.capacity && parseInt(formData.capacity) < 1) {
                const capacityInput = document.getElementById('capacity');
                if (capacityInput) capacityInput.reportValidity();
                setActiveTab("Description");
                setLoadingState(false);
                return;
            }

            // Validate co-working space slots if checked
            if (formData.isCoWorkingSpaceChecked) {
                const slotsInput = document.getElementById('availableSlots');
                if (slotsInput && !slotsInput.checkValidity()) {
                    slotsInput.reportValidity();
                    setActiveTab("Description");
                    setLoadingState(false);
                    return;
                }
            }

            // Media validation and submission
            const mediaSuccess = await submitMedia();
            if (!mediaSuccess) {
                setLoadingState(false);
                return;
            }

            // Final listing submission
            await submitFinalForm();

        } catch (error) {
            setLoadingState(false);
            setErrorAlert(true);
            setRequestHasErrors(true);
            setErrorMsg(error.message || "An error occurred during submission");
        }
    };

    // --- Function to submit the final form with collected data ---
    const submitFinalForm = async () => {
        const currentUser = SERVICES.getUser();

        try {
            const amenities = collectAmenities();

            const response = await apiService.createListing({
                additionalSpec: eAddSpec(localStorage.getItem("addSpec")),
                description: formData.description,
                mediaId: formData.mediaId,
                details: {
                    capacity: formData.capacity,
                    floorNumber: formData.floorNumber,
                    propertySize: formData.propertySize,
                    maxCapacity: !formData.isCoWorkingSpaceChecked || formData.coWorkingSpaceNumber < 2
                        ? 1
                        : formData.coWorkingSpaceNumber,
                },
                zone: formData.zone && formData.zone.length ? formData.zone : null,
                location: {
                    address: formData.address,
                    city: formData.city,
                    country: eCountry(formData.country),
                    latitude: formData.latitude,
                    longitude: formData.longitude,
                    streetName: formData.streetName,
                },
                propertyPrice: {
                    billingPeriod: formData.billingPeriod,
                    price: formData.price,
                },
                propertyStatus: "FOR_RENT",
                listingType: formData.listingType.toLowerCase(),
                propertyTitle: formData.propertyTitle,
                propertyType: eTypeOfSpace(localStorage.getItem("propertyType")),
                tags: formData.tags,
                typeOfWorkspace: eWorkspace(localStorage.getItem("Workspace")),
                userId: currentUser.id,
                amenities: amenities,
                media: {
                    images: formData.imagesArray,
                    imageTitle: formData.imageTitle,
                    imageDescription: formData.imageDescription,
                    videoOption: {
                        uploadFrom: "YOUTUBE",
                        videoLink: "",
                    },
                },
            });

            // Handle success
            const data = response.data.data;
            const addedListing = data ? data[0] : null;
            const tagsFromResponse = addedListing ? addedListing.tags : [];

            // Update keywords meta
            updateKeywordsMeta(tagsFromResponse);

            // Clear stored data if needed
            clearStoredData();

            // Redirect to properties page
            SERVICES.setnewProperty(true);
            history.push(`/properties`, { from: "successful" });

        } catch (error) {
            setLoadingState(false);
            setErrorAlert(true);
            setRequestHasErrors(true);
            setErrorMsg(error.response?.data?.message || "Failed to submit listing");
            throw new Error(error.response?.data?.message || "Failed to submit form");
        }
    };

    // --- Helper function to update keywords meta ---
    const updateKeywordsMeta = (tagsFromResponse) => {
        const keywordsMeta = document.querySelector(`meta[name='keywords']`);
        if (keywordsMeta) {
            const content = keywordsMeta.content;
            const tagTexts = tagsFromResponse
                ? tagsFromResponse.map((tag) => tag.text)
                : "";
            const existingKeywords = content ? content.split(",") : "";
            const tags = new Set([...existingKeywords, ...tagTexts]);
            keywordsMeta.content = Array.from(tags).join(",");
        }
    };

    // --- Helper function to clear stored data ---
    const clearStoredData = () => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;
        const storedListingData = SERVICES.getListingData();
        const listingOwnerId = storedListingData ? storedListingData.ownerId : 0;

        if (userId && listingOwnerId && userId === listingOwnerId) {
            SERVICES.clearListingData();
        }
    };

    // --- Function to navigate to properties page ---
    const moveToPropertiesPage = () => {
        clearStoredData();
        history.push("/properties");
    };

    const handleTabSelect = (key) => {
        // Add validation as needed before changing tabs
        setActiveTab(key);
    };

    const handleNext = () => {
        const tabOrder = ["Description", "Media", "Location", "Amenities"];
        const currentIndex = tabOrder.indexOf(activeTab);

        if (currentIndex < tabOrder.length - 1) {
            setActiveTab(tabOrder[currentIndex + 1]);
        }
    };

    const handleBack = () => {
        const tabOrder = ["Description", "Media", "Location", "Amenities"];
        const currentIndex = tabOrder.indexOf(activeTab);

        if (currentIndex > 0) {
            setActiveTab(tabOrder[currentIndex - 1]);
        }
    };

    return (
        <div className="list-property-form">
            <ErrorAlert
                message={errorMsg || "An error occurred while adding listing. Please try again!"}
                show={errorAlert}
                close={() => setErrorAlert(false)}
            />

            <div className="header-container">
                <Header title="List a Property" />
            </div>

            <Container>
                <div className="tab-container">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                        className="mb-4"
                    >
                        <Tab
                            eventKey="Description"
                            title={
                                <div className={`nav-item ${getTabStatus('Description')}`}>
                                    <div className="nav-link">
                                        <div className="step-indicator">
                                            <FaFileAlt />
                                            <div className="step-number">1</div>
                                        </div>
                                        <span>Description</span>
                                    </div>
                                </div>
                            }
                        >
                            <DescriptionTab
                                formData={formData}
                                updateFormData={updateFormData}
                                userCurrency={userCurrency}
                                onNext={handleNext}
                            />
                        </Tab>

                        <Tab
                            eventKey="Media"
                            title={
                                <div className={`nav-item ${getTabStatus('Media')}`}>
                                    <div className="nav-link">
                                        <div className="step-indicator">
                                            <FaImages />
                                            <div className="step-number">2</div>
                                        </div>
                                        <span>Media</span>
                                    </div>
                                </div>
                            }
                        >
                            <MediaTab
                                formData={formData}
                                updateFormData={updateFormData}
                                mediaLoadingState={mediaLoadingState}
                                uploadErrorModal={uploadErrorModal}
                                uploadErrorMsg={uploadErrorMsg}
                                setUploadErrorModal={setUploadErrorModal}
                                setUploadErrorMsg={setUploadErrorMsg}
                                onNext={handleNext}
                                onBack={handleBack}
                            />
                        </Tab>

                        <Tab
                            eventKey="Location"
                            title={
                                <div className={`nav-item ${getTabStatus('Location')}`}>
                                    <div className="nav-link">
                                        <div className="step-indicator">
                                            <FaMapMarkerAlt />
                                            <div className="step-number">3</div>
                                        </div>
                                        <span>Location</span>
                                    </div>
                                </div>
                            }
                        >
                            <LocationTab
                                formData={formData}
                                updateFormData={updateFormData}
                                onNext={handleNext}
                                onBack={handleBack}
                            />
                        </Tab>

                        <Tab
                            eventKey="Amenities"
                            title={
                                <div className={`nav-item ${getTabStatus('Amenities')}`}>
                                    <div className="nav-link">
                                        <div className="step-indicator">
                                            <FaList />
                                            <div className="step-number">4</div>
                                        </div>
                                        <span>Amenities</span>
                                    </div>
                                </div>
                            }
                        >
                            <AmenitiesTab
                                formData={formData}
                                updateAmenities={updateAmenities}
                                updateFormData={updateFormData}
                                loadingState={loadingState}
                                mediaLoadingState={mediaLoadingState}
                                requestHasErrors={requestHasErrors}
                                submitForm={submitForm}
                                moveToPropertiesPage={moveToPropertiesPage}
                                onBack={handleBack}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </Container>
        </div>
    );
};

export default ListPropertyForm; 