import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import toastUtils from '../../../utils/toast';
import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {Object} SuccessAlertProps
 * @property {boolean} show - Whether the alert is visible
 * @property {string} message - The success message to display
 * @property {Function} close - Function to close the alert
 * @property {Object} [alertStyle] - Optional styles for the alert
 * @property {string} [title] - Optional title for the alert
 */

/**
 * Success Alert component that shows toast notifications
 * @type {React.ForwardRefExoticComponent<SuccessAlertProps & React.RefAttributes<any>>}
 */
// Using forwardRef to allow parent components to control this component
export const SuccessAlert = forwardRef(({ show, message, close, alertStyle, title }, ref) => {
  // Expose methods to parent components if needed
  useImperativeHandle(ref, () => ({
    closeAlert: () => close(false)
  }));

  useEffect(() => {
    if (show && message) {
      // Generate a truly unique ID using UUID v4
      const toastId = `success-${uuidv4()}`;

      // Show the toast when the component becomes visible
      toastUtils.success(title || 'Success', {
        id: toastId, // Use the unique ID to ensure stacking works
        description: message,
        action: {
          label: 'Close',
          onClick: () => close(false),
        },
        // Make sure to include callbacks to clear the show state
        onAutoClose: () => close(false),
        onDismiss: () => close(false),
      });
    }
  }, [show, message, close, title]);

  // Return null as we're using Sonner's toast system instead of rendering in place
  return null;
});

// Add display name for debugging
SuccessAlert.displayName = 'SuccessAlert';

export default SuccessAlert;
