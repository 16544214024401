import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import { FaMapMarkerAlt, FaSearchLocation, FaMapPin, FaGlobe } from "react-icons/fa";
import { Constants } from "../../utils/constants";
import logger from "../../utils/logger";

const LocationTab = ({ formData, updateFormData, onNext, onBack }) => {
    const [shapes, setShapes] = useState([]);
    const [marker, setMarker] = useState(null);
    const mapRef = useRef(null);
    const mapsRef = useRef(null);
    const autocompleteRef = useRef(null);

    const defaultCenter = {
        lat: -1.286389,
        lng: 36.817223,
    };

    const handleApiLoaded = (map, maps) => {
        // Store references
        mapRef.current = map;
        mapsRef.current = maps;

        const Geocoder = new maps.Geocoder();

        maps.Polygon.prototype.Contains = function (point) {
            let crossings = 0,
                path = this.getPath();

            if (!path) {
                return false;
            }

            // for each edge
            for (let i = 0; i < path.getLength(); i++) {
                let a = path.getAt(i),
                    j = i + 1;
                if (j >= path.getLength()) {
                    j = 0;
                }

                const b = path.getAt(j);
                if (rayCrossesSegment(point, a, b)) {
                    crossings++;
                }
            }

            // odd number of crossings?
            return crossings % 2 == 1;

            function rayCrossesSegment(point, a, b) {
                let px = point.lng(),
                    py = point.lat(),
                    ax = a.lng(),
                    ay = a.lat(),
                    bx = b.lng(),
                    by = b.lat();
                if (ay > by) {
                    ax = b.lng();
                    ay = b.lat();
                    bx = a.lng();
                    by = a.lat();
                }
                // alter longitude to cater for 180 degree crossings
                if (px < 0) {
                    px += 360;
                }
                if (ax < 0) {
                    ax += 360;
                }
                if (bx < 0) {
                    bx += 360;
                }

                if (py == ay || py == by) py += 0.00000001;
                if (py > by || py < ay || px > Math.max(ax, bx)) return false;
                if (px < Math.min(ax, bx)) return true;

                var red = ax != bx ? (by - ay) / (bx - ax) : Infinity;
                var blue = ax != px ? (py - ay) / (px - ax) : Infinity;
                return blue >= red;
            }
        };

        let newMarker = new maps.Marker({
            position: defaultCenter,
            map,
            title: "Location",
        });

        setMarker(newMarker);

        const placeMarker = (e) => {
            if (marker) {
                marker.setMap(null);
            }

            const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
            Geocoder.geocode({ location: latLng })
                .then((response) => {
                    if (response.results[0]) {
                        const result = response.results[0];
                        updateFormData("country",
                            result.address_components.find(
                                (o) => o.types.indexOf("country") > -1
                            )?.long_name || "Kenya"
                        );
                        updateFormData("city",
                            result.address_components.find(
                                (o) => o.types.indexOf("locality") > -1
                            )?.long_name || ""
                        );
                        updateFormData("region",
                            result.address_components.find(
                                (o) => o.types.indexOf("administrative_area_level_1") > -1
                            )?.long_name || ""
                        );
                        updateFormData("address",
                            `${e.placeName ? e.placeName + ", " : ""}${result.formatted_address} ${result.address_components.find(
                                (o) => o.types.indexOf("sublocality") > -1
                            )?.long_name || ""}`
                        );
                    }
                })
                .catch(error => {
                    logger.error("Geocoding error:", error);
                });

            updateFormData("latitude", latLng.lat);
            updateFormData("longitude", latLng.lng);

            const newMarker = new maps.Marker({
                position: latLng,
                map,
                title: "Location",
            });

            setMarker(newMarker);
        };

        map.addListener("click", (e) => {
            updateFormData("zone", []);
            placeMarker(e);
        });

        // Setup Places Autocomplete
        const input = document.getElementById("pac-input");

        if (input && maps.places) {
            const autocomplete = new maps.places.Autocomplete(input, {
                fields: ["formatted_address", "geometry", "name"],
                strictBounds: false,
                types: ["establishment", "geocode"],
            });

            autocompleteRef.current = autocomplete;

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();

                if (place?.geometry?.location) {
                    placeMarker({
                        placeName: place.name,
                        latLng: place.geometry.location
                    });

                    // If the place has a geometry, then present it on a map.
                    if (place.geometry.viewport) {
                        map.fitBounds(place.geometry.viewport);
                    } else {
                        map.setCenter(place.geometry.location);
                        map.setZoom(17);
                    }

                    let found = false;
                    shapes.forEach(({ zoneName, polygon }, i, arr) => {
                        if (polygon.Contains(place.geometry.location)) {
                            updateFormData("zone", zoneName);
                            found = true;
                        } else if (i === arr.length - 1 && !found) {
                            updateFormData("zone", []);
                        }
                    });
                }
            });
        } else {
            console.error("Places API not available or input element not found");
        }

        // Load zones from files
        const localShapes = [];
        const promises = [];
        for (let i = 1; i <= 12; ++i) {
            promises.push(
                new Promise((resolve, reject) => {
                    fetch(`/zones/Spacia Zone ${i}.txt`).then(function (response) {
                        let reader = response.body.getReader();
                        let decoder = new TextDecoder("utf-8");

                        reader.read().then(function (result) {
                            let fillColor = "#F0ADA5";
                            let strokeOpacity = 0.8;
                            let zoneName = [];

                            const latLngs = decoder
                                .decode(result.value)
                                .split("\n")
                                .map((line, i) => {
                                    if (i > 0) {
                                        const parts = line.split("\t");
                                        if (i === 1) {
                                            fillColor = parts[3] || fillColor;
                                            strokeOpacity = parts[4] || strokeOpacity;
                                            zoneName = (parts[6] || zoneName)
                                                .toUpperCase()
                                                .replace(/\s+/g, "_");
                                        }

                                        if (parts[1] && parts[2]) {
                                            return new maps.LatLng(
                                                Number(parts[1]),
                                                Number(parts[2])
                                            );
                                        }
                                    }
                                    return null;
                                });

                            const shape = {
                                zoneName,
                                polygon: new maps.Polygon({
                                    paths: latLngs.filter((o) => !!o),
                                    strokeColor: "#B4B2C0",
                                    strokeOpacity,
                                    strokeWeight: 2,
                                    fillColor,
                                    fillOpacity: 0.35,
                                }),
                            };

                            localShapes.push(shape);
                            resolve(shape);
                        });
                    }).catch(reject);
                })
            );
        }

        Promise.all(promises).then(() => {
            localShapes.forEach(({ zoneName, polygon }) => {
                maps.event.addListener(polygon, "click", function (e) {
                    if (marker) {
                        marker.setMap(null);
                    }

                    updateFormData("zone", zoneName);
                    placeMarker(e);
                });

                polygon.setMap(map);
            });

            setShapes(localShapes);
        }).catch(error => {
            logger.error("Error loading zones:", error);
        });
    };

    const eCountry = (country) => {
        if (country === "Kenya") {
            return "ke";
        } else if (country === "Nigeria") {
            return "ng";
        } else if (country === "Ghana") {
            return "gh";
        }
        return "ke"; // Default to Kenya
    };

    // Force the map to reinitialize if needed
    useEffect(() => {
        // Re-render the map if there were issues
        if (mapRef.current && !marker) {
            const map = mapRef.current;
            const maps = mapsRef.current;
            if (map && maps) {
                handleApiLoaded(map, maps);
            }
        }
    }, []);

    return (
        <Container>
            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaSearchLocation className="me-2" /> Search Address</h5>
                    <p>Find your property location on the map</p>
                </div>

                <div className="form-group">
                    <label className="form-label" htmlFor="address">
                        Address
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="pac-input"
                        placeholder="Search by location name or address on Google maps"
                        value={formData.address}
                        onChange={(e) => updateFormData("address", e.target.value)}
                    />
                    <small className="text-muted">Enter an address or click a location on the map below</small>
                </div>

                <div className="mapouter">
                    <div className="gmap_canvas">
                        <div id="map-container" style={{ height: "400px", width: "100%" }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: Constants.firebaseConfig.apiKey,
                                    libraries: ["places"],
                                }}
                                defaultCenter={defaultCenter}
                                defaultZoom={13}
                                options={{
                                    zoomControl: true,
                                    zoomControlOptions: {
                                        position: 9
                                    },
                                    fullscreenControl: false,
                                }}
                                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                yesIWantToUseGoogleMapApiInternals={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaMapPin className="me-2" /> Location Details</h5>
                    <p>Provide specific details about your property location</p>
                </div>

                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="city">
                                City
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="city"
                                placeholder="Enter city"
                                value={formData.city}
                                onChange={(e) => updateFormData("city", e.target.value)}
                            />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="region">
                                Region/Province
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="region"
                                placeholder="Enter region"
                                value={formData.region}
                                onChange={(e) => updateFormData("region", e.target.value)}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="zone">
                                Zone
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="zone"
                                placeholder="Zone will be set automatically"
                                value={formData.zone}
                                onChange={(e) => updateFormData("zone", e.target.value)}
                                readOnly
                            />
                            <small className="text-muted">Zone is determined automatically based on map location</small>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="country">
                                Country
                            </label>
                            <div className="selectCard" style={{ cursor: "pointer" }}>
                                <h6 className="flatText">{formData.country}</h6>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        style={{
                                            backgroundColor: "transparent",
                                            color: "black",
                                            border: "none",
                                        }}
                                        id="dropdown-basic"
                                    ></Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => updateFormData("country", "Ghana")}>
                                            Ghana
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => updateFormData("country", "Kenya")}>
                                            Kenya
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => updateFormData("country", "Nigeria")}>
                                            Nigeria
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaGlobe className="me-2" /> Geographic Coordinates</h5>
                    <p>These coordinates will be set automatically when you select a location on the map</p>
                </div>

                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="latitude">
                                Latitude
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="latitude"
                                placeholder="Latitude will be set automatically"
                                value={formData.latitude}
                                onChange={(e) => updateFormData("latitude", e.target.value)}
                            />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="longitude">
                                Longitude
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="longitude"
                                placeholder="Longitude will be set automatically"
                                value={formData.longitude}
                                onChange={(e) => updateFormData("longitude", e.target.value)}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="button-container">
                <button className="button-calm" onClick={onBack}>
                    Back
                </button>
                <button className="button ms-3" onClick={onNext}>
                    Next Step
                </button>
            </div>
        </Container>
    );
};

export default LocationTab; 