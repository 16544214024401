import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid"; // random uuid generator mostly used as a key
import { Container, Col, Row, Table, Modal, Button, Spinner, Form, Tabs, Tab, ListGroup, Badge, Dropdown } from "react-bootstrap"; // useful bootstrap components
import { default as ReactSelect } from "react-select";
import axios from "axios";
import moment from "moment-timezone";

import FilterTableRow from "../../components/FilterTableRow";
import FormModal from "../../components/NewPropertyModal";
import FeedbackModal from "../../components/FeedbackModall";
import Slide from "rc-slider";
import QuantityCounter from "../../components/QuantityCounter";
import demo from "../../assets/img/Web/Spacia/Rectangle 66.png";
import home1 from "../../assets/img/homes/home1.jpeg";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useDispatch, useSelector } from "react-redux";
import {
    saveCartDetails, saveSelectedFilters, setHasItems,
} from "../../redux/actions/dashboard";
import SERVICES from "../../services";
import { FilterPropertiesStyles } from "./FilterPropertiesStyles";
import { FaTimes, FaLandmark, FaHome, FaUser, FaCaretDown, FaTimesCircle, FaUsers, FaBuilding, FaUserPlus, FaCheck, FaInfo } from 'react-icons/fa';
import imgplaceholder from "../../assets/img/Web/Spacia/imgplaceholder.png";
import { Constants } from '../../utils/constants';
import { SuccessAlert } from "../../components/Settings/alerts/SuccessAlert";
import { ErrorAlert } from "../../components/Settings/alerts/ErrorAlert";
import Pagination from "../../components/Pagination";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "react-autocomplete";
import Utils from "../../utils";
import closeButton from "../../assets/img/svg/blue-close-button.svg";
import rejectedImage from "../../assets/img/png/rejected-image.png";
import { useHistory } from "react-router-dom";
import { Booking } from "../../utils/interfaces";
import debounce from "lodash.debounce";
import enGB from 'date-fns/locale/en-GB';
import apiService from "../../services/apiService";
import logger from "../../utils/logger";



const FilterProperties = (onAddToCart) => {
    const selectedFilters = useSelector(state => state.dashboard.selectedFilters); // all user selected filters
    const filters = useSelector(state => state.dashboard.filterOptions); // all filter options received from api
    const cartDetails = useSelector(state => state.dashboard.cartDetails);

    const [formModal, setformModal] = useState(false);

    const userCurrency = SERVICES.getCurrency();

    /* Define all states for the FilterProperties component
    *  before doing any other business */

    const [beds, setBeds] = useState("");
    const [baths, setBaths] = useState("");
    const [info, setInfo] = useState("");
    const [status, setStatus] = useState("Pending");
    const [price, setPrice] = useState('');

    const [propertyType, setPropertyType] = useState(null); // property type filter
    const [zone, setZone] = useState(null); // zone filter
    const [location, setLocation] = useState(null); // location filter

    const [allZones, setAllZones] = useState([]); // sets all supported zones received from api
    const [supportedZones, setSupportedZones] = useState([]);
    const [allLocations, setAllLocations] = useState([]); // sets all locations received from api

    const [capacity, setCapacity] = useState(1);
    const [maxPrice, setMaxPrice] = useState(selectedFilters.cost);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [properties, setProperties] = useState([]);
    const [startDate, setStartDate] = useState(selectedFilters.from);
    const [startDateObject, setStartDateObject] = useState(new Date());
    const [endDate, setEndDate] = useState(selectedFilters.to);
    const [endDateObject, setEndDateObject] = useState(new Date());

    const [optionSelected, setOptionSelected] = useState(null);
    const [workSpaceTypes, setWorkSpaceTypes] = useState([]);
    const [workspaceType, setWorkspaceType] = useState(null);
    const [amenitiesTypes, setAmenitiesTypes] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [allSelectedFiltersForSession, setAllSelectedFiltersForSession] = useState({});
    const [amenitiesClicked, setAmenitiesClicked] = useState(false);

    let [searchValue, setSearchValue] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [locationAddressList, setLocationAddressList] = useState([]);
    const [locationCityList, setLocationCityList] = useState([]);
    const [locationCountryList, setLocationCountryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [propertyNameList, setPropertyNameList] = useState([]);
    const [combinedLocationsMatch, setCombinedLocationsMatch] = useState([]);
    const [selectedLocationAndMatch, setSelectedLocationAndMatch] = useState('');

    const [errorMsg, setErrorMsg] = useState('');
    const [errorAlert, setErrorAlert] = useState(false);
    const [bookingErrorMsg, setBookingErrorMsg] = useState('');
    const [bookingErrorAlert, setBookingErrorAlert] = useState(false);
    const [bookingInitiatedErrorMsg, setBookingInitiatedErrorMsg] = useState('');
    const [bookingInitiatedErrorAlert, setBookingInitiatedErrorAlert] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorOnBooking, setErrorOnBooking] = useState('');

    const [currentUser, setCurrentUser] = useState(null);

    // Add new states for team booking
    const [bookingType, setBookingType] = useState("individual"); // "individual", "team", or "company"
    const [companyGroups, setCompanyGroups] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [myGroups, setMyGroups] = useState([]);
    const [groupMembers, setGroupMembers] = useState({});  // Store members by team ID
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [newTeamName, setNewTeamName] = useState("");
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [userSearchTerm, setUserSearchTerm] = useState("");
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const [showTeamMembersModal, setShowTeamMembersModal] = useState(false);
    const [showTeamConfirmModal, setShowTeamConfirmModal] = useState(false);
    const [isCreatingTeam, setIsCreatingTeam] = useState(false);
    // Add a new state variable for search loading
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    // Add a state to control dropdown visibility
    const [showSearchDropdown, setShowSearchDropdown] = useState(false);

    // Add success message state
    const [successAlert, setSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [isCreatingTeamLoading, setIsCreatingTeamLoading] = useState(false);
    const [isBookingLoading, setIsBookingLoading] = useState(false);

    useEffect(() => {
        const user = SERVICES.getUser();
        setCurrentUser(user);
    }, []);

    const history = useHistory();

    // initialize all filter states
    useEffect(() => {
        // set property type with selected property filter
        setPropertyType(selectedFilters.propertyType);
        // set workspace type with selected workspace filter
        logger.log('selected workspace type:', selectedFilters.workspaceType);
        setWorkspaceType(selectedFilters.workspaceType);
        // set maximum price
        setMaxPrice(selectedFilters.cost);
        // set capacity
        setCapacity(selectedFilters.capacity);
        // set booking start date
        setStartDate(selectedFilters.from);

        setSearchValue(selectedFilters.locationDisplayValue);

        setSelectedLocationAndMatch(selectedFilters.location);

        const selectedStartDateFilter = selectedFilters.from;
        if (selectedStartDateFilter != null && typeof selectedStartDateFilter === 'string') {
            setStartDateObject(new Date(selectedStartDateFilter));
        }

        // set booking end date
        setEndDate(selectedFilters.to);

        const selectedEndDateFilter = selectedFilters.to;
        if (selectedEndDateFilter != null && typeof selectedEndDateFilter === 'string') {
            setEndDateObject(new Date(selectedEndDateFilter));
        }

        // populate property type filter
        setPropertyTypes(filters['propertyTypes']);

        // populate location filter
        setAllLocations(filters['location']);

        // setAllSelectedFiltersForSession(selectedFilters);
    }, []);

    const propertySearchFunction = () => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        let selectedLocationFromFilter = selectedFilters.location;
        const splitLocationAndMatch = selectedLocationFromFilter?.split('~');
        let match = null, selectedLocation = null;

        if (splitLocationAndMatch && selectedFilters.locationDisplayValue?.trim().length > 0) {
            match = splitLocationAndMatch[0];
            selectedLocation = splitLocationAndMatch[1];
        }

        apiService.searchListings({
            cost: selectedFilters.cost,
            location: match ? { match, selectedLocation } : null,
            propertyType: selectedFilters.propertyType,
            subCategory: selectedFilters.workspaceType,
            amenities: selectedAmenities,
            start: selectedFilters.from,
            end: selectedFilters.to,
            zone
        }, { userId })
            .then(res => {
                if (res.status === 200) {
                    setRequestCompleted(true);
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);
                    setProperties(res.data.data || []);
                }
            })
            .catch(err => {
                setRequestCompleted(true);
                setProperties([]);
                logger.log(err);
            });
    };

    // make a search with user selected filters immediately the page loads
    useEffect(() => {
        // invoke property search function
        propertySearchFunction();
    }, []);

    // a function that saves all selected filters for use later
    const dispatchFilters = () => {
        const selectedFilters = {
            cost: maxPrice,
            location: selectedLocationAndMatch,
            locationDisplayValue: searchValue,
            propertyType,
            workspaceType,
            selectedAmenities,
            capacity,
            from: startDate,
            to: endDate
        };

        logger.log('selected filters here:', selectedFilters);

        // dispatch the selected filters with redux
        dispatch(saveSelectedFilters(selectedFilters));
    };

    // update supported zones anytime location state changes
    useEffect(() => {
        let city = null, country = null;
        if (location) {
            city = location.split(',')[0];
            country = location.split(',')[1];
        }
        // get all zones and filter zones based on city
        let zonesBasedOnLocation = [];
        if ((city && country) && (typeof city === "string" && typeof country === "string")) {
            city = city.toLowerCase();
            zonesBasedOnLocation = allZones.filter(zone => (zone.city.toLowerCase() === city) && (zone['countryInitial'] === country));
            logger.log("zones based on location:", zonesBasedOnLocation);
        }

        setSupportedZones(zonesBasedOnLocation);
        setZone(null);
    }, [location]);

    useEffect(() => {
        switch (propertyType) {
            case 'OFFICE_SPACE':
                setWorkspaceType('PRIVATE_OFFICE');
                break;

            case 'RESIDENTIAL_SPACE':
                setWorkspaceType('SERVICED_APARTMENTS');
                break;

            case 'SERVICE':
                setWorkspaceType('TRANSPORT');
                break;
        }
    }, [propertyType]);

    useEffect(() => {
        const selectedWorkspace = selectedFilters.workspaceType;

        logger.log('selected workspace here:', selectedWorkspace);
        logger.log('orig workspace type here:', workspaceType);

        if (!workspaceType && selectedWorkspace) {
            setWorkspaceType(selectedWorkspace);
        } else if (!!workspaceType) {
            setWorkspaceType(workspaceType);
        }
    }, [workspaceType]);

    useEffect(() => {
        const selectedFilters = {
            cost: maxPrice,
            // location,
            location: selectedLocationAndMatch,
            locationDisplayValue: searchValue,
            propertyType,
            workspaceType,
            capacity,
            selectedAmenities,
            zone,
            from: startDate,
            to: endDate
        };

        setAllSelectedFiltersForSession(selectedFilters);
    },
        [
            maxPrice,
            selectedLocationAndMatch,
            searchValue,
            propertyType,
            workspaceType,
            selectedAmenities,
            capacity,
            zone,
            startDate,
            endDate]);

    useEffect(() => {
        logger.log('the selected filter for property type:', propertyType);
        // setImage(ModalProp.media && ModalProp.media.images[0] ?
        //     ModalProp.media.images[0].resourceUrl :
        //     imgplaceholder )

    }, [propertyType]);

    const truncateDescription = (desc) => {
        if (desc.length > 55) {
            return desc.substring(0, 54) + '...';
        } else {
            return desc;
        }
    };

    const imgholder = {
        width: 100,
        height: 100,
        marginRight: 20,
        objectFit: 'cover'
    };

    const changeImage = (e) => {
        logger.log(e.target.src);
        setImage(e.target.src);
    };
    const [viewModal, setViewModal] = useState(false);

    const [ModalProp, setModalProp] = useState({
        "id": 93,
        "name": "Just added",
        "description": "string",
        "amenities": [
            { "label": "string" }
        ],
        "propertyPrice": {
            "id": 96,
            "price": 3000.0,
            "billingPeriod": "HOURLY",
            "createdOn": "2021-07-08T00:29:24.044Z",
            "updatedOn": "2021-07-08T00:29:24.090Z"
        },
        "location": {
            "id": 95,
            "latitude": "12345",
            "longitude": "-12345",
            "street": "50 3rd Road",
            "address": "50 3rd Road",
            "city": "Accra",
            "country": "gh"
        },
        "tags": [
            "string"
        ],
        "listingDetails": {
            "id": 94,
            "propertySize": 2,
            "floorNumber": "string",
            "capacity": 50,
            "listingId": 93
        },
        "status": {
            "ordinal": 1,
            "value": "unpublished"
        },
        "propertyStatus": {
            "ordinal": 0,
            "value": "FOR_RENT",
            "label": "FOR RENT"
        },
        "createdBy": {
            "id": 2,
            "username": "nicholas.akorful470@gmail.com",
            "firstName": "Nick",
            "lastName": "Lynx",
            "name": null,
            "userRole": "ROLE_CONTENT_PUBLISHER",
            "isEnabled": false,
            "createdOn": "2021-07-05T10:41:30.509Z",
            "updatedOn": "2021-07-08T00:29:24.232Z"
        },
        "createdOn": "2021-07-08T00:29:24.014Z",
        "updatedOn": "2021-07-08T00:29:24.014Z"
    });

    useEffect(() => {
        let media = ModalProp.media;
        let images = media && media.images;
        let imageAtIndex0 = (images && Array.isArray(images) && images.length > 0) ? images[0].resourceUrl : imgplaceholder;

        setImage(imageAtIndex0);
    }, [ModalProp]);

    const [image, setImage] = useState(null);

    const showViewModal = (listing) => {
        logger.log("Clicked");
        logger.log(listing);
        setViewModal(true);
        // Change to Object
        setModalProp(listing);
    };

    const proceedToCreateBooking = (listingId) => {
        resetTeamBooking();
        fetchGroups();
    };

    const addToCart = (listingId, amount) => {
        setViewModal(false);
        const currentUser = SERVICES.getUser();
        const id = currentUser ? currentUser.id : 0;
        const subTotal = amount * 1;
        const cartId = cartDetails?.cartId;

        apiService.addToCart({
            endOn: selectedFilters.to || '',
            itemPrice: amount,
            listingId,
            quantity: 1,
            startOn: selectedFilters.from || '',
            subTotal,
            userId: id
        }, { cartId })
            .then((res) => {
                setSuccessAlert(true);
                if (res.status === 200) {
                    const cartDetails = res.data.data[0];
                    dispatch(saveCartDetails(cartDetails));
                    dispatch(setHasItems(cartDetails !== null));
                    propertySearchFunction();
                }
            })
            .catch(err => {
                if (err.response?.status === 400) {
                    let responseMsg = err.response.data.message;
                    responseMsg = responseMsg.slice(responseMsg.indexOf(':') + 2);
                    setErrorAlert(true);
                    setErrorMsg(responseMsg);
                }
            });
    };

    const closeViewModal = () => {
        setViewModal(false);
    };

    const closeDeleteModal = () => {
        setshowDeleteModal(false);
    };

    const onAdd = (property) => {
        logger.log(property);
        setProperties([...properties, property]);
    };

    const closeFormModal = () => {
        setformModal(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (!info) {
            alert("Please add a task");
            return;
        }

        setformModal(false);
        const today = new Date();
        const added =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
        onAdd({ info, price, status, added, beds, baths });
        setInfo("");
    };

    const fit = {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: 20
    };

    const [showDeleteModal, setshowDeleteModal] = useState(false);

    const deleteProperty = (id) => {
        setProperties(properties.filter((property) => property.id !== id));
    };

    useEffect(() => {
        logger.log('Inside filter properties');
        logger.log(selectedFilters);
    }, []);

    const formatLocation = (location) => {
        const city = location && location.city;
        const country = location && location.country;

        let label = (country) ? country.label : '';
        label = label.charAt(0).toUpperCase() + label.slice(1);

        return `${city}, ${label.toUpperCase()}`;
    };

    const dispatch = useDispatch();

    const handleLocationChange = (e) => {
        const value = e.target.value;
        logger.log(value);

        const cityAndCountry = value.split(',');
        const [city, country] = cityAndCountry;

        const location = `${city},${country}`;
        setLocation(location);

        logger.log('city', city, 'country', country);
    };

    const handlePropertyTypeChange = (e) => {
        const selectedValue = e.target.value;
        logger.log('selected property type value is', selectedValue);

        setPropertyType(selectedValue);
    };

    const handleWorkspaceChange = (e) => {
        const selectedValue = e.target.value;
        logger.log('selected workspace value is', selectedValue);

        setWorkspaceType(selectedValue);
    };

    const clearAllCheckboxes = () => {
        const allAmenitiesCheckboxes = document.querySelectorAll('.amenities-check');
        allAmenitiesCheckboxes.forEach(checkbox => {
            checkbox.checked = false;
        });

        setSelectedAmenities(null);

        // amenitiesTypes.find(o => o.value === amenity.value).checked = e.target.checked;
        amenitiesTypes.forEach(amenity => {
            if ('checked' in amenity) {
                delete amenity.checked;
            }
        });
    };

    const searchForProperties = () => {
        logger.log('selected filters when search button is clicked:', allSelectedFiltersForSession);
        dispatchFilters();
        setAmenitiesClicked(false);
        setRequestCompleted(false);
        setProperties([]);

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        let selectedLocationFromFilter = allSelectedFiltersForSession.location;
        const splitLocationAndMatch = (selectedLocationFromFilter != null) ? selectedLocationFromFilter.split('~') : null;
        let match = null, selectedLocation = null;

        if (allSelectedFiltersForSession.locationDisplayValue != null && allSelectedFiltersForSession.locationDisplayValue.trim() && splitLocationAndMatch != null) {
            match = splitLocationAndMatch[0];
            selectedLocation = splitLocationAndMatch[1];
        }

        apiService.searchListings({
            amenities: allSelectedFiltersForSession.selectedAmenities || [],
            capacity: allSelectedFiltersForSession.capacity || 1,
            cost: allSelectedFiltersForSession.cost || 0,
            end: allSelectedFiltersForSession.to,
            location: {
                address: null,
                city: null,
                country: null,
                match: match,
                selectedLocation: selectedLocation || ""
            },
            propertyType: allSelectedFiltersForSession.propertyType,
            start: allSelectedFiltersForSession.from,
            subCategory: allSelectedFiltersForSession.workspaceType
        }, { userId })
            .then(res => {
                if (res.status === 200) {
                    setRequestCompleted(true);
                    const searchBasedOnFilters = res.data['data'];
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);
                    setProperties((searchBasedOnFilters) ? searchBasedOnFilters : []);
                    logger.log(res.data.data);
                } else {
                    setRequestCompleted(true);
                    setProperties([]);
                }
            })
            .catch(err => {
                setRequestCompleted(true);
                setProperties([]);
                logger.log(err);
            });
    };

    function currentDate(date) {
        let selectedDate = '';

        try {
            selectedDate = date.toISOString().substring(0, date.toISOString().lastIndexOf(':'));
        } catch (e) {
            logger.error('an error occurred while converting date to ISO string. message:', e);
        }

        return selectedDate;
    }

    const shouldUseDateTimePicker = () => {
        // Always use DateTimePicker for non-dedicated desks
        if (workspaceType !== 'DEDICATED_DESK') return true;

        // For dedicated desks, use DateTimePicker only for individual subscribers
        if (workspaceType === 'DEDICATED_DESK' && currentUser?.role === 'ROLE_SUBSCRIBER_INDIVIDUAL') return true;

        // Otherwise use DatePicker
        return false;
    };

    const handleStartDateChange = (newValue) => {
        const selectedStartDate = new Date(newValue);

        if (workspaceType === 'DEDICATED_DESK' && currentUser?.role !== 'ROLE_SUBSCRIBER_INDIVIDUAL') {
            selectedStartDate.setHours(8, 0, 0, 0);
            setStartDateObject(selectedStartDate);
            setStartDate(currentDate(selectedStartDate));

            const endDateTime = new Date(selectedStartDate);
            endDateTime.setDate(endDateTime.getDate() + 30);
            endDateTime.setHours(17, 0, 0, 0);
            setEndDateObject(endDateTime);
            setEndDate(currentDate(endDateTime));
        } else {
            setStartDateObject(selectedStartDate);
            setStartDate(currentDate(selectedStartDate));
        }
    };

    const handleEndDateChange = (dateValue) => {
        logger.log('current selected end date:', dateValue);
        const selectedEndDate = new Date(dateValue);
        setEndDateObject(selectedEndDate);

        const endDate = currentDate(selectedEndDate);

        logger.log('current date func:', endDate);

        setEndDate(endDate);
    };

    const retainSelectedStartDate = (date) => {
        setStartDateObject(prevState => {
            return prevState;
        });
    };

    const retainSelectedEndDate = (date) => {
        setEndDateObject(prevState => {
            return prevState;
        });
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);

    const pageCount = Math.ceil(totalPageSize / itemsPerPage);

    const changePage = (selectedPage) => {
        setRequestCompleted(false);
        setProperties([]);

        // Extract the selected page number from the parameter object
        // This handles both direct numbers and {selected: number} format
        const pageNumber = selectedPage.selected || selectedPage;

        logger.log('selected page number is', pageNumber);
        setCurrentPage(pageNumber);

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        let selectedLocationFromFilter = allSelectedFiltersForSession.location;
        const splitLocationAndMatch = (selectedLocationFromFilter != null) ? selectedLocationFromFilter.split('~') : null;
        let match = null, selectedLocation = null;

        if (splitLocationAndMatch != null && allSelectedFiltersForSession.locationDisplayValue != null && allSelectedFiltersForSession.locationDisplayValue.trim().length > 0) {
            match = splitLocationAndMatch[0];
            selectedLocation = splitLocationAndMatch[1];
        }

        apiService.searchListings({
            cost: allSelectedFiltersForSession.cost,
            capacity: allSelectedFiltersForSession.capacity,
            location: (match) ? { match, selectedLocation } : null,
            propertyType: allSelectedFiltersForSession.propertyType,
            subCategory: allSelectedFiltersForSession.workspaceType,
            amenities: allSelectedFiltersForSession.selectedAmenities,
            start: allSelectedFiltersForSession.from,
            end: allSelectedFiltersForSession.to,
            zone: allSelectedFiltersForSession.zone
        }, {
            page: (pageNumber - 1),
            userId
        })
            .then(res => {
                if (res.status === 200) {
                    setRequestCompleted(true);
                    const searchBasedOnFilters = res.data['data'];
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);
                    setProperties((searchBasedOnFilters) ? searchBasedOnFilters : []);
                    logger.log(res.data.data);
                } else {
                    setRequestCompleted(true);
                    setProperties([]);
                }
            })
            .catch(err => {
                setRequestCompleted(true);
                setProperties([]);
                logger.log(err);
            });
    };

    // get all supported zones
    useEffect(() => {
        apiService.getSupportedZones()
            .then(res => {
                logger.log('supported zones:', res.data);
                setAllZones(res.data.data);
            });
    }, []);

    useEffect(() => {
        switch (propertyType) {
            case 'OFFICE_SPACE':
                const officeSpaceSubCategories = (filters && filters['subCategoryTypes']) ? filters['subCategoryTypes'].filter(sub => sub.parent === propertyType) : [];
                logger.log(officeSpaceSubCategories);
                setWorkSpaceTypes(officeSpaceSubCategories);

                const officeAmenities = (filters && filters['amenities']) ? filters['amenities'].filter(amenity => amenity.category === propertyType) : [];
                setAmenitiesTypes(officeAmenities);
                break;

            case 'RESIDENTIAL_SPACE':
                const residentialSpaceSubCategories = (filters && filters['subCategoryTypes']) ? filters['subCategoryTypes'].filter(sub => sub.parent === propertyType) : [];
                setWorkSpaceTypes(residentialSpaceSubCategories);

                const residentialAmenities = (filters && filters['amenities']) ? filters['amenities'].filter(amenity => amenity.category === propertyType) : [];
                setAmenitiesTypes(residentialAmenities);
                break;

            case 'SERVICE':
                const serviceSubCategories = (filters && filters['subCategoryTypes']) ? filters['subCategoryTypes'].filter(sub => sub.parent === propertyType) : [];
                setWorkSpaceTypes(serviceSubCategories);

                const serviceAmenities = (filters && filters['amenities']) ? filters['amenities'].filter(amenity => amenity.category === propertyType) : [];
                setAmenitiesTypes(serviceAmenities);
                break;

            default:
                setWorkSpaceTypes([]);
                setAmenitiesTypes([]);
        }

        setOptionSelected([]);
        setSelectedAmenities([]);
    }, [propertyType]);

    const groupStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    };
    const groupBadgeStyles = {
        backgroundColor: "#EBECF0",
        borderRadius: "2em",
        color: "#172B4D",
        display: "inline-block",
        fontSize: 12,
        fontWeight: "normal",
        lineHeight: "1",
        minWidth: 1,
        padding: "0.16666666666667em 0.5em",
        textAlign: "center"
    };

    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const customStyles = {
        valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            width: "50px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "initial"
        })
    };

    const multiValueContainer = ({ selectProps, data }) => {
        const label = data.label;
        const allSelected = selectProps.value;
        const index = allSelected.findIndex(selected => selected.label === label);
        const isLastSelected = index === allSelected.length - 1;
        const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
        const val = `${label}${labelSuffix}`;
        return val;
    };

    const handleAmenitiesChange = (selected) => {
        setOptionSelected(selected);

        let selectedAmenities = [];
        if (selected.length > 0) {
            selectedAmenities = selected.map(amenity => amenity.value);
        }
        setSelectedAmenities(selectedAmenities);
    };

    const handleZoneChange = (e) => {
        const selectedZone = e.target.value;
        logger.log('selected zone is:', selectedZone);

        setZone(selectedZone);
    };

    useEffect(() => {
        logger.log('option selected:', optionSelected);
    }, [optionSelected]);

    const [doneCombiningLocations, setDoneCombiningLocations] = useState(false);
    useEffect(() => {
        setDoneCombiningLocations(false);

        const addressesInLocation = locationList.filter(location => location.match === 'ADDRESS')
            .map(location => {
                const { address, city, country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${address}, ${city}, ${countryName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct locations based on the address
        const uniqueAddressesInLocation = [...new Map(addressesInLocation.map((location) => [location["address"], location])).values()];

        const citiesInLocation = locationList.filter(location => location.match === 'CITY')
            .map(location => {
                const { city, country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${city}, ${countryName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct locations based on the city
        const uniqueCitiesInLocation = [...new Map(citiesInLocation.map((location) => [location["city"], location])).values()];

        const countriesInLocation = locationList.filter(location => location.match === 'COUNTRY')
            .map(location => {
                const { country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${countryName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct locations based on the country
        const uniqueCountriesInLocation = [...new Map(countriesInLocation.map((location) => {
            const country = location["country"];
            const label = country != null ? country.label : '';

            return [label, location];
        })).values()];

        const companyNames = locationList.filter(location => location.match === 'COMPANY_NAME')
            .map(location => {
                const { name: companyName } = location;

                const displayValue = `${companyName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct companies based on the name
        const uniqueCompanyNames = [...new Map(companyNames.map((location) => [location["name"], location])).values()];

        const propertyNames = locationList.filter(location => location.match === 'PROPERTY_NAME')
            .map(location => {
                const { name: propertyName } = location;

                const displayValue = `${propertyName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct companies based on the name
        const uniquePropertyNames = [...new Map(propertyNames.map((location) => [location["name"], location])).values()];

        setLocationAddressList(uniqueAddressesInLocation);
        setLocationCityList(uniqueCitiesInLocation);
        setLocationCountryList(uniqueCountriesInLocation);
        setCompanyList(uniqueCompanyNames);
        setPropertyNameList(uniquePropertyNames);

        logger.log('location address list:', uniqueAddressesInLocation);
        logger.log('location city list:', uniqueCitiesInLocation);
        logger.log('location country list:', uniqueCountriesInLocation);
        logger.log('company list:', uniqueCompanyNames);
        logger.log('property name list:', uniquePropertyNames);

        logger.log('did I reach here?');
        setDoneCombiningLocations(true);
    }, [locationList]);

    useEffect(() => {
        logger.log('done combining locations:', doneCombiningLocations);
        if (doneCombiningLocations) {
            setCombinedLocationsMatch([...locationAddressList, ...locationCityList, ...locationCountryList, ...companyList, ...propertyNameList]);
        }
    }, [doneCombiningLocations]);

    const buildListOfLocations = (locationSearchResponse) => {
        if (locationSearchResponse != null) {
            let allLocations = [];

            for (const locationSearchResponseKey in locationSearchResponse) {
                logger.log('location search key:', locationSearchResponseKey);

                const locations = locationSearchResponse[locationSearchResponseKey]; // array of location objects

                // go through each location and add the match type
                const locationsUpdate = locations.map(location => ({ match: locationSearchResponseKey, ...location }));
                logger.log('locations update:', locationsUpdate);

                allLocations.push(...locationsUpdate);
            }

            logger.log('all locations sigh:', allLocations);
            setLocationList(allLocations);
        }
    };

    const makeSearchForLocation = useCallback(debounce((searchValue) => {
        setDoneCombiningLocations(false);

        if (searchValue) {
            apiService.searchLocations(searchValue)
                .then(res => {
                    const responseData = res.data?.data;
                    buildListOfLocations(responseData);
                })
                .catch(err => {
                    // handle exception
                });
        }
    }, 500), []);

    useEffect(() => {
        makeSearchForLocation(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (!searchValue) {
            setCombinedLocationsMatch([]);
        }
    }, [searchValue]);

    const [requestCompleted, setRequestCompleted] = useState(false);

    const componentToRender = () => {
        if (requestCompleted && Array.isArray(properties) && properties.length > 0) {
            return properties.map(listing => {
                const location = listing.location;
                const address = location ? location.address : '-';

                let status = listing.propertyStatus;

                // get property price
                const propertyPrice = listing.propertyPrice;
                const price = propertyPrice && propertyPrice.price;
                const billingPeriod = propertyPrice && propertyPrice.billingPeriod;

                let overall = undefined;
                if (price && billingPeriod) {
                    let period = null;
                    switch (billingPeriod) {
                        case 'HOURLY':
                            period = 'hour';
                            break;

                        case 'WEEKLY':
                            period = 'week';
                            break;

                        case 'MONTHLY':
                            period = 'month';
                            break;

                        case 'DAILY':
                            period = 'day';
                            break;

                        default:
                            period = 'month';
                    }

                    overall = `${price.toLocaleString()} per ${period}`;
                } else if (price) {
                    overall = price;
                } else {
                    overall = 'N/A';
                }

                return <FilterTableRow
                    key={uuidv4()}
                    id={listing.id}
                    amount={listing.propertyPrice.price}
                    image={listing.media && listing.media.images[0] ?
                        listing.media.images[0].resourceUrl :
                        imgplaceholder}
                    info={truncateDescription(listing['name'].toUpperCase())}
                    added={moment(listing.createdOn).format('DD-MMM-YYYY')}
                    size={listing['listingDetails'] ? listing['listingDetails']['propertySize'] : ''}
                    capacity={listing['listingDetails'] ? listing['listingDetails']['capacity'] : ''}
                    address={address}
                    status='AVAILABLE'
                    listing={listing}
                    propertyStatus={status && status.label} price={` ${userCurrency} ${overall}`}
                    onClick={showViewModal} onView={showViewModal} onAddToCart={onAddToCart} />;

            });
        } else if (requestCompleted && Array.isArray(properties) && properties.length === 0) {
            return (
                <tbody>
                    <tr>
                        <td colSpan={6}>
                            <p style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>No Items Found</p>
                        </td>
                    </tr>
                </tbody>
            );
        } else if (!requestCompleted) {
            return (
                <tbody>
                    <tr>
                        <td colSpan={6}>
                            <Spinner size="sm" animation="border" role="status" style={{ width: '50px', height: '50px' }}>
                                <span className="visually-hidden"></span>
                            </Spinner>{" "}
                        </td>
                    </tr>
                </tbody>
            );
        }
    };


    // const proceedToCreateBookingFromOrder = (orderId) => {
    //     const currentUser = SERVICES.getUser();
    //     const userId = currentUser ? currentUser.id : 0;

    //     const listingId = ModalProp.id;


    //     logger.log('start date:', startDate, 'end date:', endDate);

    //     const payload = {
    //         userId,
    //         listingId,
    //         startOn: startDate,
    //         endOn: endDate,
    //         orderId
    //     };

    //     axios.post(`${Constants.BASE_URL}/booking/api/v1/booking/make/immediate/payment`, payload)
    //         .then(res => {
    //             logger.log('response after creating booking:', res);

    //             if (res.status === 200) {
    //                 const response = res.data;

    //                 const bookingResponse = response?.data;

    //                 const bookingIdsArray = [];
    //                 // @ts-ignore
    //                 bookingResponse && bookingResponse.forEach(booking => {
    //                     bookingIdsArray.push(booking.id);
    //                 });

    //                 const currentUser = SERVICES.getUser();

    //                 const userId = currentUser && currentUser.id;

    //                 const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/booking/email`;
    //                 // TODO: USE THIS TO TRIGGER BOOKING CONFIRMATION EMAIL IN THE OTHER 

    //                 // trigger booking confirmation email
    //                 axios.get(resourceUrl, { params: { bookingIds: bookingIdsArray && bookingIdsArray.join(',') } })
    //                     .then(res => {
    //                         logger.log(res);

    //                         // displayModal('success');

    //                     })
    //                     .catch(err => logger.log(err));
    //             }

    //         }).catch(err => {
    //             logger.log('what is here');

    //             const errorResponse = err.response;
    //             logger.log('error response:', errorResponse);

    //             const data = errorResponse?.data;
    //             let message = data?.message;
    //             const errorOnBooking = data?.errorOnBooking;

    //             logger.log('error on booking:', errorOnBooking);

    //             if (errorOnBooking && message) {
    //                 switch (errorOnBooking) {
    //                     case 'SPACE_NOT_PART_OF_PACKAGE':
    //                     case 'NUMBER_OF_CHECKINS_EXCEEDED':
    //                         message += `. Please proceed to make payment`;
    //                 }
    //             }

    //             // setBookingErrorAlert(true);
    //             // setShowErrorModal(true);
    //             // setBookingErrorMsg(message);
    //             // setErrorOnBooking(errorOnBooking);

    //             // setLoading(false);
    //         });
    // };



    const createOrderForImmediateListingPayment = () => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        apiService.createOrderForListingPayment({
            listingId: ModalProp.id,
            startDate,
            endDate,
            userId
        })
            .then(res => {
                const responseData = res.data?.data;
                const order = responseData?.[0];

                if (res.status === 200 && order) {
                    history.push({
                        pathname: '/booking-order-summary',
                        state: {
                            listing: ModalProp,
                            order,
                            user: currentUser,
                            startDate,
                            endDate
                        }
                    });
                }
            })
            .catch(err => {
                const data = err.response?.data;
                let message = data?.message;
                const errorOnBooking = data?.errorOnBooking;

                if (errorOnBooking && message) {
                    switch (errorOnBooking) {
                        case 'SPACE_NOT_PART_OF_PACKAGE':
                        case 'NUMBER_OF_CHECKINS_EXCEEDED':
                            message += `. Please proceed to make payment`;
                            break;
                        case 'PERIOD_EXCEEDS_ALLOCATION':
                            message += '. Kindly adjust the time period or make a payment';
                            break;
                    }
                }

                setBookingErrorAlert(true);
                setBookingErrorMsg(message);
                setErrorOnBooking(errorOnBooking);
            });
    };

    const textToRenderBasedOnError = (error) => {
        let text = '';

        if (error) {
            switch (error) {
                case 'NO_ASSIGNED_PACKAGE':
                    text = 'Please contact owner account to assign you one.';
                    break;

                case 'SPACE_NOT_PART_OF_PACKAGE':
                    text = 'Would you like to make payment for this space?';
                    break;

                case 'PERIOD_EXCEEDS_ALLOCATION':
                    text = 'Adjust the booking period and try again!';
                    break;

                case 'NUMBER_OF_CHECKINS_EXCEEDED':
                    text = 'Would you like to make payment for this space?';
                    break;
            }
        }

        return text;
    };

    const handleButtonClickOnFailureModal = (errorOnBooking) => {
        if (errorOnBooking) {
            switch (errorOnBooking) {
                case 'NO_ASSIGNED_PACKAGE':
                    setShowErrorModal(false);
                    break;

                case 'SPACE_NOT_PART_OF_PACKAGE':
                    createOrderForImmediateListingPayment();
                    break;

                case 'PERIOD_EXCEEDS_ALLOCATION':
                    createOrderForImmediateListingPayment();
                    break;

                case 'NUMBER_OF_CHECKINS_EXCEEDED':
                    createOrderForImmediateListingPayment();
                    break;
            }
        }
    };

    const needsPayment = () => {
        return errorOnBooking &&
            (errorOnBooking === 'SPACE_NOT_PART_OF_PACKAGE' ||
                errorOnBooking === 'NUMBER_OF_CHECKINS_EXCEEDED' ||
                errorOnBooking === 'PERIOD_EXCEEDS_ALLOCATION');
    };

    // Fetch groups when the modal is opened
    useEffect(() => {
        if (viewModal) {
            fetchGroups();
        }
    }, [viewModal]);

    // Fetch company groups with members
    const fetchGroups = async () => {
        try {
            const response = await apiService.getCompanyGroups();
            if (response.status === 200 && response.data) {
                const companyGroups = response.data.companyGroups || [];
                const userGroups = response.data.userGroups || [];
                const myTeams = response.data.myGroups || [];

                setCompanyGroups(companyGroups);
                setUserGroups(userGroups);
                setMyGroups(myTeams);

                // Fetch members for each group
                const membersMap = {};
                for (const group of myTeams) {
                    try {
                        const membersResponse = await apiService.getGroupMembers(group.id);
                        if (membersResponse.status === 200) {
                            // Handle new response format which includes content array
                            membersMap[group.id] = membersResponse.data?.content || [];
                        }
                    } catch (error) {
                        logger.error(`Error fetching members for group ${group.id}:`, error);
                        membersMap[group.id] = [];
                    }
                }
                setGroupMembers(membersMap);
            }
        } catch (error) {
            logger.error("Error fetching groups:", error);
        }
    };

    // Search for users within the company
    const searchUsers = async (searchTerm) => {
        if (!searchTerm || searchTerm.length < 3) {
            setSearchedUsers([]);
            setIsSearchLoading(false);
            return;
        }

        setIsSearchLoading(true);
        try {
            const response = await apiService.searchCompanyUsers(searchTerm);
            if (response.status === 200 && response.data) {
                setSearchedUsers(response.data || []);
            }
        } catch (error) {
            logger.error("Error searching users:", error);
        } finally {
            setIsSearchLoading(false);
        }
    };

    // Handle user search input change with debounce
    const handleUserSearchChange = useCallback(
        debounce((term) => {
            if (term.length >= 3) {
                searchUsers(term);
                setShowSearchDropdown(true);
            } else {
                setSearchedUsers([]);
                setIsSearchLoading(false);
            }
        }, 300),
        []
    );

    // Add a function to close the dropdown
    const closeSearchDropdown = () => {
        setShowSearchDropdown(false);
    };

    // Handle click outside of the search box
    const handleModalClick = (e) => {
        // Check if the click was outside the search area
        if (!e.target.closest('.search-area')) {
            closeSearchDropdown();
        }
    };

    // Add user to selected team members
    const addUserToTeam = (user) => {
        if (!selectedTeamMembers.some(member => (member.id || member.userId) === user.id)) {
            setSelectedTeamMembers([...selectedTeamMembers, user]);
        }
    };

    // Remove user from selected team members
    const removeUserFromTeam = (userId) => {
        setSelectedTeamMembers(selectedTeamMembers.filter(member => (member.id || member.userId) !== userId));
    };

    // Handle team selection - now uses stored members
    const selectExistingTeam = (group) => {
        setSelectedGroup(group);
        setBookingType("team");

        // Use pre-fetched members if available, ensuring it's an array
        const groupMembersList = groupMembers[group.id];
        if (groupMembersList && Array.isArray(groupMembersList) && groupMembersList.length > 0) {
            setSelectedTeamMembers(groupMembersList);
        } else {
            // Fallback to fetching if not already loaded
            getGroupMembers(group.id);
        }

        setIsCreatingTeam(false);
        setShowTeamMembersModal(true);
    };

    // Create new team option
    const selectCreateTeam = () => {
        setSelectedGroup(null);
        setBookingType("team");
        setSelectedTeamMembers([]);
        setIsCreatingTeam(true);
        setShowTeamMembersModal(true);
    };

    // Book for whole company option
    const selectBookForCompany = () => {
        setBookingType("company");
        proceedWithBooking();
    };

    // Get group members for a selected group
    const getGroupMembers = async (groupId) => {
        try {
            const response = await apiService.getGroupMembers(groupId);
            if (response.status === 200) {
                // Handle new response format which includes content array
                const members = response.data?.content || [];
                setSelectedTeamMembers(members);

                // Update the group members cache
                setGroupMembers(prev => ({
                    ...prev,
                    [groupId]: members
                }));
            }
        } catch (error) {
            logger.error("Error fetching group members:", error);
            setSelectedTeamMembers([]);
        }
    };

    // Create a new team - updated API endpoint
    const createTeam = async () => {
        if (!newTeamName.trim() || selectedTeamMembers.length === 0) {
            return;
        }

        setIsCreatingTeamLoading(true);
        try {
            const userIds = selectedTeamMembers.map(member => member.id || member.userId);
            const response = await apiService.createGroup(newTeamName, userIds);

            if (response.status === 201 && response.data?.data) {
                const newGroup = response.data.data[0];
                setSelectedGroup(newGroup);

                // Add to group members cache
                setGroupMembers(prev => ({
                    ...prev,
                    [newGroup.id]: selectedTeamMembers
                }));

                // Add to my groups
                setMyGroups(prev => [...prev, newGroup]);

                setShowTeamMembersModal(false);
                setShowTeamConfirmModal(true);
            }
        } catch (error) {
            logger.error("Error creating team:", error);
            setErrorAlert(true);
            setErrorMsg("Failed to create team. Please try again.");
        } finally {
            setIsCreatingTeamLoading(false);
        }
    };

    // Update team members - updated API endpoint
    const updateTeamMembers = async () => {
        if (!selectedGroup) {
            return;
        }

        try {
            // Get current team members from cache or fetch them
            const currentMembers = groupMembers[selectedGroup.id] || [];
            const currentMemberIds = currentMembers.map(member => member.id);

            // Find members to add and remove
            const membersToAdd = selectedTeamMembers
                .filter(member => !currentMemberIds.includes(member.id))
                .map(member => member.id);

            const membersToRemove = currentMembers
                .filter(member => !selectedTeamMembers.some(selected => selected.id === member.id))
                .map(member => member.id);

            // Add new members if any
            if (membersToAdd.length > 0) {
                await apiService.addUsersToGroup(selectedGroup.id, membersToAdd);
            }

            // Remove members if any
            if (membersToRemove.length > 0) {
                await apiService.removeUsersFromGroup(selectedGroup.id, membersToRemove);
            }

            // Update the group name if it changed
            if (isCreatingTeam && newTeamName !== selectedGroup.name) {
                await apiService.updateGroupName(selectedGroup.id, newTeamName);
            }

            // Update the cached members
            setGroupMembers(prev => ({
                ...prev,
                [selectedGroup.id]: selectedTeamMembers
            }));

            setShowTeamMembersModal(false);
            setShowTeamConfirmModal(true);
        } catch (error) {
            logger.error("Error updating team members:", error);
        }
    };

    // Reset team booking state
    const resetTeamBooking = () => {
        setBookingType("individual");
        setSelectedGroup(null);
        setIsCreatingTeam(false);
        setNewTeamName("");
        setSelectedTeamMembers([]); // Ensure this is reset to an empty array
        setShowTeamMembersModal(false);
        setShowTeamConfirmModal(false);
    };

    // Proceed with booking based on selected type
    const proceedWithBooking = async () => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;
        const listingId = ModalProp.id;

        setIsBookingLoading(true);
        try {
            let response;

            if (bookingType === "individual") {
                // Individual booking
                response = await apiService.createIndividualBooking({
                    userId,
                    listingId,
                    startOn: startDate,
                    endOn: endDate
                });
            } else if (bookingType === "team" && selectedGroup) {
                // Team booking
                response = await apiService.createTeamBooking({
                    userId,
                    listingId,
                    groupId: selectedGroup.id.toString(),
                    startOn: startDate,
                    endOn: endDate
                });
            } else if (bookingType === "company") {
                // Company booking
                response = await apiService.createCompanyBooking({
                    userId,
                    listingId,
                    startOn: startDate,
                    endOn: endDate
                });
            } else {
                // Invalid state
                setBookingErrorAlert(true);
                setBookingErrorMsg("Please select a valid booking option");
                setIsBookingLoading(false);
                return;
            }

            if (response.status === 200) {
                const bookingResponse = response.data?.data;
                const bookingIds = bookingResponse?.map(booking => booking.id);

                if (bookingIds?.length) {
                    await apiService.sendBookingConfirmationEmail(bookingIds.join(','));

                    // Format the date as requested: "23rd Jan, 2020"
                    const formattedDate = moment(startDate).format("Do MMM, YYYY");
                    let successMsg = `Your booking at ${ModalProp.name} on ${formattedDate}`;

                    // Add specific text based on booking type
                    if (bookingType === "team" && selectedGroup) {
                        successMsg += ` for ${selectedGroup.name}`;
                    } else if (bookingType === "company") {
                        successMsg += ` for your entire company`;
                    }

                    // Complete the message
                    successMsg += ` has been confirmed. Please check your email for details.`;

                    setSuccessMessage(successMsg);
                    setSuccessAlert(true);
                    resetTeamBooking();
                    setViewModal(false);
                }
            }
        } catch (err) {
            const data = err.response?.data;
            let message = data?.message;
            const errorOnBooking = data?.errorOnBooking;

            if (errorOnBooking && message) {
                switch (errorOnBooking) {
                    case 'SPACE_NOT_PART_OF_PACKAGE':
                    case 'NUMBER_OF_CHECKINS_EXCEEDED':
                        message += `. Please proceed to make payment`;
                        break;
                    case 'PERIOD_EXCEEDS_ALLOCATION':
                        message += '. Kindly adjust the time period or make a payment';
                        break;
                }
            }

            setBookingErrorAlert(true);
            setBookingErrorMsg(message || "An error occurred while booking");
            setErrorOnBooking(errorOnBooking);
        } finally {
            setIsBookingLoading(false);
        }
    };

    return (

        <FilterPropertiesStyles>
            {/* Update the success alert to use the dynamic message */}
            <SuccessAlert show={successAlert} message={successMessage} close={() => setSuccessAlert(false)} />
            <ErrorAlert show={errorAlert} message={errorMsg || "There was a problem adding the item"} close={() => setErrorAlert(false)} />
            <div
                className="header"
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h4 style={{ marginBottom: '0' }}>
                        <b>Search Results</b>
                    </h4>
                </div>
                <Row className='filters' style={{ width: '60%', position: 'relative', justifyContent: 'flex-end' }}>
                    <div style={{ width: '25%' }}>
                        <div className='custom-select amenities-select form-select' onClick={() => setAmenitiesClicked(prevClickState => !prevClickState)}>
                            <p>Amenities</p>
                        </div>
                    </div>
                    <div className='card' style={{ display: (amenitiesClicked && amenitiesTypes.length > 0) ? 'block' : 'none' }}>
                        <header style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h4>Filter</h4>
                            <FaTimes style={{ cursor: 'pointer' }} onClick={() => setAmenitiesClicked(false)} />
                        </header>
                        <section>
                            {
                                amenitiesTypes && Array.isArray(amenitiesTypes) &&
                                amenitiesTypes.map(amenity => {
                                    return (
                                        <div>
                                            <div style={{ height: '100%', paddingTop: '3px' }}>
                                                <input type="checkbox" id={amenity.value} className='amenities-check' onChange={(e) => {
                                                    const checked = amenitiesTypes.find(o => o.value === amenity.value).checked = e.target.checked;
                                                    const selectedAmenities = amenitiesTypes.filter(o => o.checked === true).map(o => o.value);
                                                    logger.log('selected amenities:', selectedAmenities);

                                                    setSelectedAmenities(selectedAmenities);
                                                }} />
                                            </div>
                                            <label htmlFor={amenity.value}>{amenity.label}</label>
                                        </div>
                                    );
                                })
                            }
                        </section>
                        <div className='filter-button-container'>
                            <Button size="md" block onClick={clearAllCheckboxes}>Clear All</Button>
                            <Button size="md" block onClick={searchForProperties}>Apply</Button>
                        </div>
                    </div>
                    <div style={{ width: '25%' }}>
                        <select className="form-select" aria-label="Zone" value={zone} onChange={handleZoneChange} disabled={true}>
                            <option disabled selected={zone === null}>Zone</option>
                            {
                                supportedZones && Array.isArray(supportedZones) &&
                                supportedZones.map((zone) => <option key={uuidv4()} value={zone.name}>{zone.label}</option>)
                            }
                        </select>
                    </div>


                </Row>

            </div>

            <div className='selected-amenities'
                style={{
                    marginBottom: 10,
                }}
            >
                {selectedAmenities.length > 0 && selectedAmenities.map(amenity => (
                    <div key={amenity} className="chip" style={{ display: 'inline-flex', alignItems: 'center', margin: '5px', padding: '20px', backgroundColor: '#A3CAFFFF', borderRadius: '5px', border: "1px", borderColor: "#000000FF" }}>
                        <span>{amenity}</span>
                        <FaTimesCircle
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                            onClick={() => {
                                const updatedAmenities = selectedAmenities.filter(selectedAmenity => selectedAmenity !== amenity);
                                setSelectedAmenities(updatedAmenities);
                                const updatedAmenitiesTypes = amenitiesTypes.map(a => a.value === amenity ? { ...a, checked: false } : a);
                                setAmenitiesTypes(updatedAmenitiesTypes);
                                searchForProperties();
                            }}
                        />
                    </div>
                ))}
            </div>



            <div
                style={{
                    width: "98%",
                    padding: 10,
                    borderRadius: 10,
                    margin: "auto",
                    marginBottom: 10,
                    background: 'white',
                    boxShadow: '5px 5px 15px 5px lightgrey'

                }}
            >
                <Row>
                    <Col style={{ height: '40px' }}>
                        <select className="form-select" aria-label="Property Type" style={{ height: '100%' }} value={propertyType} onChange={handlePropertyTypeChange}>
                            <option disabled>Type of service</option>
                            {
                                propertyTypes.length > 0 && propertyTypes.map((type) => (
                                    <option key={uuidv4()} value={type.value}>
                                        {type.label}
                                    </option>
                                ))}
                        </select>
                    </Col>
                    <Col style={{ height: '40px' }}>
                        <select className="form-select" aria-label="Workspace Type" style={{ height: '100%' }} value={workspaceType} onChange={handleWorkspaceChange}>
                            <option disabled>Workspace type</option>
                            {
                                workSpaceTypes &&
                                workSpaceTypes.map((type) => <option key={uuidv4()} value={type.value}>{type.label}</option>)
                            }
                        </select>
                    </Col>
                    <Col style={{ height: '40px' }}>
                        <Autocomplete
                            getItemValue={(location) => {
                                const { match, address, city, country, name: companyName, propertyName, displayValue } = location;

                                let value = '';
                                if (match != null) {
                                    switch (match) {
                                        case 'ADDRESS':
                                            value = `${match}~${address}~${displayValue}`;
                                            break;

                                        case 'CITY':
                                            value = `${match}~${city}~${displayValue}`;
                                            break;

                                        case 'COUNTRY':
                                            const initial = country != null ? country.value : '';
                                            value = `${match}~${initial}~${displayValue}`;
                                            break;

                                        case 'COMPANY_NAME':
                                            value = `${match}~${companyName}~${displayValue}`;

                                        case 'PROPERTY_NAME':
                                            value = `${match}~${propertyName}~${displayValue}`;
                                    }
                                }

                                return value;
                            }}
                            items={combinedLocationsMatch}
                            renderInput={(props) => (
                                <input
                                    {...props}
                                    type="text"
                                    className="form-control h-100"
                                    placeholder="Search for location..."
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            )}
                            renderItem={(location, isHighlighted) =>
                                <div style={{ borderBottom: '1px solid lightgray', padding: '15px', cursor: 'pointer', background: isHighlighted ? 'lightgray' : 'white' }}>
                                    {location.displayValue}
                                </div>
                            }
                            menuStyle={{
                                borderRadius: '3px',
                                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                zIndex: '3',
                                width: '30%',
                                overflow: 'auto',
                                maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                            }}
                            wrapperStyle={{ height: '100%' }}
                            value={searchValue}
                            onSelect={(value) => {
                                const firstIndex = value.indexOf('~');
                                const lastIndex = value.indexOf('~', firstIndex + 1);
                                const displayValue = value.slice(lastIndex + 1);

                                logger.log('actual value:', value);
                                logger.log('selected value:', displayValue);

                                const selected = value.slice(0, lastIndex);
                                logger.log('full string value:', selected);
                                const initialportion = value.slice(firstIndex + 1, lastIndex);
                                logger.log('initial portion:', initialportion);
                                //setSearchValue(displayValue);
                                setSearchValue(initialportion);
                                setSelectedLocationAndMatch(selected);
                            }}
                        />
                        {/*<select className="form-select" aria-label="Location" style={{height: '100%'}} value={location} onChange={handleLocationChange}>*/}
                        {/*    <option disabled selected>Location</option>*/}
                        {/*    {*/}
                        {/*        allLocations.length > 0 && allLocations.map((type) => (*/}
                        {/*            <option key={uuidv4()} value={`${type.city},${type.country.value}`}>*/}
                        {/*                {formatLocation(type)}*/}
                        {/*            </option>*/}
                        {/*        ))}*/}
                        {/*</select>*/}
                    </Col>
                    <Col md={3} style={{ height: '42px' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
                            {shouldUseDateTimePicker() ? (
                                <DateTimePicker
                                    label="Start Date"
                                    value={startDateObject}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            ) : (
                                <DatePicker
                                    label="Start Date"
                                    value={startDateObject}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            )}
                        </LocalizationProvider>
                    </Col>
                    <Col md={3} style={{ height: '42px' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
                            {shouldUseDateTimePicker() ? (
                                <DateTimePicker
                                    label="End Date"
                                    value={endDateObject}
                                    onChange={handleEndDateChange}
                                    disabled={workspaceType === 'DEDICATED_DESK' && currentUser?.role !== 'ROLE_SUBSCRIBER_INDIVIDUAL'}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            ) : (
                                <DatePicker
                                    label="End Date"
                                    value={endDateObject}
                                    onChange={handleEndDateChange}
                                    disabled={workspaceType === 'DEDICATED_DESK' && currentUser?.role !== 'ROLE_SUBSCRIBER_INDIVIDUAL'}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            )}
                        </LocalizationProvider>
                    </Col>

                    {/*<Col>*/}
                    {/*<FormControl >*/}
                    {/*                <Input type="datetime-local"*/}
                    {/*                       className="form-control"*/}
                    {/*                       id="datetime-local"*/}
                    {/*                       onChange={handleStartDateChange}*/}
                    {/*                       defaultValue={startDate}*/}
                    {/*                    // className={classes.textField}*/}
                    {/*                       InputLabelProps={{*/}
                    {/*                           shrink: true,*/}
                    {/*                       }}*/}
                    {/*                />*/}
                    {/*            </FormControl>*/}
                    {/*</Col>*/}

                </Row>
                <Row className="mt-3" style={{ alignItems: 'center' }}>
                    <Col md="2">
                        <QuantityCounter count={capacity} setCount={setCapacity} />
                    </Col>
                    <Col>
                        <h6 className="text-muted" style={{ fontSize: 14 }}>{userCurrency} {maxPrice ? maxPrice.toLocaleString() : 0}</h6>
                        <Slide
                            defaultValue={maxPrice || 0}
                            min={0}
                            max={100000}
                            step={500}
                            railStyle={{
                                height: "6px",
                            }}
                            trackStyle={[
                                { height: "6px" },
                            ]}
                            handleStyle={[
                                {
                                    background: "white",
                                    marginTop: "-5px"
                                },
                            ]}
                            onChange={(e) => setMaxPrice(e)}
                        />
                    </Col>
                    <Col md="3">
                        <Button size="lg" block onClick={searchForProperties}>Search</Button>
                    </Col>
                </Row>
            </div>
            <div></div>

            <Container>
                <div className='table-wrapper'>
                    <Table>
                        <thead>
                            <tr style={{ height: '60px', background: '#f7f7f7' }}>
                                <td style={{ width: '28%' }}>Photo</td>
                                <td className="w-25">Property Info</td>
                                {/*<td>Added on</td>*/}
                                <td>Location</td>
                                {/*<td>Status</td>*/}
                                <td className="text-end">Price</td>
                            </tr>
                        </thead>
                        {
                            componentToRender()
                        }
                    </Table>
                    {
                        Array.isArray(properties) && properties.length > 0 &&
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalPageSize}
                            pageSize={itemsPerPage}
                            onPageChange={changePage}
                        />
                    }
                </div>

            </Container>

            <Modal scrollable={true} show={viewModal} onHide={closeViewModal} size="xl">
                <Modal.Header className="border-0 d-block">
                    {/* Remove this duplicate SuccessAlert */}
                    {/* <SuccessAlert show={successAlert} message={successMessage} close={() => setSuccessAlert(false)} /> */}
                    <ErrorAlert show={bookingErrorAlert} message={bookingErrorMsg || "An error occurred"} close={() => setBookingErrorAlert(false)} />
                    <ErrorAlert show={bookingInitiatedErrorAlert} message={bookingInitiatedErrorMsg || "An error occurred"} close={() => setBookingInitiatedErrorAlert(false)} />
                    <section className="mt-3 mb-1 px-4">
                        <div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className="font-weight-normal">
                                        {ModalProp.name}
                                    </h2>
                                </div>
                                <div>
                                    <div className="rounded-pill category-button">
                                        <p className="py-2 px-4">{ModalProp.category}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="my-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mx-3 text-success">
                                                <i className="material-icons inline-icon mr-2">
                                                    verified
                                                </i>
                                                Approved
                                            </p>
                                        </div>
                                        .
                                        <div>
                                            <p className="mx-3">{ModalProp.subCategory}</p>
                                        </div>
                                        .
                                        <div>
                                            <p className="mx-3 text-muted">
                                                {ModalProp.location.address}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <Button
                                                variant="outline-secondary"
                                                style={{ width: '90px' }}
                                                disabled={!bookingErrorMsg || !needsPayment()}
                                                onClick={() => handleButtonClickOnFailureModal(errorOnBooking)}
                                            >
                                                Pay
                                            </Button>
                                        </div>
                                        <div className={'ms-3'}>
                                            {/* Replace Book button with Dropdown */}
                                            <Dropdown>
                                                <Dropdown.Toggle variant="primary" id="booking-dropdown" style={{ width: '120px' }}>
                                                    Book
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={{ width: '250px' }}>
                                                    {/* Book for myself option */}
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setBookingType("individual");
                                                            proceedWithBooking();
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <FaUser className="me-2" />
                                                            <span>Book for myself</span>
                                                        </div>
                                                    </Dropdown.Item>

                                                    <Dropdown.Divider />

                                                    {/* Team booking options */}
                                                    {myGroups.length > 0 && (
                                                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                            {myGroups.map(group => (
                                                                <Dropdown.Item
                                                                    key={group.id}
                                                                    onClick={() => selectExistingTeam(group)}
                                                                >
                                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                                        <div className="d-flex align-items-center">
                                                                            <FaUsers className="me-2" />
                                                                            <span>{group.name}</span>
                                                                        </div>
                                                                        <Badge bg="light" pill className="ms-2" style={{ color: 'black' }}>
                                                                            {group.memberCount || 0} members
                                                                        </Badge>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            ))}
                                                        </div>
                                                    )}

                                                    <Dropdown.Item onClick={selectCreateTeam}>
                                                        <div className="d-flex align-items-center">
                                                            <FaUserPlus className="me-2" />
                                                            <span>Create new team</span>
                                                        </div>
                                                    </Dropdown.Item>

                                                    <Dropdown.Divider />

                                                    {/* Book for company option */}
                                                    <Dropdown.Item onClick={selectBookForCompany}>
                                                        <div className="d-flex align-items-center">
                                                            <FaBuilding className="me-2" />
                                                            <span>Book for entire company</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal.Header>
                <Modal.Body>
                    <section className="mb-5 px-4">
                        <div className="">
                            <div>
                                <div className="">
                                    <div className="d-flex align-items-stretch detail-images-container">
                                        <div className="image-large-container">
                                            <div className="mr-3">
                                                <img src={ModalProp.media ? image : demo} className="detail-image-dimensions detail-image" />
                                            </div>
                                        </div>
                                        <div className="image-siblings-container">
                                            <div>
                                                {ModalProp.media && ModalProp.media.images && ModalProp.media.images.map(image => {
                                                    return (
                                                        <div className=" mb-2">
                                                            <img onClick={changeImage} className="sibling-images"
                                                                src={image.resourceUrl} alt="placeholder" />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="mb-5 px-4">
                        <div>
                            <div className="d-flex">
                                <div className="description-section mr-4">
                                    <section>
                                        <div>
                                            <section>
                                                <div>
                                                    <h3 className="font-weight-normal">{ModalProp.subCategory} listed by {ModalProp.createdBy.firstName} {ModalProp.createdBy.lastName}</h3>
                                                </div>
                                                <div>
                                                    <div className="d-flex my-2">
                                                        <div className="mr-3">
                                                            <h5 className="font-weight-normal text-muted">Floor {ModalProp.listingDetails.floorNumber}</h5>
                                                        </div>
                                                        .
                                                        <div className="mx-3">
                                                            <h5 className="font-weight-normal text-muted">Capacity {ModalProp.listingDetails.capacity}</h5>
                                                        </div>
                                                        <div className="mx-3">
                                                            <h5 className="font-weight-normal text-muted">Property Size {ModalProp.listingDetails.propertySize}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <hr />
                                            <section>
                                                <div>
                                                    <div className="my-4">
                                                        <h5 className="font-weight-normal">Description</h5>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            {ModalProp.description}

                                                        </p>
                                                    </div>
                                                </div>
                                            </section>
                                            <hr className="mt-5" />
                                            <section>
                                                <div>
                                                    <div className="my-4">
                                                        <h5 className="font-weight-normal">Amenities</h5>
                                                    </div>
                                                    <div className="d-flex flex-wrap">
                                                        {ModalProp.amenities.map(amenity => {
                                                            return (
                                                                <div className=" mr-2 mb-3">
                                                                    <div className="">
                                                                        <div className="rounded-pill bg-gray py-2 px-4">
                                                                            {amenity.label}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </section>
                                </div>
                                <div className="price-section ml-3">
                                    <section>
                                        <div className="card curved shadow-sm">
                                            <div className="card-body">
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h5 className="font-weight-normal">
                                                                KHS 	{ModalProp.propertyPrice.price}

                                                            </h5>
                                                        </div>
                                                        <div>
                                                            {ModalProp.propertyPrice.billingPeriod}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>

            <FormModal
                onSubmit={onSubmit}
                title="Add a new property"
                isOpen={formModal}
                isClose={closeFormModal}
                declineButton="Cancel"
                acceptButton="Change"
                info={info}
                setInfo={setInfo}
                status={status}
                setStatus={setStatus}
                beds={beds}
                setBeds={setBeds}
                price={price}
                setPrice={setPrice}
                baths={baths}
                setBaths={setBaths}
            />
            <FeedbackModal
                isClose={closeDeleteModal}
                doneButton="Okay"
                isOpen={showDeleteModal}
                declineButton={closeDeleteModal}
            >
                <h6>This property has been deleted</h6>
            </FeedbackModal>


            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                <header className={'position-relative'} style={{ zIndex: 3 }}>
                    <figure className="text-right" style={{ padding: '15px' }}>
                        <img onClick={() => setShowErrorModal(false)} src={closeButton} alt="close button" style={{ width: '25px' }} />
                    </figure>
                </header>
                <Modal.Body style={{ height: '320px' }}>
                    <div className="text-center position-relative" style={{ bottom: '25px' }}>
                        <figure>
                            <img
                                className={'position-relative'}
                                src={rejectedImage}
                                alt="Failure"
                                style={{ width: '25%' }}
                            />
                        </figure>
                        <div>
                            <p className={'text-primary font-weight-bolder mt-5 mb-2'} style={{ fontSize: '1.1rem' }}>
                                {/* Remove direct rendering of error message to prevent duplication */}
                            </p>
                            <p>
                                {textToRenderBasedOnError(errorOnBooking)}
                            </p>
                            <div className={'mt-4'} style={{ display: (errorOnBooking) ? 'block' : 'none' }}>
                                <button
                                    className={'btn btn-primary'}
                                    onClick={() => handleButtonClickOnFailureModal(errorOnBooking)}
                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Team Members Modal - Redesigned */}
            <Modal
                show={showTeamMembersModal}
                onHide={() => setShowTeamMembersModal(false)}
                size="lg"
                centered
                onClick={handleModalClick}
            >
                <Modal.Header closeButton style={{ border: 'none', paddingBottom: 0 }}>
                    <Modal.Title style={{ fontSize: '1.5rem', fontWeight: '600' }}>
                        {isCreatingTeam ? 'Create a New Team' : 'Manage Team Members'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    {isCreatingTeam && (
                        <Form.Group className="mb-4">
                            <Form.Label style={{ fontWeight: '500' }}>Team Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter team name"
                                value={newTeamName}
                                onChange={(e) => setNewTeamName(e.target.value)}
                                className="py-2"
                                style={{ borderRadius: '8px' }}
                            />
                        </Form.Group>
                    )}

                    <Form.Group className="mb-4">
                        <Form.Label style={{ fontWeight: '500' }}>Team Members</Form.Label>
                        <div className="position-relative search-area">
                            <Form.Control
                                type="text"
                                placeholder="Search for team members by name or email"
                                value={userSearchTerm}
                                onChange={(e) => {
                                    setUserSearchTerm(e.target.value);
                                    handleUserSearchChange(e.target.value);
                                }}
                                onFocus={() => {
                                    if (userSearchTerm.length >= 3) {
                                        setShowSearchDropdown(true);
                                    }
                                }}
                                className="py-2"
                                style={{ borderRadius: '8px' }}
                            />
                            {isSearchLoading && (
                                <div className="position-absolute" style={{ right: '12px', top: '10px' }}>
                                    <Spinner animation="border" variant="primary" size="sm" />
                                </div>
                            )}
                            {showSearchDropdown && (searchedUsers.length > 0 || isSearchLoading) && (
                                <div className="position-absolute w-100 shadow-sm bg-white rounded mt-1" style={{ zIndex: 10, maxHeight: '250px', overflowY: 'auto' }}>
                                    {isSearchLoading && searchedUsers.length === 0 ? (
                                        <div className="p-2 text-center">
                                            <Spinner animation="border" variant="primary" size="sm" className="me-2" />
                                            <span>Searching...</span>
                                        </div>
                                    ) : searchedUsers.length === 0 ? (
                                        <div className="p-2 text-center text-muted">
                                            No users found
                                        </div>
                                    ) : (
                                        searchedUsers.map(user => (
                                            <div
                                                key={user.id}
                                                className="p-2 border-bottom d-flex align-items-center justify-content-between hover-bg-light"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    addUserToTeam(user);
                                                    setUserSearchTerm('');
                                                    setSearchedUsers([]);
                                                    closeSearchDropdown();
                                                }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div
                                                        className="rounded-circle me-2 d-flex align-items-center justify-content-center text-white bg-primary"
                                                        style={{
                                                            width: '32px',
                                                            height: '32px',
                                                            backgroundImage: user.avatar ? `url(${user.avatar})` : 'none',
                                                            backgroundSize: 'cover',
                                                            backgroundColor: user.avatar ? 'transparent' : '#0d6efd',
                                                            fontSize: '12px'
                                                        }}
                                                    >
                                                        {!user.avatar && (user.firstName?.charAt(0) || '') + (user.lastName?.charAt(0) || '')}
                                                    </div>
                                                    <div>
                                                        <div className="fw-bold" style={{ fontSize: '14px' }}>{user.firstName} {user.lastName}</div>
                                                        <div className="text-muted" style={{ fontSize: '12px' }}>{user.username}</div>
                                                    </div>
                                                </div>
                                                <Button
                                                    variant="light"
                                                    size="sm"
                                                    className="rounded-pill py-0 px-2"
                                                    style={{ fontSize: '12px' }}
                                                >
                                                    <FaUserPlus className="me-1" style={{ fontSize: '10px' }} /> Add
                                                </Button>
                                            </div>
                                        ))
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="text-muted small mt-1">
                            {userSearchTerm.length > 0 && userSearchTerm.length < 3 ?
                                `Type ${3 - userSearchTerm.length} more character${3 - userSearchTerm.length > 1 ? 's' : ''} to search` :
                                ''}
                        </div>
                    </Form.Group>

                    <div className="mb-4">
                        <h6 className="mb-3 fw-bold">Selected Team Members ({selectedTeamMembers?.length || 0})</h6>
                        {Array.isArray(selectedTeamMembers) && selectedTeamMembers.length > 0 ? (
                            <div className="border rounded p-2" style={{ maxHeight: '250px', overflowY: 'auto' }}>
                                {selectedTeamMembers.map(member => (
                                    <div
                                        key={member.id || member.userId}
                                        className="p-2 mb-2 rounded bg-light d-flex align-items-center justify-content-between"
                                    >
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="rounded-circle me-3 d-flex align-items-center justify-content-center text-white bg-primary"
                                                style={{
                                                    width: '36px',
                                                    height: '36px',
                                                    backgroundImage: member.avatar ? `url(${member.avatar})` : 'none',
                                                    backgroundSize: 'cover',
                                                    backgroundColor: member.avatar ? 'transparent' : '#0d6efd'
                                                }}
                                            >
                                                {!member.avatar && (member.firstName?.charAt(0) || '') + (member.lastName?.charAt(0) || '')}
                                            </div>
                                            <div>
                                                <div className="fw-bold">{member.firstName || ''} {member.lastName || ''}</div>
                                                <div className="text-muted small">{member.username}</div>
                                                <div className="d-flex align-items-center mt-1">
                                                    {member.role && (
                                                        <Badge bg="secondary" className="me-2" style={{ fontSize: '10px' }}>
                                                            {member.role}
                                                        </Badge>
                                                    )}
                                                    {member.invitationStatus && (
                                                        <Badge bg={member.invitationStatus === "ACCEPTED" ? "success" : "warning"} style={{ fontSize: '10px' }}>
                                                            {member.invitationStatus}
                                                        </Badge>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            variant="outline-danger"
                                            size="sm"
                                            className="rounded-circle"
                                            style={{ width: '32px', height: '32px', padding: 0 }}
                                            onClick={() => removeUserFromTeam(member.id || member.userId)}
                                        >
                                            <FaTimes />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-center p-4 border rounded bg-light">
                                <div className="text-muted mb-2">No team members selected</div>
                                <div className="small">Search and add team members above</div>
                            </div>
                        )}
                    </div>

                    {isCreatingTeam && (
                        <div className="alert alert-info d-flex" style={{ borderRadius: '8px' }}>
                            <FaInfo className="me-2 mt-1" />
                            <div>
                                <span>New teams need a name and at least one member. Team members will receive booking notifications.</span>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer style={{ border: 'none', paddingTop: 0 }}>
                    <Button
                        variant="outline-secondary"
                        className="px-4"
                        style={{ borderRadius: '6px' }}
                        onClick={() => setShowTeamMembersModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        className="px-4"
                        style={{ borderRadius: '6px' }}
                        onClick={createTeam}
                        disabled={isCreatingTeamLoading || !newTeamName.trim() || selectedTeamMembers.length === 0}
                    >
                        {isCreatingTeamLoading ? (
                            <Spinner animation="border" size="sm" className="me-1" />
                        ) : 'Create Team'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Team Booking Confirmation Modal */}
            <Modal show={showTeamConfirmModal} onHide={() => setShowTeamConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-4">
                        <h5>{ModalProp.name}</h5>
                        <p className="text-muted mb-0">{ModalProp.location.address}</p>
                    </div>

                    <div className="mb-4">
                        <h6 className="text-primary">Booking Details</h6>
                        <div className="d-flex justify-content-between">
                            <span>Start Date:</span>
                            <strong>{moment(startDate).format('DD MMM YYYY, h:mm A')}</strong>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>End Date:</span>
                            <strong>{moment(endDate).format('DD MMM YYYY, h:mm A')}</strong>
                        </div>
                    </div>

                    <div className="mb-4">
                        <h6 className="text-primary">Team Details</h6>
                        <div className="d-flex justify-content-between">
                            <span>Team Name:</span>
                            <strong>{selectedGroup?.name}</strong>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>Members:</span>
                            <strong>{selectedTeamMembers.length} members</strong>
                        </div>
                    </div>

                    <div className="alert alert-info">
                        <div className="d-flex">
                            <FaInfo className="me-2 mt-1" />
                            <div>
                                <span>This space will be booked for all team members. They will receive a notification about this booking.</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setShowTeamConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={proceedWithBooking} disabled={isBookingLoading}>
                        {isBookingLoading ? (
                            <Spinner animation="border" size="sm" className="me-1" />
                        ) : 'Confirm Booking'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </FilterPropertiesStyles>
    );
};

export default FilterProperties;
