import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuid, v4 as uuidv4 } from "uuid"; // random uuid generator mostly used as a key
import { Link, useHistory } from 'react-router-dom';

import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap'; // useful bootstrap components
import { FaCalendarAlt, FaArrowRight, FaHome, FaTimes } from 'react-icons/fa';
// slider to handle property price
import Slide from 'rc-slider';
import 'rc-slider/assets/index.css';

import QuantityCounter from '../../components/QuantityCounter';
import { saveSelectedFilters } from "../../redux/actions/dashboard"; // function to save all selected filters for use later
import { useDispatch, useSelector } from "react-redux";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker, } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import enGB from 'date-fns/locale/en-GB';

import SERVICES from '../../services';
import bg from '../../assets/img/Web/Spacia/Web/Spacia/nastuh-abootalebi-eHD8Y1Znfpk-unsplash 1.png';
import { ErrorAlert } from "../../components/Settings/alerts/ErrorAlert";
import moment from "moment-timezone";
import { Constants } from "../../utils/constants";
import apiService from "../../services/apiService";
import Utils from "../../utils";
import Autocomplete from 'react-autocomplete';
import closeButton from "../../assets/img/svg/blue-close-button.svg";
import finishSetupIllustration from "../../assets/img/png/finish-setup-illustration.png";
import { BundleMetricData, Package, packageSelectDataSignUp } from "../../data/package-select-data";
import MembershipPlanCard from "../../components/bundles/membership-plan-card";
import BundlePlanCard from "../../components/bundles/corporate-admin/bundle-plan-card";
import debounce from 'lodash.debounce';
import logger from "../../utils/logger";

const StartBooking = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedFilters = useSelector(state => state.dashboard.selectedFilters); // all user selected filters

    const [filterOptions, setFilterOptions] = useState({});
    const [propertyType, setPropertyType] = useState(null);
    const [workspaceType, setWorkspaceType] = useState(null);
    const [workSpaceTypes, setWorkSpaceTypes] = useState([]);

    const [startDateObject, setStartDateObject] = useState(new Date());
    const [startDate, setStartDate] = useState('');
    const [endDateObject, setEndDateObject] = useState(new Date());
    const [endDate, setEndDate] = useState('');
    const [capacity, setCapacity] = useState(1);
    const [maxPrice, setMaxPrice] = useState(null);
    const [location, setLocation] = useState(null);
    let [searchValue, setSearchValue] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [locationAddressList, setLocationAddressList] = useState([]);
    const [locationCityList, setLocationCityList] = useState([]);
    const [locationCountryList, setLocationCountryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [combinedLocationsMatch, setCombinedLocationsMatch] = useState([]);

    const [newUserModal, setNewUserModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorAlert, setErrorAlert] = useState(false);

    const [bundleMetrics, setBundleMetrics] = useState([]);

    const [requestCompleted, setRequestCompleted] = useState(false);
    const [properties, setProperties] = useState([]);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);

    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const user = SERVICES.getUser();
        setCurrentUser(user);
    }, []);

    useEffect(() => {
        const currentUser = SERVICES.getUser();
        logger.log('current user:', currentUser);

        // Only proceed if user is ROLE_SUBSCRIBER_OWNER
        if (currentUser?.role !== 'ROLE_SUBSCRIBER_OWNER') {
            logger.log('User is not an individual owner, skipping bundle check');
            return;
        }

        // Check if user has package plan from login
        const hasPackagePlan = localStorage.getItem('hasPackagePlan') === 'true';
        logger.log("Has package plan:", hasPackagePlan);

        // Only proceed with bundle metrics if user doesn't have a package plan
        if (!hasPackagePlan) {
            const ownerId = currentUser ? currentUser.id : 0;
            // Get bundle packages if available
            const bundlePackages = packageSelectDataSignUp?.packages || [];
            logger.log('bundle packages:', bundlePackages);

            apiService.getBundleMetrics(ownerId)
                .then(res => {
                    const response = res.data;
                    const responseData = response?.data;

                    if (responseData && responseData.length > 0) {
                        // Find bundle with valid metrics
                        const validBundle = responseData.find(metric => {
                            const { totalPurchased, totalUsedUp, totalAvailable } = metric;
                            return totalPurchased > 0 || totalUsedUp > 0 || totalAvailable > 0;
                        });

                        if (validBundle?.bundle?.bundleType?.value) {
                            const assignPayload = {
                                ownerId: ownerId,
                                userId: ownerId,
                                bundleType: validBundle.bundle.bundleType.value,
                                operation: "ASSIGN"
                            };

                            apiService.assignBundle(assignPayload)
                                .then(assignResponse => {
                                    if (assignResponse.data?.data?.[0]) {
                                        const updated = { ...currentUser, packagePlan: assignResponse.data.data[0] };
                                        SERVICES.saveUser(updated);
                                    }
                                })
                                .catch(assignError => {
                                    logger.error("Error assigning bundle:", assignError);
                                });
                        }

                        // Update bundle metrics state
                        const bundleDataWithOffers = responseData.map(e => {
                            const bundle = e.bundle;
                            const bundleType = bundle?.bundleType;
                            const label = bundleType?.label;

                            const bundlePkg = bundlePackages.find(bundlePackage =>
                                bundlePackage.title === label
                            );
                            const bundleOffers = bundlePkg ? bundlePkg.bundleOffer : [];

                            return { ...e, bundleOffers };
                        });

                        setBundleMetrics(bundleDataWithOffers);
                    }
                }).catch(err => {
                    logger.error("Error fetching bundle metrics:", err);
                });
        }

        // Clean up localStorage on component unmount
        return () => {
            localStorage.removeItem('hasPackagePlan');
        };
    }, []);

    const userCurrency = SERVICES.getCurrency();

    const handleStuffOnModalHide = () => {
        setNewUserModal(false);
    };

    useEffect(() => {
        const now = moment(new Date()).format('yyyy-MM-DDTHH:mm');
        logger.log('today is:', now);

        const startDateObject = new Date(now);
        const endDateObject = new Date(now);

        setStartDateObject(startDateObject);
        setEndDateObject(endDateObject);

        setStartDate(currentDate(startDateObject));
        setEndDate(currentDate(endDateObject));
    }, []);


    /*
    ** Declare style objects to be used
    *  on most of the child components
    */
    const link = {
        textDecoration: 'none',
        color: 'black'
    };

    const newModal = {
        backgroundImage: `url(${bg})`
    };

    const modalBar = {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        width: '80%',
        marginRight: 'auto',
        marginLeft: 'auto',
        padding: 10,
        marginBottom: 30,
        borderRadius: 5
    };

    const calendarStyle = {
        '.MuiTypography-root': {
            fontFamily: 'Montserrat !important'
        },
        '.MuiPickersCalendarHeader-label': {
            fontFamily: 'Montserrat !important'
        },
        '.MuiPickersDay-root': {
            fontFamily: 'Montserrat !important'
        },
        '.MuiInputBase-input': {
            fontFamily: 'Montserrat !important'
        },
        '.MuiPickersToolbar-root': {
            fontFamily: 'Montserrat !important'
        },
        '.MuiTypography-overline': {
            fontFamily: 'Montserrat !important'
        },
        '.MuiClock-root': {
            fontFamily: 'Montserrat !important'
        }
    };

    // Load filter options and set initial values
    useEffect(() => {
        const loadFilterOptions = async () => {
            try {
                const response = await apiService.getFilterOptions();

                if (response?.status === 200 && response?.data?.data) {
                    const options = response.data.data;
                    setFilterOptions(options);

                    // Set default property type
                    if (options.propertyTypes?.length > 0) {
                        const defaultPropertyType = options.propertyTypes[0].value;
                        setPropertyType(defaultPropertyType);

                        // Filter and set workspace types for this property type
                        const workspaceTypesForProperty = options.subCategoryTypes.filter(
                            type => type.parent === defaultPropertyType
                        );
                        setWorkSpaceTypes(workspaceTypesForProperty);

                        // Set default workspace type
                        if (workspaceTypesForProperty.length > 0) {
                            setWorkspaceType(workspaceTypesForProperty[0].value);
                        }
                    }
                }
            } catch (error) {
                logger.error('Error loading filter options:', error);
            }
        };

        loadFilterOptions();
    }, []); // Run only once on mount

    // Update workspace types when property type changes
    useEffect(() => {
        if (filterOptions?.subCategoryTypes && propertyType) {
            const workspaceTypesForProperty = filterOptions.subCategoryTypes.filter(
                type => type.parent === propertyType
            );
            setWorkSpaceTypes(workspaceTypesForProperty);

            // Set first workspace type if current one isn't valid for new property type
            const isCurrentWorkspaceTypeValid = workspaceTypesForProperty.some(
                type => type.value === workspaceType
            );

            if (!isCurrentWorkspaceTypeValid && workspaceTypesForProperty.length > 0) {
                setWorkspaceType(workspaceTypesForProperty[0].value);
            }
        }
    }, [filterOptions, propertyType]);

    useEffect(() => {
        if (workspaceType === 'DEDICATED_DESK') {
            const endDateTime = new Date(startDateObject);
            endDateTime.setDate(startDateObject.getDate() + 30); // 31 days later
            endDateTime.setHours(17, 0, 0, 0); // 5 PM
            setEndDateObject(endDateTime);
        }
    }, [startDateObject, workspaceType]);


    useEffect(() => {

        const now = moment().toDate(); // Get current date as a Date object
        const defaultEndTime = new Date(now);
        if (workspaceType === 'DEDICATED_DESK' && currentUser?.role !== 'ROLE_SUBSCRIBER_INDIVIDUAL') {
            now.setHours(8, 0, 0, 0); // Set the initial time to 8:00 AM regardless of type

            setStartDateObject(now);
            setStartDate(currentDate(now));
            const endDateTime = new Date(now);
            endDateTime.setDate(now.getDate() + 30); // Set 30 days later
            endDateTime.setHours(17, 0, 0, 0); // Set the end time to 5:00 PM
            setEndDateObject(endDateTime);
            setEndDate(currentDate(endDateTime));
        } else {
            defaultEndTime.setHours(now.getHours() + 25); // Default to 25 hours later
            setEndDateObject(defaultEndTime);
            setEndDate(currentDate(defaultEndTime));
        }
    }, [workspaceType, currentUser]);

    const shouldUseDateTimePicker = () => {
        // Always use DateTimePicker for non-dedicated desks
        if (workspaceType !== 'DEDICATED_DESK') return true;

        // For dedicated desks, use DateTimePicker only for individual subscribers
        if (workspaceType === 'DEDICATED_DESK' && currentUser?.role === 'ROLE_SUBSCRIBER_INDIVIDUAL') return true;

        // Otherwise use DatePicker
        return false;
    };

    useEffect(() => {
        const now = moment().toDate();
        const defaultEndTime = new Date(now);

        if (workspaceType === 'DEDICATED_DESK' && currentUser?.role !== 'ROLE_SUBSCRIBER_INDIVIDUAL') {
            // For dedicated desk (non-individual users)
            now.setHours(8, 0, 0, 0);
            setStartDateObject(now);
            setStartDate(currentDate(now));

            const endDateTime = new Date(now);
            endDateTime.setDate(now.getDate() + 30);
            endDateTime.setHours(17, 0, 0, 0);
            setEndDateObject(endDateTime);
            setEndDate(currentDate(endDateTime));
        } else {
            // For all other cases
            defaultEndTime.setHours(now.getHours() + 25);
            setEndDateObject(defaultEndTime);
            setEndDate(currentDate(defaultEndTime));
        }
    }, [workspaceType, currentUser]);

    const handleStartDateChange = (newValue) => {
        const selectedStartDate = new Date(newValue);

        if (workspaceType === 'DEDICATED_DESK' && currentUser?.role !== 'ROLE_SUBSCRIBER_INDIVIDUAL') {
            selectedStartDate.setHours(8, 0, 0, 0);
            setStartDateObject(selectedStartDate);
            setStartDate(currentDate(selectedStartDate));

            const endDateTime = new Date(selectedStartDate);
            endDateTime.setDate(endDateTime.getDate() + 30);
            endDateTime.setHours(17, 0, 0, 0);
            setEndDateObject(endDateTime);
            setEndDate(currentDate(endDateTime));
        } else {
            setStartDateObject(selectedStartDate);
            setStartDate(currentDate(selectedStartDate));
        }
    };

    // a function that saves all selected filters for use later
    const dispatchFilters = () => {
        logger.log('inside dispatch filters. selectedLocationAndMatch:', selectedLocationAndMatch, ". search value:", searchValue);
        if (startDate === endDate) {
            setErrorAlert(true);
            setErrorMsg('Start date and End date must be different');
            return;
        }

        if (startDate > endDate) {
            setErrorAlert(true);
            setErrorMsg('Start date must not be greater than the end date');
            return;
        }

        const selectedFilters = {
            cost: maxPrice,
            location: (!selectedLocationAndMatch && searchValue) ? `ADDRESS~${searchValue}` : selectedLocationAndMatch,
            locationDisplayValue: searchValue,
            propertyType,
            workspaceType,
            capacity,
            from: startDate,
            to: endDate
        };

        logger.log('selected filters here:', selectedFilters);

        // dispatch the selected filters with redux
        dispatch(saveSelectedFilters(selectedFilters));

        // load the `/filterprops` page
        history.push('/filterprops');
    };

    const formatLocation = (location) => {
        const city = location && location.city;
        const country = location && location.country;

        let label = (country) ? country.label : '';
        label = label.charAt(0).toUpperCase() + label.slice(1);

        return `${city}, ${label.toUpperCase()}`;
    };

    const handlePropertyTypeChange = (e) => {
        const selectedPropertyType = e.target.value;
        logger.log('selected property type is', selectedPropertyType);

        setPropertyType(selectedPropertyType);
    };

    const handleWorkSpaceChange = (e) => {
        const selectedWorkspace = e.target.value;
        logger.log('selected workspace is', selectedWorkspace);

        setWorkspaceType(selectedWorkspace);
    };

    const handleLocationChange = (e) => {
        const value = e.target.value;
        logger.log(value);

        const cityAndCountry = value.split(',');
        const [city, country] = cityAndCountry;

        const location = `${city},${country}`;
        setLocation(location);

        logger.log('city', city, 'country', country);
    };

    const getDate = (date) => {
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    };

    const handleEndDateChange = (dateValue) => {
        logger.log('current selected end date:', dateValue);
        const selectedEndDate = new Date(dateValue);
        setEndDateObject(selectedEndDate);

        const endDate = currentDate(selectedEndDate);

        logger.log('current date func:', endDate);

        setEndDate(endDate);
    };

    const retainSelectedStartDate = (date) => {
        setStartDateObject(prevState => {
            return prevState;
        });
    };

    const retainSelectedEndDate = (date) => {
        setEndDateObject(prevState => {
            return prevState;
        });
    };

    function currentDate(date) {
        let selectedDate = '';

        try {
            selectedDate = date.toISOString().substring(0, date.toISOString().lastIndexOf(':'));
        } catch (e) {
            logger.error('an error occurred while converting date to ISO string. message:', e);
        }

        return selectedDate;
    }

    const [doneCombiningLocations, setDoneCombiningLocations] = useState(false);
    useEffect(() => {
        setDoneCombiningLocations(false);

        const addressesInLocation = locationList.filter(location => location.match === 'ADDRESS')
            .map(location => {
                const { address, city, country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${address}, ${city}, ${countryName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct locations based on the address
        const uniqueAddressesInLocation = [...new Map(addressesInLocation.map((location) => [location["address"], location])).values()];

        const citiesInLocation = locationList.filter(location => location.match === 'CITY')
            .map(location => {
                const { city, country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${city}, ${countryName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct locations based on the city
        const uniqueCitiesInLocation = [...new Map(citiesInLocation.map((location) => [location["city"], location])).values()];

        const countriesInLocation = locationList.filter(location => location.match === 'COUNTRY')
            .map(location => {
                const { country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${countryName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct locations based on the country
        const uniqueCountriesInLocation = [...new Map(countriesInLocation.map((location) => {
            const country = location["country"];
            const label = country != null ? country.label : '';

            return [label, location];
        })).values()];

        const companyNames = locationList.filter(location => location.match === 'COMPANY_NAME')
            .map(location => {
                const { name: companyName } = location;

                const displayValue = `${companyName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct companies based on the name
        const uniqueCompanyNames = [...new Map(companyNames.map((location) => [location["name"], location])).values()];

        setLocationAddressList(uniqueAddressesInLocation);
        setLocationCityList(uniqueCitiesInLocation);
        setLocationCountryList(uniqueCountriesInLocation);
        setCompanyList(uniqueCompanyNames);

        logger.log('location address list:', uniqueAddressesInLocation);
        logger.log('location city list:', uniqueCitiesInLocation);
        logger.log('location country list:', uniqueCountriesInLocation);
        logger.log('company list:', uniqueCompanyNames);

        logger.log('did I reach here?');
        setDoneCombiningLocations(true);
    }, [locationList]);

    useEffect(() => {
        logger.log('done combining locations:', doneCombiningLocations);
        if (doneCombiningLocations) {
            setCombinedLocationsMatch([...locationAddressList, ...locationCityList, ...locationCountryList, ...companyList]);
        }
    }, [doneCombiningLocations]);

    const buildListOfLocations = (locationSearchResponse) => {
        if (locationSearchResponse != null) {
            let allLocations = [];

            for (const locationSearchResponseKey in locationSearchResponse) {
                logger.log('location search key:', locationSearchResponseKey);

                const locations = locationSearchResponse[locationSearchResponseKey]; // array of location objects

                // go through each location and add the match type
                const locationsUpdate = locations.map(location => ({ match: locationSearchResponseKey, ...location }));
                logger.log('locations update:', locationsUpdate);

                allLocations.push(...locationsUpdate);
            }

            logger.log('all locations sigh:', allLocations);
            setLocationList(allLocations);
        }
    };

    const makeSearchForLocation = useCallback(debounce((searchValue) => {
        logger.log('call me after 500 ms');

        // setLocationList([]);
        setDoneCombiningLocations(false);

        if (searchValue) {
            apiService.searchLocations(searchValue)
                .then(res => {
                    const response = res.data;
                    const responseData = response?.data;

                    buildListOfLocations(responseData);
                }).catch(err => {
                    // handle exception
                });
        }
    }, 500), []);

    useEffect(() => {
        makeSearchForLocation(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (!searchValue) {
            setCombinedLocationsMatch([]);
        }
    }, [searchValue]);

    useEffect(() => {
        switch (propertyType) {
            case 'OFFICE_SPACE':
                setWorkspaceType('PRIVATE_OFFICE');
                break;

            case 'RESIDENTIAL_SPACE':
                setWorkspaceType('SERVICED_APARTMENTS');
                break;

            case 'SERVICE':
                setWorkspaceType('TRANSPORT');
                break;
        }
    }, [propertyType]);

    useEffect(() => {
        if (Object.keys(filterOptions).length !== 0) {
            // default property type to `OFFICE_SPACE`
            setPropertyType('OFFICE_SPACE');

            // default workspace type to `PRIVATE_OFFICE`
            setWorkspaceType('PRIVATE_OFFICE');
        }
    }, [filterOptions]);

    // set the value of workSpaceTypes
    useEffect(() => {
        switch (propertyType) {
            case 'OFFICE_SPACE':
                const officeSpaceSubCategories = (filterOptions && filterOptions['subCategoryTypes']) ? filterOptions['subCategoryTypes'].filter(sub => sub.parent === propertyType) : [];
                logger.log(officeSpaceSubCategories);
                setWorkSpaceTypes(officeSpaceSubCategories);
                break;

            case 'RESIDENTIAL_SPACE':
                const residentialSpaceSubCategories = (filterOptions && filterOptions['subCategoryTypes']) ? filterOptions['subCategoryTypes'].filter(sub => sub.parent === propertyType) : [];
                setWorkSpaceTypes(residentialSpaceSubCategories);
                break;

            case 'SERVICE':
                const serviceSubCategories = (filterOptions && filterOptions['subCategoryTypes']) ? filterOptions['subCategoryTypes'].filter(sub => sub.parent === propertyType) : [];
                setWorkSpaceTypes(serviceSubCategories);
                break;

            default:
                setWorkSpaceTypes([]);
        }
    }, [filterOptions, propertyType]);

    const [selectedLocationAndMatch, setSelectedLocationAndMatch] = useState('');

    useEffect(() => {
        logger.log('selected location and match:', selectedLocationAndMatch);
    }, [selectedLocationAndMatch]);

    const propertySearchFunction = () => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        let selectedLocationFromFilter = selectedFilters.location;
        const splitLocationAndMatch = selectedLocationFromFilter?.split('~');
        let match = null, selectedLocation = null;

        if (splitLocationAndMatch && selectedFilters.locationDisplayValue?.trim().length > 0) {
            match = splitLocationAndMatch[0];
            selectedLocation = splitLocationAndMatch[1];
        }

        apiService.searchListings({
            amenities: [],
            capacity: selectedFilters.capacity || 1,
            cost: selectedFilters.cost || 0,
            end: selectedFilters.to,
            location: {
                address: null,
                city: null,
                country: null,
                match: match,
                selectedLocation: selectedLocation || ""
            },
            propertyType: selectedFilters.propertyType,
            start: selectedFilters.from,
            subCategory: selectedFilters.workspaceType
        }, { userId })
            .then(res => {
                if (res.status === 200) {
                    setRequestCompleted(true);
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);
                    setProperties(res.data.data || []);
                }
            })
            .catch(err => {
                setRequestCompleted(true);
                setProperties([]);
                logger.log(err);
            });
    };

    return (
        <Container fluid>
            <ErrorAlert show={errorAlert} message={errorMsg} close={() => setErrorAlert(false)} title="Booking Error" alertStyle={{}} />
            <Row>
                <h4 className="mb-4 mx-0 px-0">Find a Space</h4>
            </Row>
            <Row>
                <Col className="bg rounded my-auto">
                    <h3 className="text-white font-weight-bold">Find the Perfect Space</h3>
                    <Card className="mt-4">
                        <Card.Body>
                            <Row className={'mb-4'} style={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                                <Col md={2}>
                                    <select className="form-select" aria-label="Property Type" style={{ height: '100%' }} onChange={handlePropertyTypeChange} value={propertyType}>
                                        <option disabled>Type of service</option>
                                        {
                                            (filterOptions['propertyTypes']) &&
                                            filterOptions['propertyTypes'].map((type) => <option key={uuidv4()} value={type.value}>{type.label}</option>)
                                        }
                                    </select>
                                </Col>
                                {/* Workspace type here */}
                                <Col md={2}>
                                    <select className="form-select" aria-label="Workspace Type" style={{ height: '100%' }} onChange={handleWorkSpaceChange} value={workspaceType}>
                                        <option disabled>Workspace type</option>
                                        {
                                            workSpaceTypes &&
                                            workSpaceTypes.map((type) => <option key={uuidv4()} value={type.value}>{type.label}</option>)
                                        }
                                    </select>
                                </Col>
                                <Col md={2}>
                                    <Autocomplete
                                        getItemValue={(location) => {
                                            const { match, address, city, country, name: companyName, displayValue } = location;

                                            let value = '';
                                            if (match != null) {
                                                switch (match) {
                                                    case 'ADDRESS':
                                                        value = `${match}~${address}~${displayValue}`;
                                                        break;

                                                    case 'CITY':
                                                        value = `${match}~${city}~${displayValue}`;
                                                        break;

                                                    case 'COUNTRY':
                                                        const initial = country != null ? country.value : '';
                                                        value = `${match}~${initial}~${displayValue}`;
                                                        break;

                                                    case 'COMPANY_NAME':
                                                        value = `${match}~${companyName}~${displayValue}`;
                                                }
                                            }

                                            return value;
                                        }}
                                        items={combinedLocationsMatch}
                                        renderInput={(props) => (
                                            <input
                                                {...props}
                                                type="text"
                                                className="form-control h-100"
                                                placeholder="Search for location..."
                                                onChange={(e) => setSearchValue(e.target.value)}
                                            />
                                        )}
                                        renderItem={(location, isHighlighted) =>
                                            <div style={{ borderBottom: '1px solid lightgray', padding: '15px', cursor: 'pointer', background: isHighlighted ? 'lightgray' : 'white' }}>
                                                {location.displayValue}
                                            </div>
                                        }
                                        menuStyle={{
                                            borderRadius: '3px',
                                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                            background: 'rgba(255, 255, 255, 0.9)',
                                            padding: '2px 0',
                                            fontSize: '90%',
                                            position: 'fixed',
                                            zIndex: '3',
                                            width: '30%',
                                            overflow: 'auto',
                                            maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                                        }}
                                        wrapperStyle={{ height: '100%' }}
                                        value={searchValue}
                                        onSelect={(value) => {
                                            const firstIndex = value.indexOf('~');
                                            const lastIndex = value.indexOf('~', firstIndex + 1);
                                            const displayValue = value.slice(lastIndex + 1);

                                            logger.log('selected value:', displayValue);

                                            const selected = value.slice(0, lastIndex);
                                            logger.log('full string value:', selected);

                                            setSearchValue(displayValue);
                                            setSelectedLocationAndMatch(selected);
                                        }}
                                    />
                                    {/*<input*/}
                                    {/*  className="form-control"*/}
                                    {/*  list="location"*/}
                                    {/*  onChange={e => setSearchValue(e.target.value)}*/}
                                    {/* />*/}
                                    {/*<datalist id="location">*/}
                                    {/*    {*/}
                                    {/*        locationAddressList.map(location => {*/}
                                    {/*            const { address, city, country } = location;*/}
                                    {/*            const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';*/}

                                    {/*            return (*/}
                                    {/*              <option>{`${address}, ${city}, ${countryName}`}</option>*/}
                                    {/*            )*/}
                                    {/*        })*/}
                                    {/*    }*/}
                                    {/*    {*/}
                                    {/*        locationCityList.map(location => {*/}
                                    {/*            const { city, country } = location;*/}
                                    {/*            const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';*/}

                                    {/*            return (*/}
                                    {/*              <option>{`${city}, ${countryName}`}</option>*/}
                                    {/*            )*/}
                                    {/*        })*/}
                                    {/*    }*/}
                                    {/*    {*/}
                                    {/*        locationCountryList.map(location => {*/}
                                    {/*            const { country } = location;*/}
                                    {/*            const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';*/}

                                    {/*            return (*/}
                                    {/*              <option>{`${countryName}`}</option>*/}
                                    {/*            )*/}
                                    {/*        })*/}
                                    {/*    }*/}
                                    {/*</datalist>*/}









                                    {/*<select className="form-select" aria-label="Location" style={{height: '100%'}} onChange={handleLocationChange} value={location}>*/}
                                    {/*    <option disabled selected>Location</option>*/}
                                    {/*    {*/}
                                    {/*        (filterOptions['location']) &&*/}
                                    {/*        filterOptions['location'].map((type) => {*/}
                                    {/*            return <option key={uuidv4()} value={`${type.city},${type.country.value}`}>*/}
                                    {/*                {formatLocation(type)}*/}
                                    {/*            </option>*/}
                                    {/*        })*/}
                                    {/*    }*/}
                                    {/*</select>*/}
                                </Col>
                                <Col md={3} style={{ height: '42px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
                                        {shouldUseDateTimePicker() ? (
                                            <DateTimePicker
                                                label="Start Date"
                                                value={startDateObject}
                                                onChange={handleStartDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                                sx={calendarStyle}
                                            />
                                        ) : (
                                            <DatePicker
                                                label="Start Date"
                                                value={startDateObject}
                                                onChange={handleStartDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                                sx={calendarStyle}
                                            />
                                        )}
                                    </LocalizationProvider>
                                </Col>
                                <Col md={3} style={{ height: '42px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
                                        {shouldUseDateTimePicker() ? (
                                            <DateTimePicker
                                                label="End Date"
                                                value={endDateObject}
                                                onChange={handleEndDateChange}
                                                disabled={workspaceType === 'DEDICATED_DESK' && currentUser?.role !== 'ROLE_SUBSCRIBER_INDIVIDUAL'}
                                                renderInput={(params) => <TextField {...params} />}
                                                sx={calendarStyle}
                                            />
                                        ) : (
                                            <DatePicker
                                                label="End Date"
                                                value={endDateObject}
                                                onChange={handleEndDateChange}
                                                disabled={workspaceType === 'DEDICATED_DESK' && currentUser?.role !== 'ROLE_SUBSCRIBER_INDIVIDUAL'}
                                                renderInput={(params) => <TextField {...params} />}
                                                sx={calendarStyle}
                                            />
                                        )}
                                    </LocalizationProvider>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="2">
                                    <QuantityCounter count={capacity} setCount={setCapacity} />
                                </Col>
                                <Col>
                                    <h6 className="text-muted" style={{ fontSize: 14 }}>{userCurrency} {maxPrice ? maxPrice.toLocaleString() : 0}</h6>
                                    <Slide min={0} max={100000} step={500} railStyle={{ height: '6px' }}
                                        trackStyle={[{ height: '6px' }]} handleStyle={[{ background: 'white', marginTop: '-5px' }]} onChange={(e) => setMaxPrice(e)} />
                                </Col>
                                <Col md="3">
                                    <button className="button" type="button" onClick={dispatchFilters} style={{ width: '100%' }}>
                                        Search
                                    </button>
                                    {/*<Button variant="primary" size="lg" block onClick={dispatchFilters}>Search</Button>*/}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={newUserModal} onHide={() => setNewUserModal(false)} centered size='lg' style={{ borderRadius: 10 }}>

                <div style={newModal} className="newmodal">
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', margin: 20 }}>
                        <FaTimes onClick={() => setNewUserModal(false)} color="white" />
                    </div>
                    <h4 style={{ textAlign: 'center', marginTop: '10vh', marginBottom: '10vh', color: 'white' }}><b>Find The Perfect Space</b></h4>
                    <Container>
                        <div style={modalBar} onClick={() => setNewUserModal(false)} >
                            <FaCalendarAlt size={28} color='red' style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                            <h5 style={{ textAlign: 'center', marginTop: 'auto', marginBottom: 'auto' }}><b>Want to find a space on SPACIA?</b></h5>
                            <div style={{ padding: 10, borderRadius: 5, backgroundColor: 'red' }}>
                                <FaArrowRight color="white" />
                            </div>
                        </div>
                        <Link to="/listproperty" style={link}>
                            <div style={modalBar}>
                                <FaHome size={28} color='red' style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                                <h5 style={{ textAlign: 'center', marginTop: 'auto', marginBottom: 'auto' }}><b>Want to add a listing on SPACIA?</b></h5>
                                <div style={{ padding: 10, borderRadius: 5, backgroundColor: 'red' }}>
                                    <FaArrowRight color="white" />
                                </div>
                            </div>
                        </Link>
                    </Container>
                </div>
            </Modal>
        </Container>
    );
};

export default StartBooking;