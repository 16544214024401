import React, { useRef, useState } from "react";
import { Container, Dropdown, Form, Row, Col } from "react-bootstrap";
import { WithContext as ReactTags } from "react-tag-input";
import { FaHome, FaMoneyBillWave, FaRulerCombined, FaUsers, FaChevronDown } from "react-icons/fa";
import logger from "../../utils/logger";

const DescriptionTab = ({ formData, updateFormData, userCurrency, onNext }) => {
    // State for dropdown visibility
    const [dropdownVisible, setDropdownVisible] = useState(false);

    // Format billing period for display
    const getBillingPeriodDisplay = () => {
        switch (formData.billingPeriod) {
            case "HOURLY": return "Hourly";
            case "DAILY": return "Daily";
            case "WEEKLY": return "Weekly";
            case "MONTHLY": return "Monthly";
            default: return formData.billingPeriod;
        }
    };

    // Handle selection of billing period
    const handleBillingPeriodSelect = (period) => {
        updateFormData("billingPeriod", period);
        setDropdownVisible(false);
    };

    // Constants for ReactTags
    const KeyCodes = {
        comma: 188,
        enter: [10, 13],
    };

    const delimiters = [...KeyCodes.enter, KeyCodes.comma];

    // ReactTags handlers
    const handleDelete = (i) => {
        const updatedTags = formData.tags.filter((tag, index) => index !== i);
        updateFormData("tags", updatedTags);
    };

    const handleAddition = (tag) => {
        updateFormData("tags", [...formData.tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const tags = [...formData.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        updateFormData("tags", newTags);
    };

    // Validation for numeric fields
    const validateNumericField = (value, fieldName) => {
        if (value === "") {
            updateFormData(fieldName, value);
        } else {
            const numValue = parseInt(value);
            if (numValue >= 0) {
                updateFormData(fieldName, value);
            }
        }
    };

    return (
        <Container>
            {/* Basic Information Section */}
            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaHome className="me-2" /> Basic Information</h5>
                    <p>Enter the general information about your property</p>
                </div>

                <Row>
                    <Col md={12}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="propertyTitle">
                                Property Title
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="propertyTitle"
                                value={formData.propertyTitle}
                                onChange={(e) => updateFormData("propertyTitle", e.target.value)}
                                placeholder="Enter a catchy title for your property"
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="description">
                                Property Description
                            </label>
                            <textarea
                                className="form-control textarea"
                                id="description"
                                placeholder="Describe your property in detail - mention highlights, location advantages, and special features"
                                value={formData.description}
                                onChange={(e) => updateFormData("description", e.target.value)}
                                rows={6}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="form-group mb-0">
                            <label className="form-label" htmlFor="tags">
                                Add Tags
                            </label>
                            <div style={{ background: "white", flexWrap: "wrap" }}>
                                <ReactTags
                                    tags={formData.tags}
                                    placeholder="Add keywords like 'wifi', 'parking', 'quiet'"
                                    suggestions={[]}
                                    handleDelete={handleDelete}
                                    handleAddition={handleAddition}
                                    handleDrag={handleDrag}
                                    delimiters={delimiters}
                                    classNames={{
                                        tags: 'react-tags__selected',
                                        tagInput: 'react-tags__search',
                                        tagInputField: 'react-tags__search-input',
                                        selected: 'react-tags__selected',
                                        tag: 'react-tags__selected-tag',
                                        remove: 'react-tags__remove',
                                    }}
                                />
                            </div>
                            <small className="text-muted">Tags help potential clients find your property more easily</small>
                        </div>
                    </Col>
                </Row>
            </div>

            {/* Pricing Section */}
            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaMoneyBillWave className="me-2" /> Pricing Information</h5>
                    <p>Set your property price and billing options</p>
                </div>

                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="price">
                                Price in {userCurrency || "KES"}
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="price"
                                placeholder="Enter price"
                                value={formData.price}
                                onChange={(e) => validateNumericField(e.target.value, "price")}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="billingPeriod">
                                Billing Period
                            </label>
                            <div className="custom-dropdown-container">
                                <div
                                    className={`custom-dropdown ${dropdownVisible ? 'active' : ''}`}
                                    onClick={() => setDropdownVisible(!dropdownVisible)}
                                    onMouseEnter={() => setDropdownVisible(true)}
                                    onMouseLeave={() => setDropdownVisible(false)}
                                >
                                    <div className="dropdown-selected">
                                        <span>{getBillingPeriodDisplay()}</span>
                                        <FaChevronDown className="dropdown-icon" />
                                    </div>
                                    <div className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`}>
                                        <div
                                            className={`dropdown-item ${formData.billingPeriod === 'HOURLY' ? 'active' : ''}`}
                                            onClick={() => handleBillingPeriodSelect('HOURLY')}
                                        >
                                            Hourly
                                        </div>
                                        <div
                                            className={`dropdown-item ${formData.billingPeriod === 'DAILY' ? 'active' : ''}`}
                                            onClick={() => handleBillingPeriodSelect('DAILY')}
                                        >
                                            Daily
                                        </div>
                                        <div
                                            className={`dropdown-item ${formData.billingPeriod === 'WEEKLY' ? 'active' : ''}`}
                                            onClick={() => handleBillingPeriodSelect('WEEKLY')}
                                        >
                                            Weekly
                                        </div>
                                        <div
                                            className={`dropdown-item ${formData.billingPeriod === 'MONTHLY' ? 'active' : ''}`}
                                            onClick={() => handleBillingPeriodSelect('MONTHLY')}
                                        >
                                            Monthly
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            {/* Property Details Section */}
            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaRulerCombined className="me-2" /> Physical Characteristics</h5>
                    <p>Provide measurements and physical details of your property</p>
                </div>

                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="propertySize">
                                Size in ft²
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                name="propertySize"
                                id="propertySize"
                                min={1}
                                required
                                placeholder="Enter property size"
                                value={formData.propertySize}
                                onChange={(e) => validateNumericField(e.target.value, "propertySize")}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="floorNumber">
                                Floor Number
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="floorNumber"
                                placeholder="Enter floor number"
                                value={formData.floorNumber}
                                onChange={(e) => validateNumericField(e.target.value, "floorNumber")}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            {/* Capacity Section */}
            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaUsers className="me-2" /> Capacity Details</h5>
                    <p>Specify how many people can use this space</p>
                </div>

                <Row>
                    <Col md={6}>
                        <div className="form-group mb-md-0">
                            <label className="form-label" htmlFor="capacity">
                                Capacity (number of people)
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                name="capacity"
                                id="capacity"
                                min={1}
                                required
                                placeholder="Enter capacity"
                                value={formData.capacity}
                                onChange={(e) => validateNumericField(e.target.value, "capacity")}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <div className="d-flex mb-2 align-items-center">
                                <input
                                    type="checkbox"
                                    className="form-check-input me-2"
                                    id="coWorkingSpace"
                                    checked={formData.isCoWorkingSpaceChecked}
                                    onChange={(e) => updateFormData("isCoWorkingSpaceChecked", e.target.checked)}
                                    style={{ marginTop: "0", position: "relative", left: "5px" }}
                                />
                                <label className="form-label mb-0" htmlFor="coWorkingSpace" style={{ width: "100%" }}>
                                    Number of available slots (Select to enable)
                                </label>
                            </div>
                            <input
                                type="number"
                                className="form-control"
                                name="availableSlots"
                                id="availableSlots"
                                min={1}
                                required={formData.isCoWorkingSpaceChecked}
                                placeholder="Enter number of slots"
                                disabled={!formData.isCoWorkingSpaceChecked}
                                value={formData.coWorkingSpaceNumber}
                                onChange={(e) => validateNumericField(e.target.value, "coWorkingSpaceNumber")}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="button-container">
                <button
                    className="button"
                    onClick={() => {
                        const propertySizeInput = document.getElementById('propertySize');
                        if (propertySizeInput && formData.propertySize && parseInt(formData.propertySize) < 1) {
                            propertySizeInput.reportValidity();
                            return;
                        }

                        const capacityInput = document.getElementById('capacity');
                        if (capacityInput && formData.capacity && parseInt(formData.capacity) < 1) {
                            capacityInput.reportValidity();
                            return;
                        }

                        if (formData.isCoWorkingSpaceChecked) {
                            const slotsInput = document.getElementById('availableSlots');
                            if (slotsInput && !slotsInput.checkValidity()) {
                                slotsInput.reportValidity();
                                return;
                            }
                        }

                        onNext();
                    }}
                >
                    Next Step
                </button>
            </div>
        </Container>
    );
};

export default DescriptionTab; 