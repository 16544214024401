import React, { useRef } from "react";
import { Container, Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import Gallery from "react-photo-gallery";
import { ErrorAlert } from "../Settings/alerts/ErrorAlert";
import { FaUpload, FaImages, FaInfoCircle } from "react-icons/fa";
import imgplaceholder from "../../assets/img/Web/Spacia/imgplaceholder.png";
import logger from "../../utils/logger";
import { Constants } from "../../utils/constants";

// Convert file to data URI for preview
const fileToDataUri = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target.result);
        };
        reader.onerror = () => {
            reject(new Error("Failed to read file"));
        };
        reader.readAsDataURL(file);
    });
};

// Photo component for the gallery
const Photo = ({
    photo,
    index,
    direction,
    top,
    left,
    margin,
    removeImage,
}) => {
    const containerStyle = {
        position: "relative",
        float: "left",
        margin,
        cursor: "move",
        width: 200,
        height: 150,
        display: "inline-block",
        padding: "5px",
    };

    const imgStyle = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "6px",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
        transition: "all 0.2s ease",
    };

    const deleteButtonStyle = {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
        zIndex: "1",
        backgroundColor: "rgba(255, 0, 0, 0.7)",
        border: "none",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontSize: "14px",
        fontWeight: "bold",
        opacity: 0.7,
        transition: "opacity 0.3s ease",
    };

    return (
        <div style={containerStyle}>
            <img
                src={photo.src}
                alt={photo.title}
                style={imgStyle}
            />
            <button
                style={deleteButtonStyle}
                onClick={(e) => {
                    e.stopPropagation();
                    removeImage(index);
                }}
                onMouseOver={(e) => {
                    e.currentTarget.style.opacity = 1;
                }}
                onMouseOut={(e) => {
                    e.currentTarget.style.opacity = 0.7;
                }}
            >
                ×
            </button>
        </div>
    );
};

// SortablePhoto component for the sortable gallery
const SortablePhoto = SortableElement((props) => (
    <Photo {...props} />
));

// SortableGallery component for the sortable image gallery
const SortableGallery = SortableContainer(({ items, removeImage }) => {
    // Process items to have consistent dimensions for the gallery
    const processedItems = items.map(item => ({
        ...item,
        width: 1,
        height: 1
    }));

    return (
        <div className="sortable-gallery-wrapper">
            <Gallery
                photos={processedItems}
                renderImage={(props) => <SortablePhoto {...props} removeImage={removeImage} />}
                columns={4}
                margin={10}
            />
        </div>
    );
});

const MediaTab = ({
    formData,
    updateFormData,
    mediaLoadingState,
    uploadErrorModal,
    uploadErrorMsg,
    setUploadErrorModal,
    setUploadErrorMsg,
    onNext,
    onBack,
}) => {
    // Create a ref for the file input element
    const fileInputRef = useRef(null);

    // Function to handle upload box click
    const handleUploadBoxClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Handle image upload
    const handleImageUpload = async (target) => {
        if (!target || !target.files) return;

        // Reset error states
        setUploadErrorModal(false);

        // Check file size
        const maxFileSizeLimit = Constants.maxFileSizeLimit;
        const allValidImages = [...target.files].filter(
            (file) => file.size < Number(maxFileSizeLimit)
        );

        if (allValidImages.length === 0) {
            setUploadErrorModal(true);
            setUploadErrorMsg("File size exceeds the limit. Maximum file size allowed is 5MB.");
            return;
        }

        // Process images
        try {
            const newImages = await Promise.all(
                allValidImages.map(async (file) => {
                    const dataUri = await fileToDataUri(file);
                    const fileName = file ? file.name : "";
                    const id = fileName || uuidv4();

                    return {
                        id,
                        src: dataUri,
                        width: 1,
                        height: 1,
                        title: fileName,
                        file: file,
                        blob: dataUri,
                    };
                })
            );

            // Update form data with new images
            const updatedImages = [...formData.imagesArray, ...newImages];
            updateFormData("imagesArray", updatedImages);
        } catch (error) {
            logger.error("Error uploading images", error);
            setUploadErrorModal(true);
            setUploadErrorMsg("Failed to upload images. Please try again.");
        }
    };

    // Handle image removal
    const removeImage = (index) => {
        const updatedImages = [...formData.imagesArray];
        updatedImages.splice(index, 1);
        updateFormData("imagesArray", updatedImages);
    };

    // Handle drag and sort end
    const onSortEnd = ({ oldIndex, newIndex }) => {
        updateFormData(
            "imagesArray",
            arrayMove(formData.imagesArray, oldIndex, newIndex)
        );
    };

    // Function to handle drag over events
    const handleDragOver = (e) => {
        e.preventDefault();
        e.currentTarget.classList.add('dragging');
    };

    // Function to handle drag leave events
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove('dragging');
    };

    // Function to handle drop events
    const handleDrop = (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove('dragging');
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            handleImageUpload({ files });
        }
    };

    return (
        <Container>
            <ErrorAlert
                show={uploadErrorModal}
                close={() => setUploadErrorModal(false)}
                message={
                    uploadErrorMsg ||
                    "There was a problem uploading your images, please check and try again."
                }
            />

            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaInfoCircle className="me-2" /> Media Information</h5>
                    <p>Provide title and description for your property images</p>
                </div>

                <div className="form-group">
                    <label className="form-label" htmlFor="imageTitle">
                        Image Title
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="imageTitle"
                        placeholder="Enter a title for your images"
                        value={formData.imageTitle}
                        onChange={(e) => updateFormData("imageTitle", e.target.value)}
                    />
                </div>

                <div className="form-group mb-0">
                    <label className="form-label" htmlFor="imageDescription">
                        Image Description
                    </label>
                    <textarea
                        className="form-control textarea"
                        id="imageDescription"
                        placeholder="Enter description for your images (optional)"
                        value={formData.imageDescription}
                        onChange={(e) => updateFormData("imageDescription", e.target.value)}
                        rows={3}
                    />
                </div>
            </div>

            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaUpload className="me-2" /> Upload Images</h5>
                    <p>Upload high-quality images of your property (max 5MB each)</p>
                </div>

                <div
                    className="uploadBox"
                    onClick={handleUploadBoxClick}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <input
                        type="file"
                        id="imageUpload"
                        ref={fileInputRef}
                        multiple
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e.target)}
                        style={{ display: "none" }}
                    />

                    <div className="upload-content">
                        <FaImages size={40} color="#017bef" />
                        <p>Drag and drop images here or click anywhere to browse</p>
                        <label
                            htmlFor="imageUpload"
                            className="button"
                            onClick={(e) => e.stopPropagation()}
                        >
                            Select Images
                        </label>
                        <p className="text-muted">Maximum file size: 5MB</p>
                    </div>
                </div>
            </div>

            <div className="form-section-card">
                <div className="section-header">
                    <h5><FaImages className="me-2" /> Image Gallery</h5>
                    <p>Drag to reorder images. First image will be the featured image.</p>
                </div>

                <div className="gallery-container">
                    {/* Show processing or uploading state */}
                    {mediaLoadingState && (
                        <div className="loading-state">
                            <Spinner animation="border" role="status" variant="primary">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            <p>Processing images...</p>
                        </div>
                    )}

                    {/* Show uploaded images */}
                    {!mediaLoadingState && formData.imagesArray.length > 0 && (
                        <SortableGallery
                            items={formData.imagesArray}
                            onSortEnd={onSortEnd}
                            axis={"xy"}
                            removeImage={removeImage}
                            useDragHandle={false}
                        />
                    )}

                    {/* Show message if no images are uploaded */}
                    {!mediaLoadingState && formData.imagesArray.length === 0 && (
                        <div className="no-images-message">
                            <FaImages size={30} color="#adb5bd" />
                            <p>No images uploaded yet</p>
                            <p className="text-muted">Please select images to upload</p>
                        </div>
                    )}
                </div>
            </div>

            <div className="button-container">
                <button className="button-calm" onClick={onBack}>
                    Back
                </button>
                <button className="button ms-3" onClick={onNext}>
                    Next Step
                </button>
            </div>
        </Container>
    );
};

export default MediaTab; 